import { ConfigProvider, Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { observer } from 'mobx-react';
import { CSSProperties, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useParams } from 'react-router-dom';
import { HeaderStore } from '../DataSource/Stores/HeaderStore';
import { ManagerStoreContext } from '../DataSource/context/title';
import HeaderTop from '../components/Cabinet/DeliveryCenter/HeaderTop';
import Menu from '../components/Cabinet/DeliveryCenter/Menu';
import Nav from '../components/Cabinet/Nav';
import Header from '../components/Header/Header';
import styles from './index.module.scss';

function CabinetLayout() {
  const { managersStore } = useContext(ManagerStoreContext)!;
  const onClose = () => {
    HeaderStore.setOpenRight(false);
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' });

  const siderStyle: CSSProperties = {
    backgroundColor: '#F3F5F6',
    padding: '180px 16px',
  };

  const { id } = useParams();

  return (
    <ConfigProvider>
      {id == '6707a212598d48bda5860bf0' ? (
        <div className="block_width">
          <Header />
          <HeaderTop title={managersStore.title} />
          <div className="flex_row">
            <div className="menu_block">
              <Menu />
            </div>
            <div className="second_column">
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <Layout>
          <Sider width="auto" style={siderStyle}>
            <Nav />
          </Sider>
          <div id='cabinet-layout' className={styles.cabinetLayout}>
            <Header />
            <Outlet />
          </div>
        </Layout>
      )}
    </ConfigProvider>
  );
}
export default observer(CabinetLayout);
