import { action, makeAutoObservable, observable } from 'mobx';
import { basicRequestPost } from '../../Requests/basicRequestPost';
import { FieldType } from '../../../components/Profile/ModalProfile';
import { AuthStore } from '../AuthStore';
import { basicRequestPatch } from '../../Requests/basicRequestPatch';

export class ProfileStoreActionsClass {
  @observable
  isModalOpen: boolean = false;

  @observable
  sendingEmail: boolean = false;

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  /**
   *
   */
  async sendNewEmail(email: string) {
    await basicRequestPost('/user/profile/send_new_email', {
      email,
    });
    console.log('---- ww|sendNewEmail');
  }

  /**
   *
   */
  async changeEmail(data: FieldType) {
    console.log('---- ww|changeEmail|data=', data);
    const res = await basicRequestPost('/user/profile/change_email', {
      ...data,
      ...{ code: Number(data.code) },
    });
    console.log('---- ww|changeEmail|res.data=', res.data);

    AuthStore.currentUserData.saveAuthToken(res.data.access_token);
    AuthStore.currentUserData.user.email = data.new_email;
  }

  @action
  async updateUserPhoto(formData: FormData) {
    try {
      const res = await basicRequestPatch('/image-uploader/userPhoto', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  }
}
