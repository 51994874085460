import { Button, Form, type FormProps, message, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { ProductListStore } from '../../../../DataSource/Stores/Admin/ProductListStore';
import styles from '../../Admin.module.scss';
import Forms from './Forms';
import { ProductParams } from '../../../../DataSource/Stores/Admin/ProductParams';
import FormParamType from './ParamType';

export interface Condition {
  param_id: string;
  value: string;
}
export interface TypeParamIds {
  param_id: string;
  value: string;
}

export type CpLsListFieldType = {
  _id: string;
  key: string;
  product_name: string;
  type_param_id: string;
  status_param_id: string;
};

export type CpLsFieldType = {
  _id?: string;
  product_name: string;
  type_param_ids: TypeParamIds[];
};

type Param = {
  param_id: string;
  value: string;
};
type TypeParam = {
  [key: string]: Param[];
};
export type Product = {
  product_name: string;
  type_param_ids: TypeParam[];
};

const onFinishFailed: FormProps<CpLsFieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const ModalForm: React.FC = () => {
  const formRef = useRef<any>(null);

  const onFinish: FormProps<CpLsFieldType>['onFinish'] = async (values) => {
    console.log('Success:', values);

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if (/^[a-f0-9]{24}$/.test(key)) {
          if (!values.type_param_ids) values.type_param_ids = [];
          // @ts-ignore
          if (values[key]) {
            // @ts-ignore
            values.type_param_ids.push({ [key]: values[key] });
            // @ts-ignore
            delete values[key];
          }
        }
      }
    }

    message.loading('Loading');
    try {
      if (ProductListStore.dataProduct?._id) {
        values._id = ProductListStore.dataProduct._id;
        await ProductListStore.editProduct(values as unknown as Product);
      } else {
        await ProductListStore.addProduct(values as unknown as Product);
      }
      message.destroy();
    } catch (error: any) {
      console.error('onFinish exception:error?.status=', error?.message);
      message.destroy();
      message.error('Error add challenge');
      return;
    }

    await ProductParams.getParameterTypesList();
    await ProductListStore.getList();

    message.info('Success');
    ProductListStore.isModalOpen = false;
    ProductListStore.dataProduct = null;
    // formRef.current?.resetFields();
  };

  const handleSubmit = () => {
    console.log('---- ww|!! formRef.current', !!formRef.current);
    formRef.current?.submit();
  };

  const handleOk = () => {
    ProductListStore.isModalOpen = false;
  };

  const handleCancel = () => {
    ProductListStore.isModalOpen = false;
  };
  const handleAfter = () => {
    console.log('---- ww|handleCancel');

    if (ProductListStore.dataProduct) {
      ProductListStore.dataProduct = null;
    }
    formRef.current?.resetFields();
    ProductListStore.isModalOpen = false;
  };

  // console.log('---- ww|ProductListStore.dataProduct=', ProductListStore.dataProduct);

  return (
    <Modal
      afterClose={handleAfter}
      title="Product list"
      open={ProductListStore.isModalOpen}
      onOk={handleOk}
      className={styles.modalform}
      onCancel={handleCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={ProductListStore.globalLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>,
        <Button
          key="link"
          type="primary"
          loading={ProductListStore.globalLoading}
          onClick={handleCancel}
        >
          Close
        </Button>,
      ]}
    >
      <Form
        key={Date.now()} // Установите key с уникальным значением
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ width: '100%' }}
        initialValues={ProductListStore.dataProduct || {}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        ref={formRef} // Привязываем ссылку на форму
      >
        <Forms />
        {
          (ProductParams.paramTypesList || []).map((i) => {
            // console.log('---- ww|paramTypesList|i=', i);
            return <FormParamType key={i.key} tab={i} />;
          })
          //FormParamType
        }
        {/*<FormDescription />*/}
        {/*<FormCondition />*/}
      </Form>
    </Modal>
  );
};

export default observer(ModalForm);
