import { DeleteOutlined } from '@ant-design/icons';
import { message, Modal, Popconfirm, Switch, Table, TableProps, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import usePageTitle from '../../DataSource/Hooks/usePageTitle';
import { AuthStore } from '../../DataSource/Stores/AuthStore';
import Create from '../Auth/Create';
import Lang from '../Lang/index';
import styles from './Admin.module.scss';
interface StatusColorMap {
  [key: string]: string;
}

interface Pagination {
  total: number;
  next: number | null;
  prev: number | null;
}
const handleDelete = (key: React.Key) => {
  console.log('---- handleDelete|key=', key);
  message.loading('Loading');
  AuthStore.deleteUser(key as string)
    .then(() => {
      message.destroy();
      message.info('Success');
    })
    .catch((error) => {
      message.destroy();
      if (error?.message == 'Request failed with status code 409') {
        return message.error('Parameter in use');
      }
      message.error('Error');
    });
};

const onChange = (checked: boolean, key: React.Key) => {
  console.log(`switch to ${checked}`);

  AuthStore.setAsAdmin(key as string, checked)
    .then(() => {
      message.destroy();
      message.info('Success');
    })
    .catch((error) => {
      message.destroy();
      if (error?.message == 'Request failed with status code 409') {
        return message.error('Parameter in use');
      }
      message.error('Error');
    });
};
const onBlockChange = (checked: boolean, key: React.Key) => {
  console.log(`switch to ${checked}`);

  AuthStore.blockUser(key as string, checked)
    .then(() => {
      message.destroy();
      message.info('Success');
    })
    .catch((error) => {
      message.destroy();
      if (error?.message == 'Request failed with status code 409') {
        return message.error('Parameter in use');
      }
      message.error('Error');
    });
};

const columns: TableProps<UserDataType>['columns'] = [
  {
    title: 'First name',
    dataIndex: 'firstname',
    key: 'firstname',
    render: (text) => <p>{text}</p>,
  },
  {
    title: 'Second name',
    dataIndex: 'secondname',
    key: 'secondname',
    render: (text) => <p>{text}</p>,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'createdAt',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'PhoneNumber',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (_, { status }) => {
      const color: StatusColorMap = {
        verified: 'green',
        block: 'volcano',
      };
      return <Tag color={color[status]}>{status.toUpperCase()}</Tag>;
    },
  },
];

const getTable = () => {
  if (
    AuthStore.currentUserData.user.isSuperAdmin &&
    columns.findIndex((i) => i.title === 'Delete') == -1
  ) {
    columns.push(
      {
        title: 'Delete',
        dataIndex: 'operation',
        render: (_, record) => (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <DeleteOutlined className="icon_bl" />
          </Popconfirm>
        ),
      },
      {
        title: 'Admin',
        dataIndex: 'admin',
        key: 'admin',
        render: (_, record) => (
          <Switch
            value={record.role == 'admin' || record.role == 'super_admin'}
            onChange={(val) => onChange(val, record.key)}
            loading={AuthStore.changingUserData}
          />
        ),
      },
      {
        title: 'Block',
        dataIndex: 'block',
        key: 'block',
        render: (_, record) => (
          <Switch
            value={record.status == 'block'}
            onChange={(val) => onBlockChange(val, record.key)}
            loading={AuthStore.changingUserData}
          />
        ),
      }
    );
  }
  return columns;
};

const Users: React.FC = () => {
  usePageTitle(Lang({ str: 'users', section: 'default' }));
  const [loading, setLoading] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (init) return;
    setInit(true);

    setLoading(true);
    AuthStore.getUsers()
      .catch(() => setLoadError(true))
      .finally(() => setLoading(false));
  }, []);

  if (loadError) {
    return (
      <div>
        <h1>Users</h1>
        <div className={styles.table + ' mt-32'}>Error...</div>
      </div>
    );
  }

  return (
    <div className="pt-80">
      <div className={styles.d_flex}>
        <h1>{Lang({ str: 'users', section: 'default' })}</h1>
        <button className={styles.but_link} onClick={showModal}>
          <svg
            width="14"
            height="13"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.3334 7H9.33337V1C9.33337 0.447719 8.88566 0 8.33337 0C7.78109 0 7.33337 0.447719 7.33337 1V7H1.33337C0.781093 7 0.333374 7.44772 0.333374 8C0.333374 8.55228 0.781093 9 1.33337 9H7.33337V15C7.33337 15.5523 7.78109 16 8.33337 16C8.88566 16 9.33337 15.5523 9.33337 15V9H15.3334C15.8857 9 16.3334 8.55228 16.3334 8C16.3334 7.44772 15.8857 7 15.3334 7Z"
              fill="#000000"
            />
          </svg>
          {Lang({ str: 'addadmin', section: 'default' })}
        </button>
      </div>
      <div className={styles.table + ' mt-32'}>
        <Table
          scroll={{ x: 1000 }}
          columns={getTable()}
          dataSource={AuthStore.usersList}
          pagination={{
            pageSize: 10, // Размер страницы
            total: AuthStore.usersPagination?.total || 0, // Общее количество элементов (если известно заранее)
            // Можно также добавить другие настройки пагинации, например, current, pageSizeOptions и т.д.
          }}
          loading={loading}
        />
      </div>
      <Modal
        className={styles.modall}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Create />
      </Modal>
    </div>
  );
};

export default observer(Users);
