import { Spin } from 'antd';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { WorkspacesStore } from '../../../DataSource/Stores/Cabinet/WorkspacesStore';
import NotFound from '../../NotFound';
import Item from '../Workspaces/Item';
import styles from './index.module.scss';

// console.log(stream);
export interface WorkspacesItemProps {
  name?: string;
  _id?: string;
  userIds?: [];
  roles?: Record<string, never> | undefined;
}

const Main = () => {
  useEffect(() => {
    WorkspacesStore.getList().catch((e) => {
      console.error(e);
    });
  }, []);

  if (!WorkspacesStore.getloading && WorkspacesStore.getError) return <NotFound />;

  return (
    <div>
      <h2>Workspaces</h2>
      <div className={styles.flex_worksp}>
        {WorkspacesStore.loadingError && 'Error loading'}
        {WorkspacesStore.loading ? (
          <Spin size="small" />
        ) : WorkspacesStore.dataListWork.length == 0 ? (
          'empty'
        ) : (
          WorkspacesStore.dataListWork.map((workspace, index) => (
            <Item workspace={workspace} key={index} />
          ))
        )}
      </div>
    </div>
  );
};

export default observer(Main);
