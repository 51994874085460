import { animated, useSpring } from '@react-spring/web';
import { Result, Spin } from 'antd';
import { observer } from 'mobx-react';
import { FC, useRef } from 'react';
import { JobsDataType } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import styles from '../Mobile/index.module.scss';
import JobItem from './JobItem';

interface IProps {
  workspaceId: string;
}

const JobTabActive: FC<IProps> = ({ workspaceId }) => {
  const wrapOrdRef = useRef<HTMLDivElement>(null);

  const widthnew = wrapOrdRef.current ? wrapOrdRef.current.clientWidth : 0;

  const jobClick = (job: JobsDataType) => {
    StagesStore.setShowText(true);
    StagesStore.setLayoutPageJob('jobeditor');
    StagesStore.setSelectedJob(job);
    StagesStore.setIsSelectedJob(true);
  };

  const onBackJob = () => {
    StagesStore.setShowText(false);
    StagesStore.setLayoutPageJob('joblay');
    StagesStore.setIsSelectedJob(false);
    StagesStore.setSelectedJob(null);
  };

  const { x } = useSpring({
    x: StagesStore.layoutPageJob === 'joblay' ? 0 : -widthnew,
  });

  const textAnimation = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: StagesStore.showText ? 1 : 0,
    },
    delay: 300, // Задержка перед появлением текста
    config: { duration: 400 }, // Длительность анимации
  });

  const activeJob = StagesStore.dataQueueList[0];

  return (
    <div className={styles.prompt_wrap} ref={wrapOrdRef}>
      {StagesStore.layoutPageJob === 'joblay' ? (
        <div>
          {activeJob && (
            <div className={styles.jobsGroup}>
              <div className={styles.jobsGroupTop}>
                <h4>Активный заказ:</h4>
              </div>
              <JobItem
                className={styles.jobItemActive}
                key={activeJob._id}
                _id={activeJob._id}
                name={activeJob.name}
                jobId={activeJob.jobId}
                onClick={jobClick}
                stageId={activeJob.stageId}
                workspaceId={activeJob.workspaceId}
                bitrixId2={activeJob.externalId?.bitrixId}
                jiraId2={activeJob.externalId?.jiraId}
                jobData={activeJob.jobData}
                targetDate={activeJob.targetDate}
                comments={activeJob.comments}
                isSelectedJob={false}
                submitBtnName="Добавить в маршрут"
              />
            </div>
          )}

          <div className={styles.jobsGroup}>
            <div className={styles.jobsGroupTop}>
              <h4>Все заказы:</h4>

              <Button autoWidth variant="primary" icon="refresh">
                Авто-маршрут
              </Button>
            </div>
            <div className={styles.task_child_flex}>
              {StagesStore.loadingQueueError && 'Error loading'}

              {StagesStore.loadingQueue && StagesStore.dataQueueList?.length === 0 ? (
                <Spin size="small" />
              ) : StagesStore.dataQueueList?.length === 0 ? (
                <div className={styles.no_tasks}>
                  <Result status="warning" title="Новых заявок пока нет" />
                </div>
              ) : (
                StagesStore.dataQueueList?.map((task_i) => (
                  <JobItem
                    key={task_i._id}
                    _id={task_i._id}
                    name={task_i.name}
                    jobId={task_i.jobId}
                    onClick={jobClick}
                    stageId={task_i.stageId}
                    workspaceId={task_i.workspaceId}
                    bitrixId2={task_i.externalId?.bitrixId}
                    jiraId2={task_i.externalId?.jiraId}
                    jobData={task_i.jobData}
                    targetDate={task_i.targetDate}
                    comments={task_i.comments}
                    isSelectedJob={
                      StagesStore.isJobSelected && StagesStore.selectedJob?._id === task_i._id
                    }
                    submitBtnName="Добавить в маршрут"
                  />
                ))
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.task_child_flex}>
          <div className={styles.prompts_create}>
            <div className={styles.flex_block}>
              <animated.p className={styles.back} onClick={onBackJob} style={textAnimation}>
                <Icon slug="arrow_back" /> Все "Маршруты"
              </animated.p>
              {/* <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: <p>текст</p>,
                      },
                      {
                        key: '2',
                        danger: true,
                        label: <p>текст</p>,
                      },
                    ],
                  }}
                >
                  <a className="button_add" onClick={(e) => e.preventDefault()}>
                    <Icon slug="icons_menu_point" />
                  </a>
                </Dropdown> */}
            </div>

            {StagesStore.selectedJob && StagesStore.layoutPageJob == 'jobeditor' && (
              <JobItem
                key={StagesStore.selectedJob._id}
                targetDate={StagesStore.selectedJob.targetDate}
                _id={StagesStore.selectedJob._id}
                name={StagesStore.selectedJob.name}
                jobId={StagesStore.selectedJob.jobId}
                workspaceId={StagesStore.selectedJob.workspaceId}
                bitrixId2={StagesStore.selectedJob.bitrixId2}
                jiraId2={StagesStore.selectedJob.jiraId2}
                jobData={StagesStore.selectedJob.jobData}
                comments={StagesStore.selectedJob.comments}
                onClick={() => {}}
                isSelectedJob={StagesStore.isJobSelected}
                stageId={''}
                submitBtnName={'Завершить заказ'}
                submitBtnTypes={[
                  {
                    value: 'delivered',
                    label: 'Доставлено',
                  },
                  {
                    value: 'transfered',
                    label: 'Перенос',
                  },
                  {
                    value: 'canceled',
                    label: 'Отмена клиентом',
                  },
                ]}
                children={
                  <>
                    <Button icon="location" className={styles.navigatorBtn}>
                      Навигатор
                    </Button>
                    <Button icon="image" className={styles.photoBtn}>
                      Добавить фото
                    </Button>
                  </>
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(JobTabActive);
