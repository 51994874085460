// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n5fSidwPoBlC1tFzWBhX{margin-top:24px}.n5fSidwPoBlC1tFzWBhX .xKpEwuzXqg8TgetnUEJe{color:#2a3038;font-family:"Inter",sans-serif;font-weight:400;font-size:24px;margin-bottom:24px}.n5fSidwPoBlC1tFzWBhX .ant-select-selector{border:0 !important;font-size:16px;padding:4px 16px !important}.n5fSidwPoBlC1tFzWBhX .ant-select-single{height:auto}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/StatusProf/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,4CACE,aAAA,CACA,8BAAA,CACA,eAAA,CACA,cAAA,CACA,kBAAA,CAEF,2CACE,mBAAA,CACA,cAAA,CACA,2BAAA,CAEF,yCACE,WAAA","sourcesContent":[".status_wrp {\n  margin-top: 24px;\n  .title {\n    color: #2a3038;\n    font-family: 'Inter', sans-serif;\n    font-weight: 400;\n    font-size: 24px;\n    margin-bottom: 24px;\n  }\n  :global(.ant-select-selector) {\n    border: 0 !important;\n    font-size: 16px;\n    padding: 4px 16px !important;\n  }\n  :global(.ant-select-single) {\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status_wrp": `n5fSidwPoBlC1tFzWBhX`,
	"title": `xKpEwuzXqg8TgetnUEJe`
};
export default ___CSS_LOADER_EXPORT___;
