import type { CollapseProps } from 'antd';
import { Avatar, Collapse, Input } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import avatar from '../../../../assets/bg.svg';
import Button from '../../../../components/ui/Button';
import Icon from '../../../../components/ui/Icon';
import StatusTag from '../StatusTag';
import styles from './index.module.scss';

const HeaderMessage: React.FC = () => {
  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <div className={styles.one_courier}>
          <div className={styles.one_courier__top}>
            <Avatar size={50} src={avatar} />
            <div>
              <p>Дмитрий</p>
              <StatusTag theme={'work'} title={'в работе'} />
            </div>
          </div>
        </div>
      ),
      children: (
        <div className={styles.message_wrp}>
          <div className={styles.message_cont}>
            <div className={styles.no_messag}>
              <Icon slug={'fi-rr-drawer-empty'} />
              <p>Сообщений нет</p>
            </div>
          </div>
          <div className={styles.input_bl}>
            <Input placeholder="Сообщение" />
            <Button>
              <Icon slug={'fi-rr-paper-plane-top'} />
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return <Collapse expandIconPosition={'end'} bordered={true} onChange={onChange} items={items} />;
};
export default observer(HeaderMessage);
