import { UniqueIdentifier } from '@dnd-kit/core';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  CpLsFieldType,
  CpLsListFieldType,
  Entity,
} from '../../../components/Admin/BaseEntity/Modal';
import { basicRequestDelete } from '../../Requests/basicRequestDelete';
import { basicRequestGet } from '../../Requests/basicRequestGet';
import { basicRequestPost } from '../../Requests/basicRequestPost';

export abstract class BaseEntityStoreActionsClass {
  isModalOpen: boolean = false;

  loadingError: boolean = false;

  loading: boolean = false;

  loadingEntity: boolean = false;

  addInProgress: boolean = false;

  dataList: CpLsListFieldType[] = [];

  dataEntity: CpLsFieldType | null = null;

  slug: string = '';
  protected constructor(slug: string) {
    this.slug = slug;
    // makeObservable(this);
    makeObservable(this, {
      isModalOpen: observable,

      loadingError: observable,

      loading: observable,

      loadingEntity: observable,

      addInProgress: observable,

      dataList: observable,

      dataEntity: observable,

      slug: observable,
      add: action,
      edit: action,
      get: action,
      getList: action,
      changeSort: action,
      delete: action,
      import: action,
      globalLoading: computed,
    });
  }

  /**
   *
   */
  async add(data: Entity) {
    this.addInProgress = true;
    try {
      await basicRequestPost(`/admin/${this.slug}/add`, data);
    } catch (error) {
      console.error('---- add|error=', error);
      throw error;
    } finally {
      this.addInProgress = false;
    }
  }

  /**
   *
   */
  async edit(data: Entity) {
    this.addInProgress = true;
    try {
      await basicRequestPost(`/admin/${this.slug}/edit`, data);
    } catch (error) {
      console.error('---- edit|error=', error);
      throw error;
    } finally {
      this.addInProgress = false;
    }
  }

  /**
   *
   */
  async get(data: CpLsListFieldType) {
    console.log('---- ww|get|data=', data);
    if (this.loadingEntity) {
      console.info('---- ww|get|loadingEntity==true');
      return;
    }
    this.loadingEntity = true;
    try {
      const res = await basicRequestGet(`/admin/${this.slug}/get/${data._id}`);
      console.log('---- ww|get|res.data=', res.data);
      this.dataEntity = res.data as CpLsFieldType;
    } catch (error) {
      throw error;
    } finally {
      this.loadingEntity = false;
    }
  }

  /**
   *
   */
  async getList() {
    console.log('---- ww|getList');
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.loadingError = false;

    try {
      const res = await basicRequestPost(`/admin/${this.slug}/list`, {
        // filter: { param_type },
      });

      this.dataList = res.data.data.docs.map((i: any, k: number) => {
        i.key = i._id;
        i.numb = k + 1;
        // i.tags = [];
        return i;
      }) as CpLsListFieldType[];
    } catch (error) {
      // throw error;
      console.error('getList|error=', error);
      this.loadingError = true;
    } finally {
      this.loading = false;
    }
  }

  /**
   *
   */
  async changeSort(active_id: UniqueIdentifier, over_id: UniqueIdentifier | null) {
    console.log('---- ww|chandeSort');
    if (this.loading) {
      return;
    }

    this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPost(`/admin/${this.slug}/change_params_sort`, {
        active_id,
        over_id,
      });
    } catch (error) {
      // throw error;
      console.error('getList|error=', error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  /**
   *
   */
  async delete(_id: string) {
    const index = this.dataList.findIndex((obj) => obj._id === _id);
    console.log('delete|index=', index);
    if (index === -1) {
      console.error('delete|index !== -1');
      throw 'Remove error';
    }
    await basicRequestDelete(`/admin/${this.slug}/delete/` + _id);
    this.dataList = this.dataList.filter((obj) => obj._id !== _id);
  }

  /**
   *
   */
  async changeActive(_id: string, isActive: boolean) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPost(`/admin/${this.slug}/changeActive`, {
        _id,
        isActive,
      });

      this.dataList = this.dataList.map((obj) => {
        if (obj.key === _id) {
          obj.isActive = isActive;
        }
        return obj;
      });
    } catch (error) {
      // throw error;
      console.error('import|error=', error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  /**
   *
   */
  async import() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPost(`/admin/${this.slug}/import`, {});
    } catch (error) {
      // throw error;
      console.error('import|error=', error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  /**
   *
   */
  // async syncHard() {
  //   if (this.loading) {
  //     return;
  //   }
  //
  //   this.loading = true;
  //   // this.loadingError = false;
  //
  //   try {
  //     const res = await basicRequestPost(`/admin/${this.slug}/syncHard`, {});
  //   } catch (error) {
  //     // throw error;
  //     console.error('import|error=', error);
  //     throw error;
  //   } finally {
  //     this.loading = false;
  //   }
  // }

  /**
   *
   */
  async sync() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPost(`/admin/${this.slug}/sync`, {});
    } catch (error) {
      // throw error;
      console.error('import|error=', error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  /**
   *
   */
  async handleSyncStopLists() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPost(`/admin/${this.slug}/syncStopLists`, {});
    } catch (error) {
      // throw error;
      console.error('import|error=', error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  get globalLoading() {
    return this.loading || this.addInProgress;
  }
}
