// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k5rxhHqufc0wTH_iRBgY{display:flex;align-items:end;justify-content:space-between;grid-column-start:2}.k5rxhHqufc0wTH_iRBgY .AQj0qjGusBKgpXIQViBm{width:112px}.k5rxhHqufc0wTH_iRBgY .v9rUcx8udWH5HAE_b5Pv{border-radius:8px;color:#2a3038;font-size:20px;display:flex;align-items:center;gap:16px}.k5rxhHqufc0wTH_iRBgY .ecEVcaL3MsHaXwSHF5sO{display:flex;align-items:center;column-gap:8px;padding:12px;border-radius:12px;background-color:#e2e5e9}.k5rxhHqufc0wTH_iRBgY .ecEVcaL3MsHaXwSHF5sO p{font-size:15px;font-weight:500}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA,sBAME,YAAA,CACA,eAAA,CACA,6BAAA,CACA,mBAAA,CAEA,4CACE,WAAA,CAGF,4CACE,iBAAA,CACA,aAAA,CACA,cAAA,CAEA,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,YAAA,CACA,kBAAA,CACA,wBAAA,CAEA,8CACE,cAAA,CACA,eAAA","sourcesContent":[".header {\n  // background-color: #FFFFFFB2;\n  // border-radius: 8px;\n  // box-shadow: 0 2px 4px 0 #00000026;\n  // padding: 18px 24px;\n\n  display: flex;\n  align-items: end;\n  justify-content: space-between;\n  grid-column-start: 2;\n\n  .logo {\n    width: 112px;\n  }\n\n  .dropdownBtn {\n    border-radius: 8px;\n    color: #2A3038;\n    font-size: 20px;\n\n    display: flex;\n    align-items: center;\n    gap: 16px;\n  }\n\n  .userCard {\n    display: flex;\n    align-items: center;\n    column-gap: 8px;\n\n    padding: 12px;\n    border-radius: 12px;\n    background-color: #e2e5e9;\n\n    p {\n      font-size: 15px;\n      font-weight: 500;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `k5rxhHqufc0wTH_iRBgY`,
	"logo": `AQj0qjGusBKgpXIQViBm`,
	"dropdownBtn": `v9rUcx8udWH5HAE_b5Pv`,
	"userCard": `ecEVcaL3MsHaXwSHF5sO`
};
export default ___CSS_LOADER_EXPORT___;
