import { Button, Form, Input, type FormProps } from 'antd';
import { observer } from 'mobx-react';
// import { useState } from 'react';
import { AuthStore } from '../../../DataSource/Stores/AuthStore';
import Lang from '../../Lang';
import styles from '../Auth.module.scss';
import { FieldTypetwo } from './index';

const onFinishCreateFailed: FormProps<FieldTypetwo>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
  // setActive(true);
};

interface SetupProfileProps {
  queryParams: QueryParams;
}

function SetupProfile({ queryParams }: SetupProfileProps) {
  const [form] = Form.useForm();
  // const [showpass, setShowpass] = useState(false);

  // const handlePassChange = (e: any) => {
  //   if (e.target.value.length > 0) {
  //     setShowpass(true);
  //   } else {
  //     setShowpass(false);
  //   }
  // };

  const onFinishCreate: FormProps<FieldTypetwo>['onFinish'] = async (values) => {
    console.log('onFinishCreate Success:', values);

    // if (values.password !== values.confirmpassword) {
    //   message.error('Passwords do not match');
    //   console.error('Passwords do not match');
    //   return; // Прерываем отправку формы
    // }

    values.phoneNumber = queryParams.phoneNumber;
    values.token = queryParams.token;
    try {
      await AuthStore.signUp(values);
      AuthStore.signUpSuccess = true;
    } catch (e) {
      AuthStore.signUpError = true;
      return console.warn('signUp exception:', e);
    }
  };

  // const codedValidator = (rule: any, value: any, callback: any) => {
  //   // Проверка на минимальную длину пароля
  //   if (value && value.length !== 6) {
  //     callback(Lang({ str: 'Code must be at 6 characters long', section: 'default' }));
  //     return;
  //   }
  //
  //   // Проверка на наличие как минимум одной цифры или специального символа
  //   const digitRegex = /\d{6}/;
  //   if (value && !digitRegex.test(value)) {
  //     callback(
  //       Lang({
  //         str: 'Code wrong format',
  //         section: 'default',
  //       })
  //     );
  //     return;
  //   }
  //
  //   callback();
  // };

  // const confirmPasswordValidator = (rule: any, value: any, callback: any) => {
  //   const passwordFieldValue = form.getFieldValue('password');
  //
  //   // Проверка на совпадение паролей
  //   if (value && passwordFieldValue && value !== passwordFieldValue) {
  //     callback(Lang({ str: 'Passwords do not match', section: 'default' }));
  //     return;
  //   }
  //
  //   callback();
  // };

  return (
    <>
      <h6 className={styles.title_lit}>{queryParams.phoneNumber}</h6>
      <p className={styles.text_min + ' ' + styles.text_center + ' mb-24'}>
        Enter your details to create your account
      </p>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinishCreate}
        onFinishFailed={onFinishCreateFailed}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item<FieldTypetwo>
          label={Lang({ str: 'First name', section: 'default' })}
          name="firstname"
          rules={[
            {
              required: true,
              message: Lang({ str: 'Please input your first name!', section: 'default' }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<FieldTypetwo>
          label={Lang({ str: 'Second name', section: 'default' })}
          name="secondname"
          rules={[
            {
              required: true,
              message: Lang({ str: 'Please input your second name!', section: 'default' }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/*<Form.Item<FieldTypetwo>*/}
        {/*  label={Lang({ str: 'Password', section: 'default' })}*/}
        {/*  name="code"*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*      message: Lang({ str: 'Please input your code!', section: 'default' }),*/}
        {/*    },*/}
        {/*    { validator: codedValidator },*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <Input />*/}
        {/*</Form.Item>*/}
        {/*<Form.Item<FieldTypetwo>*/}
        {/*  label={Lang({ str: 'Confirm password', section: 'default' })}*/}
        {/*  name="confirmpassword"*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*      message: Lang({ str: 'Please confirm password!', section: 'default' }),*/}
        {/*    },*/}
        {/*    { validator: confirmPasswordValidator },*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <Input.Password />*/}
        {/*</Form.Item>*/}
        {/*{showpass && (*/}
        {/*  <div className={styles.error_block}>*/}
        {/*    <p>*/}
        {/*      <span>{Lang({ str: 'ensure_pass', section: 'default' })}</span>*/}
        {/*    </p>*/}
        {/*    <p>{Lang({ str: 'ensure_pass_2', section: 'default' })}</p>*/}
        {/*    <p>{Lang({ str: 'ensure_pass_3', section: 'default' })}</p>*/}
        {/*    <p>{Lang({ str: 'ensure_pass_4', section: 'default' })}</p>*/}
        {/*  </div>*/}
        {/*)}*/}

        <Form.Item>
          <Button className={styles.button_prime} type="primary" htmlType="submit">
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default observer(SetupProfile);
