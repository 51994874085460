import { Alert, Button, Form, Input, Select } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { BaseEntityStoreActionsClass } from '../../../../DataSource/Stores/Admin/BaseEntityStoreActionsClass';
import { CitiesStore } from '../../../../DataSource/Stores/Admin/CitiesStore';

type FieldType = {
  name: string;
  sourceId?: string;
  cityId?: string;
  status_param_id: string;
  type_param_id: string;
  price_param_id: string;
};
interface Props {
  EntityStore: BaseEntityStoreActionsClass;
  needSourceId: boolean;
  needCities: boolean;
}
const Forms: React.FC<Props> = ({ EntityStore, needSourceId, needCities }) => {
  const [loadErrorStatus, setLoadErrorStatus] = useState<boolean>(false);
  const [loadErrorType, setLoadErrorType] = useState<boolean>(false);
  const [loadErrorPrice, setLoadErrorPrice] = useState<boolean>(false);

  const getList = () => {
    setLoadErrorStatus(false);
    setLoadErrorType(false);
    setLoadErrorPrice(false);
  };

  useEffect(() => {
    getList();
  }, []);

  if (loadErrorStatus || loadErrorType || loadErrorPrice) {
    return (
      <Alert
        message="Error loading data from the server. Please try again later."
        type="error"
        showIcon
        action={
          <Button size="small" onClick={getList}>
            Try Again
          </Button>
        }
      />
    );
  }

  return (
    <>
      <Form.Item<FieldType>
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input name!' }]}
      >
        <Input disabled={EntityStore.globalLoading} />
      </Form.Item>
      {needSourceId ? (
        <Form.Item<FieldType>
          label="SourceId"
          name="sourceId"
          rules={[{ required: true, message: 'Please input sourceId!' }]}
        >
          <Input disabled={true} />
        </Form.Item>
      ) : (
        <></>
      )}
      {needCities ? (
        <Form.Item<FieldType>
          label="City"
          name="cityId"
          rules={[{ required: true, message: 'Please select city!' }]}
        >
          <Select
            options={CitiesStore.dataList.map(({ _id, name }) => {
              return { value: _id, label: <span>{name}</span> };
            })}
          />
        </Form.Item>
      ) : (
        <></>
      )}
    </>
  );
};

export default observer(Forms);
