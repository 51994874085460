import { KeyOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, type FormProps, Input, message, Typography } from 'antd';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AuthStore } from '../../DataSource/Stores/AuthStore';
import styles from './Auth.module.scss';

type FieldType = {
  email?: string;
  password?: string;
  remember?: string;
};

const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
  const { email, password } = values;
  console.log('Email:', email);
  console.log('Password:', password);

  if (email && password) {
    try {
      await AuthStore.login(email, password);
    } catch (error) {
      message.error('Invalid username or password');
    }
  }
};

const { Title, Text } = Typography;

const Login = observer(() => (
  <div className={styles.fon_wrp}>
    <div>
      <Title level={2}>Войдите в аккаунт</Title>
      <p className={styles.text_add}>
        Нет аккаунта? <Link to="/register">Создать</Link>
      </p>
      <Form name="login" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item name="email" rules={[{ required: true, message: 'Пожалуйста, введите логин!' }]}>
          <Input prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}
        >
          <Input.Password prefix={<KeyOutlined />} placeholder="Пароль" />
        </Form.Item>
        <Form.Item>
          <Link className={styles.link} to="/forgot">
            Забыли пароль?
          </Link>
        </Form.Item>
        <Form.Item>
          <Button className={styles.button_prime} type="primary" htmlType="submit" block>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  </div>
));

export default Login;
