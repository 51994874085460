import { observer } from 'mobx-react';
import { config } from '../../../../../config';
import { AuthStore } from '../../../../../DataSource/Stores/AuthStore';
import ImageUpload from '../../../../Profile/ImageUpload';
import IconButton from '../../../../ui/IconButton';
import styles from './index.module.scss';

const Profile = () => {
  const user = AuthStore.currentUserData.user;

  return (
    <div className={styles.wrap}>
      <div className={styles.userCard}>
        <div className={styles.userCardWrap}>
          <ImageUpload
            image={`${config.EXTERNAL_ADDRESS}/image-uploader/${AuthStore.currentUserData.user.photo}`}
          />

          <div>
            <p>Имя</p>
            <div>{user.firstname}</div>
          </div>

          <div>
            <p>Телефон</p>
            <div>+7 (777) 123 45-67</div>
          </div>
        </div>
        <div className={styles.userStatus}>
          <div>

          </div>
        </div>
      </div>

      <div className={styles.moneyAudit}>
        <div className={styles.moneyAuditTop}>
          <h4>
            Учёт наличных средств
            <span>за сегодня</span>
          </h4>

          <IconButton icon="document" />
        </div>
        <div className={styles.moneyAuditValue}>24 000 ₸</div>
      </div>
    </div>
  );
};

export default observer(Profile);
