import styles from './index.module.scss';
import { Collapse, CollapseProps } from 'antd';
import React, { FC, ReactNode } from 'react';
import CommentBlock from './CommentBlock';
import { Comment } from '../../../../ types';

interface IProps {
  expandIcon: (panelProps: any) => ReactNode;
  comments?: Comment[];
}

const CommentsBlock: FC<IProps> = ({ expandIcon, comments }) => {
  const commentsCollapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'КОММЕНТАРИИ',
      children: (
        <div className={styles.commentsWrap}>
          {comments?.map((comment) => <CommentBlock key={comment._id} {...comment} />)}
        </div>
      ),
    },
  ];

  return (
    <Collapse
      items={commentsCollapseItems}
      expandIcon={expandIcon}
      expandIconPosition="right"
      bordered={false}
      ghost
    />
  );
};

export default CommentsBlock;
