import { Flex, message, Table } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { JobsDataType } from '../../ types';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import Input from '../../ui/Input';
import styles from './index.module.scss';
import { WorkspacesStore } from '../../../DataSource/Stores/Cabinet/WorkspacesStore';
import toast from 'react-hot-toast';
import Icon from '../../ui/Icon';
import { useDebounce } from '../../../utils';
import JobDrawer from '../NewJobsBlock/JobColumnItem/JobDrawer';

const Search: React.FC = () => {
  const { id } = useParams();
  const [filteredData, setFilteredData] = useState<JobsDataType[]>([]);
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [selectedJob, setSelectedJob] = useState<JobsDataType | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    (async () => {
      if (!id) return;
      await StagesStore.getListJobs(id);
      setFilteredData(StagesStore.dataJobsList);

      await StagesStore.getList(id);

      try {
        const res = await WorkspacesStore.membersGet.call(id);
        WorkspacesStore.setMembers(res.data);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (debouncedQuery) {
      getJobsById(debouncedQuery);
    } else {
      setFilteredData(StagesStore.dataJobsList);
    }
  }, [debouncedQuery]);

  const getJobsById = async (value: string) => {
    if (!id) return;
    setIsSearching(true);
    const res = await WorkspacesStore.getJobsById.call(id, value);
    setFilteredData(res.data);
    setIsSearching(false);
  };

  const handleCopy = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      message.info('Скопировано', 2);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleRowClick = (record: JobsDataType) => {
    setSelectedJob(record);
    setIsDrawerOpen(true);
  };

  const columns = [
    {
      title: 'Job ID',
      dataIndex: 'jobId',
      key: 'jobId',
      render: (text: string) =>
        text ? (
          <Flex gap={12}>
            {text}
            <div
              className={styles.copyBtn}
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(text);
              }}
            >
              <Icon slug="copy" />
            </div>
          </Flex>
        ) : (
          '-'
        ),
    },
    {
      title: 'External ID',
      dataIndex: 'externalId',
      key: 'externalId',
      render: (_: string, record: JobsDataType) =>
        record.externalId?.bitrixId ? (
          <Flex gap={12}>
            {record.externalId?.bitrixId}
            <div
              className={styles.copyBtn}
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(record.externalId?.bitrixId as string);
              }}
            >
              <Icon slug="copy" />
            </div>
          </Flex>
        ) : (
          '-'
        ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Этап',
      dataIndex: 'stageId',
      key: 'stageId',
      render: (_: string, record: JobsDataType) =>
        StagesStore.dataList.find((item) => item._id === record.stageId)?.name,
    },
    {
      title: 'Создан',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => dayjs(text).format('HH:mm | DD.MM.YYYY'),
      sorter: (a: any, b: any) => {
        if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) return -1;
        if (dayjs(a.createdAt).isAfter(dayjs(b.createdAt))) return 1;
        return 0;
      },
    },
    {
      title: 'Целевая дата',
      dataIndex: 'targetDate',
      key: 'targetDate',
      render: (text: string) => dayjs(text).format('HH:mm | DD.MM.YYYY'),
      sorter: (a: any, b: any) => {
        if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) return -1;
        if (dayjs(a.createdAt).isAfter(dayjs(b.createdAt))) return 1;
        return 0;
      },
    },
    {
      title: 'Изменен',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => dayjs(text).format('HH:mm | DD.MM.YYYY'),
      sorter: (a: any, b: any) => {
        if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) return -1;
        if (dayjs(a.createdAt).isAfter(dayjs(b.createdAt))) return 1;
        return 0;
      },
    },
    {
      title: 'Исполнитель',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      render: (_: string, record: JobsDataType) =>
        record.assignedTo && WorkspacesStore.members[record.assignedTo]?.userId?.firstname,
    },
  ];

  return (
    <div className={styles.searchPage}>
      <div className={styles.searchPageTop}>
        <p className={styles.title}>Поиск</p>
        <Input
          width="400px"
          placeholder="Поиск по Job ID / External ID"
          icon="search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>

      <Table
        columns={columns}
        dataSource={filteredData}
        loading={StagesStore.loadingArchives || isSearching}
        rowKey="_id"
        onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
        components={{
          body: {
            wrapper: (props: any) => {
              if (StagesStore.loadingArchivesEr) {
                return (
                  <div className={styles.error}>
                    <p>Error loading data: {StagesStore.loadingArchivesEr}</p>
                  </div>
                );
              }
              return <tbody {...props} />;
            },
          },
        }}
      />

      {selectedJob && (
        <JobDrawer
          {...selectedJob}
          _id={selectedJob._id}
          workspaceId={id}
          bitrixId={selectedJob?.externalId?.bitrixId}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}
    </div>
  );
};

export default observer(Search);
