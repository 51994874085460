import { Button, message, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import Lang from '../../Lang';
import styles from '../ProductAccounts.module.scss';
import { OrderStore } from '../../../DataSource/Stores/Site/OrdersStore';
import React from 'react';

interface OperationsProps {
  _id: string;
  product_id: string;
  payment_status: string;
  product_param_id: string;
  product_name: string;
}

const Operations: React.FC<OperationsProps> = ({
  _id,
  product_id,
  payment_status,
  product_param_id,
  product_name,
}) => {
  const handleCancel = (key: React.Key) => {
    console.log('---- handleCancel|key=', key);
    message.loading('Loading');
    OrderStore.cancelProduct(key as string)
      .then(() => {
        message.destroy();
        message.info('Success');
      })
      .catch((error) => {
        message.destroy();
        if (error?.message == 'Request failed with status code 409') {
          return message.error('Product in use');
        }
        message.error('Error');
      });
  };
  const handleBuy = (order_id: string, product_id: string, product_param_id: string) => {
    console.log('---- handleBuy|product_id=', product_id);
    message.loading('Loading');
    OrderStore.getPaymentUrl(order_id, product_id, product_param_id)
      .then((res: PaymentOrderResponse) => {
        message.destroy();
        if (!res.fp_order) {
          message.warning('Getting payment URL error');
          return;
        }
        message.info('Redirection to payment...');
        setTimeout(
          (pg_redirect_url) => {
            window.location.replace(pg_redirect_url);
          },
          1000,
          res.fp_order.pg_redirect_url
        );
      })
      .catch((error) => {
        message.destroy();
        if (error?.message == 'Request failed with status code 409') {
          return message.error('Product in use');
        }
        message.error('Error');
      });
  };

  const pending = payment_status?.toUpperCase() == 'PENDING';
  // console.log('---- ww|product_name=', product_name);
  const hasAmount = product_name?.match(/^([$€£¥]\d+(,|))\d+$/);
  return (
    <div className={styles.but_group}>
      <Button
        loading={OrderStore.loadingPaymentUrl}
        disabled={!pending || !hasAmount || OrderStore.loadingDelete}
        onClick={() => handleBuy(_id, product_id, product_param_id)}
      >
        {Lang({ str: 'buy', section: 'default' })}
      </Button>
      <Popconfirm
        title={Lang({ str: 'Sure to cancel?', section: 'default' })}
        disabled={!pending}
        onConfirm={() => handleCancel(_id)}
      >
        <Button
          loading={OrderStore.loadingDelete}
          disabled={!pending || OrderStore.loadingPaymentUrl}
        >
          {Lang({ str: 'cancel', section: 'default' })}
        </Button>
      </Popconfirm>
    </div>
  );
};

export default observer(Operations);
