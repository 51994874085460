import { Alert, Button, message, Popconfirm, Select, Table, TableProps } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import usePageTitle from '../../DataSource/Hooks/usePageTitle';
import { OrderStore } from '../../DataSource/Stores/Admin/OrdersStore';
import Lang from '../Lang';
import styles from './Admin.module.scss';
import { OrderParams } from '../../DataSource/Stores/Admin/OrderParams';

export interface OrdersDataType {
  key: number;
  _id: string;
  createdAt: string;
  user_name_email: string;
  status: any;
  product_name: string;
  order_status_param_id: string;
  payment_status_param_id: string;
}
interface StatusColorMap {
  [key: string]: string;
}
const handleDelete = (key: React.Key) => {
  console.log('---- handleDelete|key=', key);
  message.loading('Loading');
  OrderStore.deleteProduct(key as string)
    .then(() => {
      message.destroy();
      message.info('Success');
    })
    .catch((error) => {
      message.destroy();
      if (error?.message == 'Request failed with status code 409') {
        return message.error('Product in use');
      }
      message.error('Error');
    });
};
const handleCancel = (key: React.Key) => {
  console.log('---- handleCancel|key=', key);
  message.warning('In development');
  // message.loading('Loading');
  // OrderStore.deleteProduct(key as string)
  //   .then(() => {
  //     message.destroy();
  //     message.info('Success');
  //   })
  //   .catch((error) => {
  //     message.destroy();
  //     if (error?.message == 'Request failed with status code 409') {
  //       return message.error('Product in use');
  //     }
  //     message.error('Error');
  //   });
};
const handleChange = (statusName: OrdersStatus, order_id: string, value: string) => {
  console.log(`selected|order_id=${order_id}`);
  console.log(`selected|value=${value}`);

  OrderStore.changeOrdersStatus(statusName, order_id, value).catch(() =>
    message.error('Error while change order status. Try later')
  );
};

const columns: TableProps<OrdersDataType>['columns'] = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
  },
  {
    title: 'Date time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Users',
    dataIndex: 'user_name_email',
    key: 'user_name_email',
  },
  {
    title: 'Product Type',
    dataIndex: 'product_param_name',
    key: 'product_param_name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Status',
    key: 'order_status',
    dataIndex: 'order_status',
    render: (_, { _id, order_status_param_id }) => (
      <Select
        defaultValue={order_status_param_id}
        style={{ width: 100 }}
        onChange={(val) => handleChange('order_status', _id, val)}
        options={
          (OrderStore.paramList['order_status'] || []).map((i) => ({
            key: i._id,
            value: i._id,
            label: i.field_name.toUpperCase(),
          })) as []
        }
        loading={
          OrderStore.loadingParamList['order_status'] ||
          OrderStore.loadingChangeParam['order_status']
        }
        disabled={
          OrderStore.loadingParamList['order_status'] ||
          OrderStore.loadingChangeParam['order_status']
        }
      />
    ),
  },
  {
    title: 'Payment status',
    key: 'payment_status',
    dataIndex: 'status',
    render: (_, { _id, payment_status_param_id }) => (
      <Select
        defaultValue={payment_status_param_id}
        style={{ width: 100 }}
        onChange={(val) => handleChange('payment_status', _id, val)}
        options={
          (OrderStore.paramList['payment_status'] || []).map((i) => ({
            key: i._id,
            value: i._id,
            label: i.field_name.toUpperCase(),
          })) as []
        }
        loading={
          OrderStore.loadingParamList['payment_status'] ||
          OrderStore.loadingChangeParam['payment_status']
        }
        disabled={
          OrderStore.loadingParamList['payment_status'] ||
          OrderStore.loadingChangeParam['payment_status']
        }
      />
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  // {
  //   title: 'Delete',
  //   dataIndex: 'operation',
  //   render: (_, record) => (
  //     <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record._id)}>
  //       <a>{Lang({ str: 'delete', section: 'default' })}</a>
  //     </Popconfirm>
  //   ),
  // },
  {
    title: 'Cancel',
    dataIndex: 'operation',
    render: (_, { _id, payment_status_param_id }) => {
      // Проверка на paid. Только для них можно отменить. Остальным должно быть disabled
      const disabled = !(OrderStore.paramList['payment_status'] || []).some(
        ({ _id, field_name }) =>
          _id === payment_status_param_id && /(^paid|^tttt45)/i.test(field_name)
      );
      const disabledText = Lang({ str: 'cancel', section: 'default' });
      return (
        <Popconfirm
          disabled={disabled}
          title="Sure to cancel?"
          onConfirm={() => handleCancel(_id)}
          className={disabled ? 'unactive' : ''}
        >
          {disabled ? disabledText : <a>{disabledText}</a>}
        </Popconfirm>
      );
    },
  },
];

const OrdersList: React.FC = () => {
  usePageTitle(Lang({ str: 'orderslist', section: 'default' }));
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    if (init) return;
    setInit(true);

    getList();
  }, []);

  const getList = async () => {
    console.log('---- ww|getList|getParamType|1');
    try {
      await OrderParams.getOrderTypesList();
    } catch (error) {
      console.error('---- ww|getOrderTypesList|error=', error);
    }
    await Promise.all(
      // @ts-ignore
      OrderParams.paramTypesList.map(async (i) => {
        return OrderParams.getParametersList(i.key);
      })
    );
    console.log('---- ww|getList|getParamType|2');

    // OrderStore.getOrdersStatuses('order_status');
    // OrderStore.getOrdersStatuses('payment_status');
    OrderStore.getList();
  };

  return (
    <div className="pt-80">
      <h1>{Lang({ str: 'orderslist', section: 'default' })}</h1>
      <div className={styles.table + ' mt-32'}>
        {OrderStore.loadingError && (
          <Alert
            message="Error loading data from the server. Please try again later."
            type="error"
            showIcon
            action={
              <Button size="small" onClick={getList}>
                Try Again
              </Button>
            }
          />
        )}
        {/* <Button onClick={showModal}>+</Button>*/}
        <Table
          scroll={{ x: 1100 }}
          columns={columns}
          dataSource={OrderStore.dataList}
          loading={OrderStore.loading}
        />
      </div>
    </div>
  );
};

export default observer(OrdersList);
