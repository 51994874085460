import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormListFieldData } from 'antd';
import { FormListOperation } from 'antd/es/form/FormList';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import InputFields from './InputFields';
import { BaseEntityStoreActionsClass } from '../../../../DataSource/Stores/Admin/BaseEntityStoreActionsClass';
import { BaseParamsClass } from '../../../../DataSource/Stores/Admin/BaseParamsClass';

interface Description {
  param_id: string;
  value: string;
}

type FieldType = {
  type_param_id: Description[];
};

interface InputFieldsProps {
  tab: any;
  EntityStore: BaseEntityStoreActionsClass;

  EntityParams: BaseParamsClass;
}
//i: Tab
const FormParamType: React.FC<InputFieldsProps> = ({ tab, EntityStore, EntityParams }) => {
  const [formsCnt, setFormsCnt] = useState(EntityParams._dataList[tab.key]?.length || 0);
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    if (init) return;
    setInit(true);
  }, []);

  const renderFields = (fields: FormListFieldData[], { add, remove, move }: FormListOperation) => (
    <>
      {fields.map(({ key, name, ...restField }, fieldIndex) => (
        <InputFields
          key={key}
          formsCnt={formsCnt || EntityParams._dataList[tab.key]?.length}
          kkey={key}
          name={name}
          remove={remove}
          move={move}
          restField={restField}
          setFormsCnt={setFormsCnt}
          param_type_id={tab.key}
          fieldIndex={fieldIndex}
          label={tab.label}
          EntityStore={EntityStore}
          EntityParams={EntityParams}
        />
      ))}
      <Form.Item>
        <Button
          type="dashed"
          onClick={() => {
            add();
            setFormsCnt(formsCnt + 1);
          }}
          block
          icon={<PlusOutlined />}
          disabled={EntityStore.globalLoading}
        >
          Add {tab.label}
        </Button>
      </Form.Item>
    </>
  );

  return (
    <Form.Item<FieldType>
      label={tab.label}
      name={tab.key}
      className="block_list"
      rules={[
        {
          validator: (_, __: Description[]) => {
            // if (formsCnt) {
            return Promise.resolve();
            // } else {
            //   return Promise.reject(`Please add at least one ${tab.label}`);
            // }
          },
        },
      ]}
    >
      <Form.List name={tab.key}>
        {(fields: FormListFieldData[], { add, remove, move }) =>
          renderFields(fields, { add, remove, move })
        }
      </Form.List>
    </Form.Item>
  );
};

export default observer(FormParamType);
