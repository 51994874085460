import { Form, message, Flex } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import usePageTitle from '../../DataSource/Hooks/usePageTitle';
import { AuthStore } from '../../DataSource/Stores/AuthStore';
import { ProfileStore } from '../../DataSource/Stores/Site/ProfileStore';
import Button from '../ui/Button';
import ModalProfile from './ModalProfile';
import styles from './index.module.scss';
import IconButton from '../ui/IconButton';
import ImageUpload from './ImageUpload';
import { config } from '../../config';
import Input from '../ui/Input';
import { $class } from '../../utils';
import { useLocation } from 'react-router-dom';

export type FieldTypetwo = {
  firstname: string;
  secondname: string;
  password: string;
  email: string;
  confirmpassword: string;
  token?: string;
};

const Profile: React.FC = () => {
  usePageTitle('Профиль');

  const [form] = Form.useForm();
  const location = useLocation();

  useEffect(() => {
    form.setFieldsValue(AuthStore.currentUserData.user);
  }, []);

  const forceUpdate = () => {
    form.setFieldsValue({ email: AuthStore.currentUserData.user.email });
  };

  const onFinish = async (values: FieldTypetwo) => {
    if (values.password && values.password !== values.confirmpassword) {
      message.error('Passwords do not match');
      return; // Прерываем отправку формы
    }

    try {
      await AuthStore.editProfile(values);
    } catch (error) {
      message.error('Error');
      return;
    }
    message.info('Success');
  };

  const validateConfirmPassword = (_: any, value: string) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('The two passwords do not match!'));
  };

  const openModal = () => {
    ProfileStore.isModalOpen = true;
  };

  return (
    <div className={$class(styles.profile, [styles.mobile, location.pathname.includes('user')])}>
      <ImageUpload
        image={`${config.EXTERNAL_ADDRESS}/image-uploader/${AuthStore.currentUserData.user.photo}`}
      />

      <Form
        key={Date.now()}
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item<FieldTypetwo>
          label={<p className={styles.formLabel}>Имя</p>}
          name="firstname"
          rules={[
            {
              required: true,
              message: 'Please input your first name!',
            },
            {
              message: 'You can only enter letters',
              pattern: new RegExp(/^[A-Za-zа-яёА-ЯЁ]+$/),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item<FieldTypetwo>
          label={<p className={styles.formLabel}>Фамилия</p>}
          name="secondname"
          rules={[
            { required: true, message: 'Please input your second name!' },
            {
              message: 'You can only enter letters',
              pattern: new RegExp(/^[A-Za-zа-яёА-ЯЁ]+$/),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item<FieldTypetwo> label={<p className={styles.formLabel}>E-mail</p>} name="email">
          <Flex gap={16}>
            <Input disabled />
            <IconButton icon="pen" size="lg" onClick={openModal} />
          </Flex>
        </Form.Item>
        <Form.Item<FieldTypetwo>
          label={<p className={styles.formLabel}>Пароль</p>}
          name="password"
          rules={[{ message: 'Please input your password!' }]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item<FieldTypetwo>
          label={<p className={styles.formLabel}>Повторите пароль</p>}
          name="confirmpassword"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please confirm password!' },
            { validator: validateConfirmPassword },
          ]}
          style={{ marginBottom: '40px' }}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item style={{ margin: 0 }}>
          <Button variant="secondary">Сохранить</Button>
        </Form.Item>
      </Form>

      <ModalProfile forceUpdate={forceUpdate} />
    </div>
  );
};
export default observer(Profile);
