import { Button, Form, type FormProps, message, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { BaseEntityStoreActionsClass } from '../../../../DataSource/Stores/Admin/BaseEntityStoreActionsClass';
import { BaseParamsClass } from '../../../../DataSource/Stores/Admin/BaseParamsClass';
import styles from '../../Admin.module.scss';
import Forms from './Forms';
import FormParamType from './ParamType';

export interface Condition {
  param_id: string;
  value: string;
}
export interface TypeParamIds {
  param_id: string;
  value: string;
}

export type CpLsListFieldType = {
  _id: string;
  key: string;
  name: string;
  isActive: boolean;
  type_param_id: string;
  status_param_id: string;
};

export type CpLsFieldType = {
  _id?: string;
  name: string;
  sourceId: string;
  isActive: boolean;
  type_param_ids: TypeParamIds[];
};

type Param = {
  param_id: string;
  value: string;
};
type TypeParam = {
  [key: string]: Param[];
};
export type Entity = {
  name: string;
  type_param_ids: TypeParam[];
};

interface Props {
  EntityStore: BaseEntityStoreActionsClass;

  EntityParams: BaseParamsClass;
  needSourceId: boolean;
  needCities: boolean;
}

const onFinishFailed: FormProps<CpLsFieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const ModalForm: React.FC<Props> = ({ EntityStore, EntityParams, needSourceId, needCities }) => {
  const formRef = useRef<any>(null);

  const onFinish: FormProps<CpLsFieldType>['onFinish'] = async (values) => {
    console.log('Success:', values);

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if (/^[a-f0-9]{24}$/.test(key)) {
          if (!values.type_param_ids) values.type_param_ids = [];
          // @ts-ignore
          if (values[key]) {
            // @ts-ignore
            values.type_param_ids.push({ [key]: values[key] });
            // @ts-ignore
            delete values[key];
          }
        }
      }
    }

    message.loading('Loading');
    try {
      if (EntityStore.dataEntity?._id) {
        values._id = EntityStore.dataEntity._id;
        await EntityStore.edit(values as unknown as Entity);
      } else {
        await EntityStore.add(values as unknown as Entity);
      }
      message.destroy();
    } catch (error: any) {
      console.error('onFinish exception:error?.status=', error?.message);
      message.destroy();
      message.error('Error add challenge');
      return;
    }

    await EntityParams.getParameterTypesList();
    await EntityStore.getList();

    message.info('Success');
    EntityStore.isModalOpen = false;
    EntityStore.dataEntity = null;
    // formRef.current?.resetFields();
  };

  const handleSubmit = () => {
    console.log('---- ww|!! formRef.current', !!formRef.current);
    formRef.current?.submit();
  };

  const handleOk = () => {
    EntityStore.isModalOpen = false;
  };

  const handleCancel = () => {
    EntityStore.isModalOpen = false;
  };
  const handleAfter = () => {
    console.log('---- ww|handleCancel');

    if (EntityStore.dataEntity) {
      EntityStore.dataEntity = null;
    }
    formRef.current?.resetFields();
    EntityStore.isModalOpen = false;
  };

  // console.log('---- ww|EntityStore.dataEntity=', EntityStore.dataEntity);

  return (
    <Modal
      afterClose={handleAfter}
      wrapClassName={'dfddffscroll'}
      title="List"
      open={EntityStore.isModalOpen}
      onOk={handleOk}
      style={{ top: 20 }}
      className={styles.modalform + ' ' + styles.modalformheight}
      onCancel={handleCancel}
      width={800}
      footer={[
        <div className={styles.new_footer}>
          <Button
            key="submit"
            type="primary"
            loading={EntityStore.globalLoading}
            onClick={handleSubmit}
          >
            Save
          </Button>

          <Button
            key="link"
            type="primary"
            loading={EntityStore.globalLoading}
            onClick={handleCancel}
          >
            Close
          </Button>
        </div>,
      ]}
    >
      <div className={styles.scroll_bl}>
        <Form
          key={Date.now()} // Установите key с уникальным значением
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ width: '100%' }}
          initialValues={EntityStore.dataEntity || {}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          ref={formRef} // Привязываем ссылку на форму
        >
          <Forms EntityStore={EntityStore} needSourceId={needSourceId} needCities={needCities} />
          {
            (EntityParams.paramTypesList || []).map((i) => {
              // console.log('---- ww|paramTypesList|i=', i);
              return (
                <FormParamType
                  key={i.key}
                  tab={i}
                  EntityStore={EntityStore}
                  EntityParams={EntityParams}
                />
              );
            })
            //FormParamType
          }
          {/*<FormDescription />*/}
          {/*<FormCondition />*/}
        </Form>
      </div>
    </Modal>
  );
};

export default observer(ModalForm);
