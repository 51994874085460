import type { MenuProps, TableColumnsType, TableProps } from 'antd';
import { Button, Dropdown, Space, Table } from 'antd';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ManagerStore } from '../../../../../DataSource/Stores/ManagerStore';
import { ManagerStoreContext } from '../../../../../DataSource/context/title';
import Icon from '../../../../ui/Icon';
import CourierItem from '../../CourierItem';
import StatusTag from '../../StatusTag';
import NewOrders from '../NewOrders';
import ModalAdd from './Modal/Add';
import ModalOrder from './Modal/Order';
import styles from './index.module.scss';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;
type Filters = Parameters<OnChange>[1];

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface DataType {
  key: string;
  company: string;
  timedelivery: string;
  couriers: string;
  status: string;
  idorder: string;
  map: string;
}

const data: DataType[] = [
  {
    key: '1',
    idorder: '#00238049',
    company: 'John Brown',
    timedelivery: '14:15, 16 июня',
    couriers: 'Андрей',
    status: 'Отменено',
    map: 'ул. Майлина, 17',
  },
  {
    key: '2',
    idorder: '#00238049',
    company: 'Jim Green',
    timedelivery: '14:15, 16 июня',
    couriers: 'Андрей',
    status: 'В доставке',
    map: 'ул. Майлина, 17',
  },
  {
    key: '3',
    idorder: '#00238049',
    company: 'Joe Black',
    timedelivery: '14:15, 16 июня',
    couriers: 'Андрей',
    status: 'В обработке',
    map: 'ул. Майлина, 17',
  },
  {
    key: '4',
    idorder: '#00238049',
    company: 'Jim Red',
    timedelivery: '14:15, 16 июня',
    couriers: 'Андрей',
    status: 'Доставлено',
    map: 'ул. Майлина, 17',
  },
];

const ManagingOrder = () => {
  const { managersStore } = useContext(ManagerStoreContext)!;
  useEffect(() => {
    managersStore.setTitle('Управление заказами');
  }, [managersStore]);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const showModal = () => {
    ManagerStore.ModalOpenOrder = true;
  };

  const showOrder = () => {
    ManagerStore.modalOpenOrderItem = true;
  };

  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const handleChange: OnChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as Sorts);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setActiveFilter(null);
  };

  const setFilter = () => {
    setFilteredInfo({
      status: ['Доставлено'],
    });
    setActiveFilter('delivered');
  };

  const editFilter = () => {
    setFilteredInfo({
      status: ['В обработке'],
    });

    setActiveFilter('processing');
  };

  const delivFilter = () => {
    setFilteredInfo({
      status: ['В доставке'],
    });
    setActiveFilter('ondelivery');
  };
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" href="/profile">
          Редактировать
        </a>
      ),
    },
    {
      key: '2',
      label: <a>Удалить</a>,
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: 'ID заказа',
      dataIndex: 'idorder',
      width: '10%',
      key: 'idorder',
      sorter: (a, b) => a.idorder.length - b.idorder.length,
      sortOrder: sortedInfo.columnKey === 'idorder' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <a onClick={showOrder}>{text}</a>,
    },
    {
      title: 'Компания',
      dataIndex: 'company',
      width: '15%',
      key: 'company',
      sorter: (a, b) => a.company.length - b.company.length,
      sortOrder: sortedInfo.columnKey === 'company' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Время доставки',
      dataIndex: 'timedelivery',
      key: 'timedelivery',
      sorter: (a, b) => a.timedelivery.length - b.timedelivery.length,
      sortOrder: sortedInfo.columnKey === 'timedelivery' ? sortedInfo.order : null,
      ellipsis: true,
      width: '15%',
    },
    {
      title: 'Курьер',
      width: '20%',
      dataIndex: 'couriers',
      key: 'couriers',
      sorter: (a, b) => a.couriers.length - b.couriers.length,
      sortOrder: sortedInfo.columnKey === 'couriers' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <CourierItem title={text} icon={true} />,
    },

    {
      title: 'Статус',
      width: '15%',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'В доставке', value: 'В доставке' },
        { text: 'В обработке', value: 'В обработке' },
        { text: 'Доставлено', value: 'Доставлено' },
        { text: 'Отменено', value: 'Отменено' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value as string),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <>
          {record.status === 'Доставлено' ? (
            <StatusTag theme="create" title={text} />
          ) : record.status === 'В обработке' ? (
            <StatusTag theme="indelivery" title={text} />
          ) : record.status === 'Отменено' ? (
            <StatusTag theme="unactive" title={text} />
          ) : record.status === 'В доставке' ? (
            <StatusTag theme="work" title={text} />
          ) : (
            <span>{text}</span>
          )}
        </>
      ),
    },
    {
      title: 'Маршрут',
      width: '20%',
      dataIndex: 'map',
      key: 'map',
      sorter: (a, b) => a.map.length - b.map.length,
      sortOrder: sortedInfo.columnKey === 'map' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: '',
      width: '5%',
      key: 'x',
      render: () => (
        <Dropdown className="menuprogile" trigger={['click']} menu={{ items }}>
          <a onClick={(e) => e.preventDefault()}>
            <Icon slug={'fi-rr-menu-dots-vertical'} />
          </a>
        </Dropdown>
      ),
    },
  ];
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex_group">
        <div className="tabl_bl">
          <div className="tabl_bl__top">
            <div className="d_flex">
              <p>16 июля 2024 - 34 заказов</p>
              <Button onClick={showModal} type="primary">
                Новый заказ
              </Button>
            </div>
          </div>
          <Space className="tabl_top tabl_bl__buttons">
            <p>Журнал заказов</p>
            <Button
              className={`filter_button ${activeFilter === null ? 'active' : ''}`}
              onClick={clearFilters}
            >
              Все
            </Button>
            <Button
              className={`filter_button ${activeFilter === 'ondelivery' ? 'active' : ''}`}
              onClick={delivFilter}
            >
              На доставке
            </Button>
            <Button
              className={`filter_button ${activeFilter === 'delivered' ? 'active' : ''}`}
              onClick={setFilter}
            >
              Доставленно
            </Button>
            <Button
              className={`filter_button ${activeFilter === 'processing' ? 'active' : ''}`}
              onClick={editFilter}
            >
              В обработке
            </Button>
          </Space>
          <Table
            columns={columns}
            pagination={{ position: ['topRight'] }}
            dataSource={data}
            onChange={handleChange}
          />
        </div>
        <div className={styles.new_orders}>
          <NewOrders />
        </div>
      </div>
      <ModalAdd />
      <ModalOrder />
    </DndProvider>
  );
};

export default observer(ManagingOrder);
