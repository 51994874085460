import { observer } from 'mobx-react';
import React from 'react';

import Lang from '../../Lang';

import { Params } from '../../../DataSource/Stores/Admin/CitiesParams';
import BaseParameters from '../BaseParameters';

const CitiesParameters: React.FC = () => {
  return (
    <BaseParameters
      Params={Params}
      title={Lang({ str: 'citiesParameters', section: 'default' })}
    ></BaseParameters>
  );
};

export default observer(CitiesParameters);
