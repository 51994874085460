import { useContext, useEffect, useState } from 'react';
import { ManagerStoreContext } from '../../../DataSource/context/title';
import Kanban from './Kanban';
import styles from './index.module.scss';

interface IProps {
  workspaceId: string;
  name: string;
}

const DeliveryCenter = ({ workspaceId }: IProps) => {
  const { managersStore } = useContext(ManagerStoreContext)!;
  useEffect(() => {
    managersStore.setTitle('Центр доставки');
  }, [managersStore]);
  const [view, setView] = useState<'list' | 'columns'>('columns');
  return (
    <div className={styles.distributionCenterPage}>
      <Kanban workspaceId={workspaceId} view={view} />
    </div>
  );
};

export default DeliveryCenter;
