import styles from '../Workspaces/index.module.scss';
import NewJobsBlock from '../NewJobsBlock';
import { useState } from 'react';

interface IProps {
  workspaceId: string;
  name: string;
}

const DistributionCenter = ({ workspaceId, name }: IProps) => {
  const [view, setView] = useState<'list' | 'columns'>('columns');
  return (
    <div className={styles.distributionCenterPage}>
      <div className={styles.distributionCenterPageTop}>
        <p className={styles.title}>{name}</p>
      </div>
      <NewJobsBlock workspaceId={workspaceId} view={view} />
    </div>
  );
};

export default DistributionCenter;
