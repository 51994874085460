import { makeAutoObservable, observable, runInAction } from 'mobx';
import { basicRequestGet } from '../../Requests/basicRequestGet';
import { OrdersDataType } from '../../../components/Admin/OrdersList';
import { basicRequestDelete } from '../../Requests/basicRequestDelete';
import { basicRequestPost } from '../../Requests/basicRequestPost';
import { DataTypeMap, LoadingMap } from '../../../components/Admin/OrdersParameters';
import { basicRequestPatch } from '../../Requests/basicRequestPatch';

type ChangeOrdersStatusParams = {
  order_status_param_id?: string;
  payment_status_param_id?: string;
};

export class OrdersStoreActionsClass {
  @observable
  loadingError: boolean = false;

  @observable
  loading: boolean = false;

  @observable dataList: OrdersDataType[] = [];

  @observable loadingParamList: LoadingMap = {};
  @observable loadingParamError: LoadingMap = {};
  @observable loadingChangeParam: LoadingMap = {};
  @observable loadingChangeParamError: LoadingMap = {};
  @observable paramList: DataTypeMap = { type: [] as ChParamDataType[] };

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  /**
   *
   */
  async getList() {
    console.log('---- ww|getList');
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.loadingError = false;
    this.dataList = [];

    try {
      const res = await basicRequestGet('/admin/orders_list');
      console.log('---- ww|getList|res.data=', res.data);
      this.dataList = res.data.data.docs.map((i: any, k: number) => {
        i.key = k + 1;
        // i.tags = [];
        return i;
      }) as OrdersDataType[];
    } catch (error) {
      // throw error;
      console.error('getList|error=', error);
      this.loadingError = true;
    } finally {
      this.loading = false;
    }
  }

  /**
   *
   */
  async deleteProduct(_id: string) {
    const index = this.dataList.findIndex((obj) => obj._id === _id);
    console.log('deleteProduct|index=', index);
    if (index === -1) {
      console.error('deleteProduct|index !== -1');
      throw 'Remove error';
    }
    await basicRequestDelete(`/admin/orders_list/delete/${_id}`);
    this.dataList = this.dataList.filter((obj) => obj._id !== _id);
  }

  /**
   *
   */
  async getOrdersStatuses(param_type: string) {
    if (this.loadingParamList[param_type]) {
      return;
    }
    runInAction(() => {
      this.loadingParamList[param_type] = true;
      this.loadingParamError[param_type] = false;
    });

    try {
      const res = await basicRequestPost('/admin/orders_list/list', {
        // filter: { param_type },
        param_type,
      });
      // return {
      //   data: res.data.data.data.map((i: any) => {
      //     i.key = i._id;
      //     i.tags = [];
      //     return i;
      //   }) as DataType[],
      //   pagination: res.data.data.pagination,
      // };
      // console.log('---- ww|getList|basicRequestPost|param_type=', param_type);
      runInAction(() => {
        this.paramList[param_type] = res.data.data.docs.map((i: any) => {
          i.key = i._id;
          i.tags = [];
          return i;
        }) as ChParamDataType[];
      });
    } catch (error) {
      runInAction(() => {
        this.loadingParamError[param_type] = true;
      });
      throw error;
    } finally {
      runInAction(() => {
        this.loadingParamList[param_type] = false;
      });
    }
  }

  /**
   *
   */
  async changeOrdersStatus(param_type: OrdersStatus, order_id: string, param_id: string) {
    if (this.loadingChangeParam[param_type]) {
      return;
    }
    runInAction(() => {
      this.loadingChangeParam[param_type] = true;
      this.loadingChangeParamError[param_type] = false;
    });

    const query: ChangeOrdersStatusParams = {};
    if (param_type == 'order_status') {
      query.order_status_param_id = param_id;
    } else if (param_type == 'payment_status') {
      query.payment_status_param_id = param_id;
    }

    try {
      const res = await basicRequestPatch(
        `/admin/orders_list/change_${param_type}/${order_id}`,
        query
      );
      console.log('---- ww|getList|basicRequestPost|res=', res);
    } catch (error) {
      console.log('---- ww|changeOrdersStatus|error=', error);
      runInAction(() => {
        this.loadingChangeParamError[param_type] = true;
      });
      throw error;
    } finally {
      runInAction(() => {
        this.loadingChangeParam[param_type] = false;
      });
    }
  }
}
