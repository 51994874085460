import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { AuthStore } from '../../DataSource/Stores/AuthStore';
import AdminLayout from '../../layouts/AdminLayout';
import AuthLayout from '../../layouts/AuthLayout';
import CabinetLayout from '../../layouts/CabinetLayout';
import OrdersList from '../Admin/OrdersList';
import OrdersParameters from '../Admin/OrdersParameters';
import ProductList from '../Admin/ProductList';
import ProductParameters from '../Admin/ProductParameters';
import Users from '../Admin/Users';
import CheckingAuth from '../Auth/CheckingAuth';
import CreateByEmail from '../Auth/CreateByEmail';
// import Forgot from '../Auth/Forgot';
import { ManagerStoreContext } from '../../DataSource/context/title';
import { ManagerStore } from '../../DataSource/Stores/ManagerStore';
import CabinetLayout2 from '../../layouts/CabinetLayout2';
import MobileLayout from '../../layouts/MobileLayout';
import Categories from '../Admin/Categories';
import CategoriesParameters from '../Admin/CategoriesParameters';
import Cities from '../Admin/Cities';
import CitiesParameters from '../Admin/CitiesParameters';
import Groups from '../Admin/Groups';
import GroupsParameters from '../Admin/GroupsParameters';
import IikoIntegration from '../Admin/IikoIntegration';
import Modifiers from '../Admin/Modifiers';
import ModifiersParameters from '../Admin/ModifiersParameters';
import Orders from '../Admin/Orders';
import OrdersParametersV2 from '../Admin/OrdersParametersV2';
import Organizations from '../Admin/Organizations';
import OrganizationsParameters from '../Admin/OrganizationsParameters';
import Products from '../Admin/Products';
import ProductsParameters from '../Admin/ProductsParameters';
import UsersParameters from '../Admin/UsersParameters';
import Check from '../Auth/Check';
import SignUp from '../Auth/Create/SignUp';
import Forgot from '../Auth/Forgot';
import Login from '../Auth/Login';
import NetworkError from '../Auth/NetworkError';
import NewPass from '../Auth/NewPass';
import ZakazBuketov from '../Auth/ZakazBuketov';
import Distribution from '../Cabinet/DeliveryCenter/Kanban/Distribution';
import ManagingOrder from '../Cabinet/DeliveryCenter/Kanban/ManagingOrder';
import ManagingZoning from '../Cabinet/DeliveryCenter/Kanban/ManagingZoning';
import History from '../Cabinet/History';
import Main from '../Cabinet/Main';
import MemberApp from '../Cabinet/MemberApp';
import Members from '../Cabinet/Members';
import Search from '../Cabinet/Search';
import Workspaces from '../Cabinet/Workspaces';
import Failure from '../Failure';
import Invite from '../Invite';
import InviteSuccess from '../InviteSuccess';
import Leaderboard from '../Leaderboard/Leaderboard';
import ProductAccounts from '../ProductAccounts/ProductAccounts';
import Profile from '../Profile';
import Success from '../Success';
import Waiting from '../Waiting';

const App = observer(() => {
  useEffect(() => {
    if (!AuthStore.currentUserData.ready) {
      AuthStore.testCurrentLogged();
    }
  }, []);

  if (AuthStore.networkError) {
    return (
      <RouterProvider
        router={createBrowserRouter([
          {
            element: <AuthLayout />,
            children: [
              {
                path: '*',
                element: <NetworkError />,
              },
            ],
          },
        ])}
      />
    );
  }

  if (AuthStore.loggedTesting || AuthStore.loggedTesting === null) {
    return (
      <RouterProvider
        router={createBrowserRouter([
          {
            element: <Outlet />,
            children: [
              {
                path: '*',
                element: <CheckingAuth />,
              },
            ],
          },
        ])}
      />
    );
  }

  if (!AuthStore.currentUserData.ready) {
    return (
      <RouterProvider
        router={createBrowserRouter([
          {
            element: <AuthLayout />,
            children: [
              {
                path: '/success',
                element: <Success />,
              },
              {
                path: '/failure',
                element: <Failure />,
              },
              {
                path: '/waiting',
                element: <Waiting />,
              },
              {
                path: '/create',
                // element: <Create />,
                element: <CreateByEmail />,
              },
              {
                path: '/admin/create',
                // element: <Create />,
                element: <CreateByEmail />,
              },
              {
                path: '/login',
                element: <Login />,
              },
              {
                path: '/forgot',
                element: <Forgot />,
              },
              {
                path: '/new_password',
                element: <NewPass />,
              },
              {
                path: '/check',
                element: <Check />,
              },
              {
                path: '/register',
                element: <SignUp />,
              },
              {
                path: '/invite/:workspaceId/:email/:code',
                element: <Invite />,
              },
              {
                path: '/zakazbuketov',
                element: <ZakazBuketov />,
              },
              {
                path: '*',
                element: <Login />,
              },
            ],
          },
        ])}
      />
    );
  }

  const parts = [];

  parts.push({
    element: <CabinetLayout />,
    children: [
      {
        path: '/workspaces/:id',
        element: <Workspaces />,
      },
      {
        path: '/workspaces/:id/orderhistory',
        element: <History />,
      },
      {
        path: '/workspaces/:id/distribution_center',
        element: <Distribution />,
      },
      {
        path: '/workspaces/:id/zoning_management',
        element: <ManagingZoning />,
      },
      {
        path: '/workspaces/:id/order_management',
        element: <ManagingOrder />,
      },

      {
        path: '/workspaces/:id/search',
        element: <Search />,
      },
      {
        path: '/workspaces/:id/members',
        element: <Members />,
      },
      {
        path: '/workspaces/:id/profile',
        element: <Profile />,
      },
      {
        path: '/leaderboard',
        element: <Leaderboard />,
      },
      {
        path: '/product_accounts',
        element: <ProductAccounts />,
      },
      {
        path: '/login',
        element: <Navigate to="/" />, // Редир
      },
      {
        path: '/zakazbuketov',
        element: <Navigate to="/workspaces/66e170b52fc0a03d58789c24/user" />, // Редир
      },
      {
        path: '/forgot',
        element: <Navigate to="/" />, // Редир
      },
      {
        path: '/invite/:workspaceId/:email/:code',
        element: <Invite />,
      },
      {
        path: '/invite_success/:workspaceId/',
        element: <InviteSuccess />,
      },
    ],
  });

  parts.push({
    element: <CabinetLayout2 />,
    children: [
      {
        path: '/',
        element: <Main />,
      },
    ],
  });
  parts.push({
    element: <MobileLayout />,
    children: [
      {
        path: '/workspaces/:id/user',
        // element: <User />,
        element: <MemberApp />,
      },
    ],
  });

  parts.push({
    element: <AdminLayout />,
    children: [
      {
        path: '/create',
        element: <Navigate to="/" />, // Редир
      },
    ],
  });

  /* if (AuthStore.currentUserData.user.isSuperAdmin) {*/
  parts.push({
    element: <AdminLayout />,
    children: [
      {
        path: '/admin',
        element: <Users />,
      },
      {
        path: '/admin/users',
        element: <Users />,
      },
      {
        path: '/admin/users/add',
        // element: <Create />,
        element: <CreateByEmail />,
      },
      {
        path: '/admin/product_parameters',
        element: <ProductParameters />,
      },
      {
        path: '/admin/product_list',
        element: <ProductList />,
      },
      {
        path: '/admin/orders_list',
        element: <OrdersList />,
      },
      {
        path: '/admin/orders_parameters',
        element: <OrdersParameters />,
      },
      {
        path: '/admin/users_parameters',
        element: <UsersParameters />,
      },
      {
        path: '/admin/iikoIntegration',
        element: <IikoIntegration />,
      },
      {
        path: '/admin/cities',
        element: <Cities />,
      },
      {
        path: '/admin/cities_parameters',
        element: <CitiesParameters />,
      },
      {
        path: '/admin/organizations',
        element: <Organizations />,
      },
      {
        path: '/admin/organizations_parameters',
        element: <OrganizationsParameters />,
      },
      {
        path: '/admin/groups',
        element: <Groups />,
      },
      {
        path: '/admin/groups_parameters',
        element: <GroupsParameters />,
      },
      {
        path: '/admin/categories',
        element: <Categories />,
      },
      {
        path: '/admin/categories_parameters',
        element: <CategoriesParameters />,
      },
      {
        path: '/admin/products',
        element: <Products />,
      },
      {
        path: '/admin/products_parameters',
        element: <ProductsParameters />,
      },
      {
        path: '/admin/modifiers',
        element: <Modifiers />,
      },
      {
        path: '/admin/modifiers_parameters',
        element: <ModifiersParameters />,
      },
      {
        path: '/admin/orders',
        element: <Orders />,
      },
      {
        path: '/admin/orders_parametersV2',
        element: <OrdersParametersV2 />,
      },
    ],
  });
  /* }*/
  const router = createBrowserRouter(parts);
  return (
    <ManagerStoreContext.Provider value={{ managersStore: ManagerStore }}>
      <RouterProvider router={router} />{' '}
    </ManagerStoreContext.Provider>
  );
});
export default App;
