import styles from './index.module.scss';
import { Collapse, CollapseProps, Dropdown, MenuProps } from 'antd';
import React, { FC, Fragment, ReactNode } from 'react';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import Clickable from '../../../../ui/Clickable';
import { DownOutlined } from '@ant-design/icons';

interface IProps {
  expandIcon: (panelProps: any) => ReactNode;
  assignedTo?: string;
}

const MemberTasksBlock: FC<IProps> = ({ expandIcon, assignedTo }) => {
  const memberTasksCollapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'ЗАДАЧИ ПОЛЬЗОВАТЕЛЕЙ',
      children: StagesStore.dataUserTask.map((task) => {
        const membersDropdownMenu: MenuProps['items'] = Object.values(WorkspacesStore.members).map(
          (member) => ({
            key: member._id,
            label: <div>{member.userId.firstname}</div>,
          })
        );

        return (
          <Fragment key={task._id}>
            {/*<Button*/}
            {/*  variant={task.status == 'in_progress' ? 'primary' : 'secondary'}*/}
            {/*  onClick={() => onTask(task._id, task.status)}*/}
            {/*  key={task._id}*/}
            {/*  loading={StagesStore.loadingJob}*/}
            {/*>*/}
            {/*  <div>*/}
            {/*    <p>taskId: {task._id.slice(-4)}</p>*/}
            {/*  </div>*/}
            {/*</Button>*/}
            <div className={styles.fieldsWrap}>
              <div className={styles.field}>
                <p className={styles.name}>{task.title}</p>
                <Dropdown trigger={['click']} disabled>
                  <Clickable className={styles.dropdownBtn}>
                    {task.status}
                    <DownOutlined color="#2A3038" />
                  </Clickable>
                </Dropdown>
              </div>
              <div className={styles.field}>
                <p className={styles.name}>Ответственный</p>
                <Dropdown menu={{ items: membersDropdownMenu }} trigger={['click']}>
                  <Clickable className={styles.dropdownBtn}>
                    {task.assignedTo && assignedTo
                      ? WorkspacesStore.members[assignedTo]?.userId?.firstname
                      : 'Ответственный'}
                    <DownOutlined color="#2A3038" />
                  </Clickable>
                </Dropdown>
              </div>
            </div>
          </Fragment>
        );
      }),
    },
  ];

  return (
    <Collapse
      items={memberTasksCollapseItems}
      expandIcon={expandIcon}
      expandIconPosition="right"
      bordered={false}
      ghost
    />
  );
};

export default MemberTasksBlock;
