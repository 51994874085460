// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AjumXLNE9zUyNNbBdkFd{width:30%;border:1px solid #e2e2e1;border-radius:20px;padding:24px 0px}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Kanban/ManagingOrder/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,wBAAA,CACA,kBAAA,CACA,gBAAA","sourcesContent":[".new_orders {\n  width: 30%;\n  border: 1px solid #e2e2e1;\n  border-radius: 20px;\n  padding: 24px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new_orders": `AjumXLNE9zUyNNbBdkFd`
};
export default ___CSS_LOADER_EXPORT___;
