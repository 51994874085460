import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  CpFieldType,
  DataType,
  DataTypeMap,
  LoadingMap,
} from '../../../components/Admin/ProductParameters';
import { TabsProps } from 'antd';
import { basicRequestPost } from '../../Requests/basicRequestPost';
import { basicRequestGet } from '../../Requests/basicRequestGet';
import { basicRequestDelete } from '../../Requests/basicRequestDelete';
import { UniqueIdentifier } from '@dnd-kit/core';
import { RenameParamTypeName } from '../../../components/Admin/UsersParameters/InputTab';
import { basicRequestPatch } from '../../Requests/basicRequestPatch';

export abstract class BaseParamsClass {
  paramTypeId: string = 'first';
  _loadingList: LoadingMap = {};
  loadingParamError: LoadingMap = {};
  loadingParamTypesList = false;
  _dataList: DataTypeMap = { type: [] as DataType[] };
  paramTypesList: TabsProps['items'] = [];
  paramData: DataType | null = null;

  visibInput: boolean = false;
  addingTab: boolean = false;
  renameTab: boolean = false;
  removeTab: boolean = false;

  slug: string = '';
  protected constructor(slug: string, paramTypeId?: string) {
    this.slug = slug;
    if (paramTypeId) this.paramTypeId = paramTypeId;
    // makeObservable(this);
    makeObservable(this, {
      paramTypeId: observable,
      _loadingList: observable,
      loadingParamError: observable,
      loadingParamTypesList: observable,
      _dataList: observable,
      paramTypesList: observable,
      paramData: observable,
      visibInput: observable,
      addingTab: observable,
      renameTab: observable,
      removeTab: observable,
      slug: observable,
      addType: action,
      editType: action,
      getParametersList: action,
      deleteParam: action,
      changeSort: action,
      getParameterTypesList: action,
      renameParamTypeName: action,
      addProductParam: action,
      deleteProductParam: action,
    });
  }

  /**
   *
   */
  async addType(data: CpFieldType) {
    data.param_type_id = this.paramTypeId;
    await basicRequestPost(`/admin/${this.slug}/add_type`, data);
  }

  /**
   *
   */
  async editType(data: CpFieldType) {
    data.param_type_id = this.paramTypeId;
    await basicRequestPost(`/admin/${this.slug}/edit_type`, data);
  }

  /**
   *
   */
  async getParametersList(param_type_id?: string) {
    // console.log('---- ww|getParametersList|param_type_id=', param_type_id);
    if (param_type_id) {
      this.paramTypeId = param_type_id;
    } else {
      param_type_id = this.paramTypeId;
    }
    if (this._loadingList[param_type_id]) {
      return;
    }
    runInAction(() => {
      this._loadingList[param_type_id] = true;
    });

    try {
      const res = await basicRequestGet(`/admin/${this.slug}/list/${param_type_id}`);
      // return {
      //   data: res.data.data.data.map((i: any) => {
      //     i.key = i._id;
      //     i.tags = [];
      //     return i;
      //   }) as DataType[],
      //   pagination: res.data.data.pagination,
      // };
      // console.log('---- ww|getParametersList|basicRequestPost|param_type_id=', param_type_id);
      runInAction(() => {
        delete this.loadingParamError[param_type_id];
        this._dataList[param_type_id] = res.data.data.docs.map((i: any) => {
          i.key = i._id;
          i.tags = [];
          return i;
        }) as DataType[];
        // console.log(
        //   '---- ww|getParametersList|basicRequestPost|param_type_id=',
        //   param_type_id,
        //   this._dataList[param_type_id]
        // );
      });
    } catch (error) {
      runInAction(() => {
        this.loadingParamError[param_type_id] = true;
      });
      throw error;
    } finally {
      runInAction(() => {
        this._loadingList[param_type_id] = false;
      });
    }
  }

  get loadingList() {
    return this._loadingList[this.paramTypeId];
  }

  set loadingList(val) {
    console.log('---- ww|loadingList|val=', val, this.paramTypeId);
    runInAction(() => {
      this._loadingList[this.paramTypeId] = val;
    });
  }

  get dataList(): DataType[] {
    // console.log(
    //   '---- ww|loadingList|get|this.paramTypeId=',
    //   this.paramTypeId,
    //   this._dataList[this.paramTypeId]
    // );
    return this._dataList[this.paramTypeId] || [];
  }

  set dataList(val) {
    this._dataList[this.paramTypeId] = val;
  }

  /**
   *
   */
  async deleteParam(_id: string) {
    const index = this.dataList.findIndex((obj) => obj._id === _id);
    console.log('deleteParam|index=', index);
    if (index === -1) {
      console.error('deleteParam|index !== -1');
      throw 'Remove error';
    }
    await basicRequestDelete(`/admin/${this.slug}/delete/${_id}`);
    this.dataList = this.dataList.filter((obj) => obj._id !== _id);
  }

  /**
   *
   */
  async changeSort(
    active_id: UniqueIdentifier,
    over_id: UniqueIdentifier | null,
    param_type_id?: string
  ) {
    console.log('---- ww|changeSort');
    if (param_type_id) {
      this.paramTypeId = param_type_id;
    } else {
      param_type_id = this.paramTypeId;
    }
    if (this._loadingList[param_type_id]) {
      return;
    }
    runInAction(() => {
      this._loadingList[param_type_id] = true;
    });

    // this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPost(`/admin/${this.slug}/change_params_sort`, {
        active_id,
        over_id,
      });
    } catch (error) {
      console.error('changeSort|error=', error);
      throw error;
    } finally {
      runInAction(() => {
        this._loadingList[param_type_id] = false;
      });
    }
  }

  /**
   *
   */
  async getParameterTypesList() {
    // console.log('---- ww|getParameterTypesList|param_type_id=', param_type_id);

    if (this.loadingParamTypesList) {
      return;
    }
    runInAction(() => {
      this.loadingParamTypesList = true;
    });

    try {
      const res = await basicRequestGet(`/admin/${this.slug}/param_types`);
      // return {
      //   data: res.data.data.data.map((i: any) => {
      //     i.key = i._id;
      //     i.tags = [];
      //     return i;
      //   }) as DataType[],
      //   pagination: res.data.data.pagination,
      // };
      // console.log('---- ww|getParameterTypesList|basicRequestGet|res.data=', res.data);
      runInAction(() => {
        this.paramTypesList = res.data.data.docs.map((i: any) => {
          i.key = i._id;
          i.label = i.param_name;
          return i;
        }) as TabsProps['items'];
        console.log(
          '---- ww|getParameterTypesList|basicRequestGet|this.paramTypesList=',
          this.paramTypesList
        );
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingParamTypesList = false;
      });
    }
  }

  /**
   *
   */
  async renameParamTypeName({ param_type_id, param_name }: RenameParamTypeName): Promise<string> {
    console.log('---- ww|renameParamTypeName');

    runInAction(() => {
      this.renameTab = true;
    });

    // this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPatch(`/admin/${this.slug}/rename_parameter_type_name`, {
        param_type_id,
        param_name,
      });
      console.log('---- ww|renameParamTypeName|res=', res.data.data);
      return res.data.data as string;
    } catch (error) {
      console.error('renameParamTypeName|error=', error);
      throw error;
    } finally {
      runInAction(() => {
        this.renameTab = false;
      });
    }
  }

  /**
   *
   */
  async addProductParam(): Promise<string> {
    console.log('---- ww|addProductParam');

    runInAction(() => {
      this.addingTab = true;
    });

    // this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPost(`/admin/${this.slug}/add_param_type`, {});
      console.log('---- ww|addProductParam|res=', res.data.data);
      return res.data.data as string;
    } catch (error) {
      console.error('addProductParam|error=', error);
      throw error;
    } finally {
      runInAction(() => {
        this.addingTab = false;
      });
    }
  }

  /**
   *
   */
  async deleteProductParam(productId: string) {
    console.log('---- ww|deleteProductParam|productId=', productId);

    runInAction(() => {
      this.removeTab = true;
    });

    // this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestDelete(`/admin/${this.slug}/delete_param_type/${productId}`);
    } catch (error) {
      console.error('deleteProductParam|error=', error);
      throw error;
    } finally {
      runInAction(() => {
        this.removeTab = false;
      });
    }
  }
}
