import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthStore } from '../../../DataSource/Stores/AuthStore';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import { config } from '../../../config';
import uWS from '../../../uWS';
import Profile from '../../Profile';
import Tabs, { TabData } from '../../ui/Tabs';
import JobTab from '../NewJobsBlock/JobTab';
import TaskTab from '../NewJobsBlock/TaskTab';
import StatusProf from '../StatusProf';
import styles from './index.module.scss';
import { WorkspacesStore } from '../../../DataSource/Stores/Cabinet/WorkspacesStore';

const Mobile: React.FC = () => {
  const navigate = useNavigate();
  const logoutModal = () => {
    console.log('User logged out');
    AuthStore.logout();
    navigate('/zakazbuketov');
  };
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };

  // const workspaceId = '66e170b52fc0a03d58789c24';
  const workspaceId = config.WORKSPACE_ID;

  //polucheniye ocheredi

  useEffect(() => {
    // StagesStore.getListJobsQueue(workspaceId, '66e194841725b77d117c4d57').catch((e) => {
    StagesStore.getListJobsQueue(workspaceId, config.CEH_STAGE).catch((e) => {
      console.error(e);
    });
  }, [workspaceId]);

  //polucheniye taskov

  useEffect(() => {
    StagesStore.getMyTasks(workspaceId).catch((e) => {
      console.error(e);
    });
    // console.log('useeffect get my task');
  }, []);

  //polucheniye stage

  useEffect(() => {
    if (workspaceId) {
      StagesStore.getList(workspaceId).catch((e) => {
        console.error(e);
      });
      // console.log('useeffect get my job');
    }
  }, [workspaceId]);

  useEffect(() => {
    if (!workspaceId) return;
    const eventStatus = uWS.subscribe(
      `workspaces/${workspaceId}/changeMyTasks/${AuthStore.currentUserData.user._id}`,
      (data: any) => {
        if (data.error) {
          console.error('Error subscribing to workspace changeMyTasks:', data.error);
          return;
        }

        console.log('changeMyTasks:', data);
        StagesStore.getMyTasks(workspaceId).catch((e) => {
          console.error(e);
        });

        if (
          data.taskId &&
          StagesStore?.dataMyTaskOpened?._id &&
          data.taskId === StagesStore?.dataMyTaskOpened?._id
        ) {
          StagesStore.getMyTaskOpen(workspaceId, StagesStore?.dataMyTaskOpened?._id).catch((e) => {
            console.error(e);
          });
        }

        if (
          (data.taskId &&
            StagesStore.selectedTask &&
            data.taskId === StagesStore.selectedTask._id) ||
          (!data.taskId &&
            data.jobId &&
            StagesStore.selectedTask &&
            StagesStore.selectedTask.jobId === data.jobId &&
            data.from !== 'updateExpectedDate')
        ) {
          StagesStore.setShowTextTask(false);
          StagesStore.setSelectedTask(null);
          StagesStore.setSelectedJob(null);
          StagesStore.setLayoutPage('library');
          StagesStore.setIsSelectedTask(false);
        }
      }
    );

    return () => {
      if (eventStatus)
        uWS.unSubscribe(
          `workspaces/${workspaceId}/changeMyTasks/${AuthStore.currentUserData.user._id}`
        );
    };
  }, [workspaceId]);

  useEffect(() => {
    if (!workspaceId) return;
    const eventStatus = uWS.subscribe(`workspaces/${workspaceId}/changeJob`, (data: any) => {
      if (data.error) {
        console.error('Error subscribing to workspace changeJob:', data.error);
        return;
      }

      console.log('changeJob:', data);
      StagesStore.getListJobsQueue(workspaceId, config.CEH_STAGE).catch((e) => {
        console.error(e);
      });

      if (data.jobId && StagesStore.selectedJob && data.jobId === StagesStore.selectedJob._id) {
        StagesStore.setShowText(false);
        StagesStore.setLayoutPageJob('joblay');
        StagesStore.setIsSelectedJob(false);
        StagesStore.setSelectedJob(null);
      }
    });

    return () => {
      if (eventStatus) uWS.unSubscribe(`workspaces/${workspaceId}/changeJob`);
    };
  }, []);

  const tabData: TabData[] = [
    {
      id: 1,
      icon: 'arr_right',
      count: <>{StagesStore.dataQueueList.length}</>,
      title: 'Новые',
      content: <JobTab workspaceId={workspaceId} />,
    },
    {
      id: 2,
      icon: 'cake',
      count: <>{StagesStore.dataMyTasks.length}</>,
      title: 'В работе',
      content: <TaskTab workspaceId={workspaceId} />,
    },
    {
      id: 3,
      icon: 'user',
      title: 'Профиль',
      content: (
        <>
          <Profile />
          <p onClick={showModal} className={styles.exit}>
            Выйти
          </p>
        </>
      ),
      className: 'just_img',
    },
    {
      id: 4,
      icon: 'wifi',
      title: 'Cтатус',
      content: <StatusProf />,
      className: 'just_img',
    },
  ];

  return (
    <div className={styles.distributionCenterPage}>
      <div className={styles.user_wrp}>
        <Tabs data={tabData} />
      </div>
      <Modal
        title=""
        open={open}
        closable={false}
        onOk={logoutModal}
        onCancel={hideModal}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to leave the site?</p>
      </Modal>
    </div>
  );
};

export default observer(Mobile);
