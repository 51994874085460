import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { $class } from '../../../utils';
import Clickable from '../../ui/Clickable';
import Icon from '../../ui/Icon';
import styles from './index.module.scss';

type WorkspaceIdParams = {
  id?: string;
};

const Nav = () => {
  const { id } = useParams<WorkspaceIdParams>();

  const buttons = [
    { icon: 'cake', link: `/workspaces/${id}` },
    { icon: 'list', link: `/workspaces/${id}/orderhistory` },
    { icon: 'search', link: `/workspaces/${id}/search` },
    { icon: 'users', link: `/workspaces/${id}/members` },
  ];
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.nav}>
      {buttons.map((button, i) => (
        <Clickable
          key={i}
          className={$class(styles.navBtn, [styles.active, location.pathname === button.link])}
          onClick={() => navigate(button.link)}
        >
          <Icon slug={button.icon} />
        </Clickable>
      ))}
    </div>
  );
};

export default Nav;
