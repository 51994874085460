import { observer } from 'mobx-react';
import React from 'react';
import Lang from '../../Lang';
import { Params } from '../../../DataSource/Stores/Admin/GroupsParams';
import BaseParameters from '../BaseParameters';

const GroupsParameters: React.FC = () => {
  return (
    <BaseParameters
      Params={Params}
      title={Lang({ str: 'groupsParameters', section: 'default' })}
    ></BaseParameters>
  );
};

export default observer(GroupsParameters);
