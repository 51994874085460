import { FormProps, message } from 'antd';
import { AutoComplete, Button, DatePicker, Form, Input, Modal, Radio } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ManagerStore } from '../../../../../../DataSource/Stores/ManagerStore';
import Icon from '../../../../../ui/Icon';
import StatusTitle from '../../../StatusTitle';
import styles from './index.module.scss';
import { useParams } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { WorkspacesStore } from '../../../../../../DataSource/Stores/Cabinet/WorkspacesStore';

type Contact = {
  name: string;
  phone: string;
};

type FieldType = {
  contactsFrom: Contact[];
  contactsTo: Contact[];
  // username?: string;
  // password?: string;
  // remember?: string;
  // monday?: string;

  from: string;
  to: string;
  datetime: Dayjs;
  instruction: string;
  paymentType: string;
  cost?: number;
};
type WorkspaceIdParams = {
  id?: string;
};

const { TextArea } = Input;

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

interface ModalAddProps {
  // forceUpdate: () => void;
}

const ModalAdd: React.FC<ModalAddProps> = ({}) => {
  const { id } = useParams<WorkspaceIdParams>();

  console.log(id);

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    if (!id) return;

    console.log('Success:', values);

    console.log(values.datetime.toDate());

    const jobData = {
      name: `Доставка на ${values.to}`,
      externalId: {},
      workspaceId: id,
      jobData: [
        {
          text: values.instruction,
          photoFiles: [],
        },
      ],
      targetDate: values.datetime.toDate(),
      deliveryData: {
        pickupPoint: {
          address: values.from,
          contactData: values.contactsFrom,
          // geoPoint: {
          //   latitude: '55.7558',
          //   longitude: '37.6173',
          // },
        },
        deliveryPoint: {
          address: values.to,
          contactData: values.contactsTo,
          // geoPoint: {
          //   latitude: '48.8566',
          //   longitude: '2.3522',
          // },
        },
        paymentData: {
          method: values.paymentType,
          amount: values.cost || 0,
        },
      },
    };

    try {
      await WorkspacesStore.addJobForDeliveryCenter(id, jobData);
      message.info('Success');
    } catch (err) {
      message.error('Error');
    }
  };

  const locationFrom = [
    { value: 'Burns Bay Road' },
    { value: 'Downing Street' },
    { value: 'Wall Street' },
  ];

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const handleOk = () => {
    ManagerStore.ModalOpenOrder = false;
  };
  const handleCancel = () => {
    ManagerStore.ModalOpenOrder = false;
  };

  return (
    <Modal
      title={'заказ'}
      open={ManagerStore.ModalOpenOrder}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <div className={styles.moda_cont}>
        <p className={styles.moda_cont__title}>новый Заказ</p>
        <StatusTitle title="создание" theme="create" />

        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          className={styles.orderModal}
          requiredMark={false}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className={styles.formLabel}>Откуда:</div>
          <Form.Item
            name="from"
            rules={[{ required: true, message: 'Укажите адрес' }]}
            style={{ marginBottom: 12 }}
          >
            <AutoComplete
              options={locationFrom}
              placeholder="Введите или выберите адрес.."
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>

          <div className={styles.formLabel}>Куда:</div>
          <Form.Item
            name="to"
            rules={[{ required: true, message: 'Укажите адрес' }]}
            style={{ marginBottom: 12 }}
          >
            <AutoComplete
              options={locationFrom}
              placeholder="Введите или выберите адрес.."
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>

          <div className={styles.formLabel}>Дата и время доставки:</div>
          <Form.Item
            name="datetime"
            rules={[{ required: true, message: 'Заполните поле' }]}
            style={{ marginBottom: 12 }}
          >
            <DatePicker
              placeholder="Выберите дату"
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>

          <div className={styles.formLabel}>Инструкция для курьера:</div>
          <Form.Item
            name="instruction"
            rules={[{ required: true, message: 'Заполните поле' }]}
            style={{ marginBottom: 12 }}
          >
            <TextArea placeholder="Введите описание..." autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>

          <div className={styles.formLabel}>Контакты в точке Забора:</div>
          <Form.List
            name="contactsFrom"
            rules={[
              {
                validator: async (_, contactsFrom) => {
                  if (!contactsFrom || contactsFrom.length === 0) {
                    return Promise.reject(new Error('You must add at least one contact!'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ display: 'flex', marginBottom: 8 }}>
                    <Form.Item
                      {...restField}
                      name={[name, 'phone']}
                      style={{ marginBottom: 0 }}
                      rules={[{ required: true, message: 'Phone is required' }]}
                    >
                      <Input placeholder="Телефон" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      style={{ marginLeft: 8, marginBottom: 0 }}
                      rules={[{ required: true, message: 'Name is required' }]}
                    >
                      <Input placeholder="Имя" />
                    </Form.Item>

                    <Button
                      icon={<Icon slug="minus" size={18} color="#959595" />}
                      style={{ width: 32, marginLeft: 8 }}
                      shape="default"
                      type="link"
                      onClick={() => remove(name)}
                    ></Button>
                  </div>
                ))}
                <Form.Item>
                  <Button type="default" onClick={() => add()} block>
                    + Добавить контакт
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <div className={styles.formLabel}>Контакты в точке Доставки:</div>
          <Form.List
            name="contactsTo"
            rules={[
              {
                validator: async (_, contactsTo) => {
                  if (!contactsTo || contactsTo.length === 0) {
                    return Promise.reject(new Error('You must add at least one contact!'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ display: 'flex', marginBottom: 8 }}>
                    <Form.Item
                      {...restField}
                      name={[name, 'phone']}
                      style={{ marginBottom: 0 }}
                      rules={[{ required: true, message: 'Phone is required' }]}
                    >
                      <Input placeholder="Phone" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      style={{ marginLeft: 8, marginBottom: 0 }}
                      rules={[{ required: true, message: 'Name is required' }]}
                    >
                      <Input placeholder="Имя" />
                    </Form.Item>

                    <Button
                      icon={<Icon slug="minus" size={18} color="#959595" />}
                      style={{ width: 32, marginLeft: 8 }}
                      shape="default"
                      type="link"
                      onClick={() => remove(name)}
                    ></Button>
                  </div>
                ))}
                <Form.Item>
                  <Button type="default" onClick={() => add()} block>
                    + Добавить контакт
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <div className={styles.formLabel}>Способ оплаты:</div>
          <Form.Item
            name="paymentType"
            style={{ marginBottom: 12 }}
            rules={[{ required: true, message: 'Пожалуйста, выберите тип оплаты' }]}
          >
            <Radio.Group>
              <Radio value={'cash'}>Наличные</Radio>
              <Radio value={'online'}>Онлайн</Radio>
            </Radio.Group>
          </Form.Item>

          <div className={styles.formLabel}>Сумма, ₸</div>
          <Form.Item name="cost" style={{ marginBottom: 12 }}>
            <Input prefix="₸" placeholder="Введите сумму..." />
          </Form.Item>

          {/* <div className={styles.one_block}>
            <p className={styles.one_tit}>Параметры заказа</p>
            <div className={styles.flex_input}>
              <Form.Item<FieldType>
                label="Курьер"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Select
                  defaultValue="Выберите"
                  onChange={handleChange}
                  options={[
                    { value: 'Активен', label: 'Активен' },
                    { value: 'Не активен', label: 'Не активен' },
                  ]}
                />
              </Form.Item>
              <Form.Item<FieldType>
                label="Компания"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Select
                  defaultValue="Выберите"
                  onChange={handleChange}
                  options={[
                    { value: 'Активен', label: 'Активен' },
                    { value: 'Не активен', label: 'Не активен' },
                  ]}
                />
              </Form.Item>
            </div>
            <div className={styles.flex_input}>
              <Form.Item<FieldType>
                label="Откуда"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Select
                  defaultValue="Выберите"
                  onChange={handleChange}
                  options={[
                    { value: 'Активен', label: 'Активен' },
                    { value: 'Не активен', label: 'Не активен' },
                  ]}
                />
              </Form.Item>
              <Form.Item<FieldType>
                label="Куда"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Select
                  defaultValue="Выберите"
                  onChange={handleChange}
                  options={[
                    { value: 'Активен', label: 'Активен' },
                    { value: 'Не активен', label: 'Не активен' },
                  ]}
                />
              </Form.Item>
            </div>
          </div>

          <div className={styles.one_block}>
            <Form.Item<FieldType>
              label="комментарий"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <TextArea rows={2} placeholder="Заполните" />
            </Form.Item>
            <Form.Item<FieldType>
              label="Содержание"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <TextArea rows={2} placeholder="Заполните" />
            </Form.Item>
          </div> */}

          <Button style={{ width: '100%' }} type="primary" htmlType="submit">
            Добавить
          </Button>
        </Form>
      </div>
    </Modal>
  );
};
export default observer(ModalAdd);
