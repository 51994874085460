import { Alert, Button, message, Table, TableProps, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import usePageTitle from '../../DataSource/Hooks/usePageTitle';
import { OrderStore } from '../../DataSource/Stores/Site/OrdersStore';
import Lang from '../Lang/index';
import styles from './ProductAccounts.module.scss';
import { toLocalDate } from '../../DataSource/Utils/date';
import Operations from './Table/Operations';

export interface ProductDataType {
  key: number;
  _id: string;
  createdAt: string;
  user_name_email: string;
  product_param_name: string;
  order_status: string;
  payment_status: string;
  product_name: string;
  product_param_id: string;
  product_id: string;
}
interface StatusColorMap {
  [key: string]: string;
}

const handleDelete = (key: React.Key) => {
  console.log('---- handleDelete|key=', key);
  message.loading('Loading');
  OrderStore.deleteProduct(key as string)
    .then(() => {
      message.destroy();
      message.info('Success');
    })
    .catch((error) => {
      message.destroy();
      if (error?.message == 'Request failed with status code 409') {
        return message.error('Product in use');
      }
      message.error('Error');
    });
};

const columns: TableProps<ProductDataType>['columns'] = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Date time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text) => <a>{toLocalDate(text)}</a>,
  },
  {
    title: 'Users',
    dataIndex: 'user_name_email',
    key: 'user_name_email',
  },
  {
    title: 'Product Type',
    dataIndex: 'product_param_name',
    key: 'product_param_name',
    render: (text) => <a>{text}</a>,
  },

  {
    title: 'Order status',
    key: 'order_status',
    dataIndex: 'order_status',
    render: (_, { order_status }) => {
      const color: StatusColorMap = {
        verified: 'green',
        block: 'volcano',
      };
      return <Tag color={color[order_status]}>{order_status.toUpperCase()}</Tag>;
    },
  },
  {
    title: 'Payment status',
    key: 'payment_status',
    dataIndex: 'payment_status',
    render: (_, { payment_status }) => {
      const color: StatusColorMap = {
        verified: 'green',
        block: 'volcano',
      };
      return <Tag color={color[payment_status]}>{payment_status?.toUpperCase()}</Tag>;
    },
  },
  {
    title: 'Amount',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: '',
    dataIndex: 'operation',
    render: (_, params) => <Operations {...params} />,
  },
  {
    /*
		title: 'Delete',
		dataIndex: 'operation',
		render: (_, record) => (
			<Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record._id)}>
				<a>{Lang({ str: 'delete', section: 'default' })}</a>
			</Popconfirm>
		),*/
  },
];

const OrdersList: React.FC = () => {
  usePageTitle(Lang({ str: 'mychall', section: 'default' }));

  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    !init && getList();

    setInit(true);
  }, []);

  const getList = () => {
    console.log('---- ww|getList|getParamType');
    OrderStore.getList();
  };

  return (
    <div className="p-20 pt-80 w-100">
      <h1>{Lang({ str: 'mychall', section: 'default' })}</h1>
      <div className={styles.table + ' mt-32'}>
        {OrderStore.loadingError && (
          <Alert
            message="Error loading data from the server. Please try again later."
            type="error"
            showIcon
            action={
              <Button size="small" onClick={getList}>
                Try Again
              </Button>
            }
          />
        )}
        {/* <Button onClick={showModal}>+</Button>*/}
        <Table
          scroll={{ x: 1000 }}
          columns={columns}
          dataSource={OrderStore.dataList}
          loading={OrderStore.loading}
        />
      </div>
    </div>
  );
};

export default observer(OrdersList);
