// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b0BkLJX9BqcYuJ1yDF4j{width:300px;padding:16px;border-radius:16px;background-color:#fff}.b0BkLJX9BqcYuJ1yDF4j>*+*{margin-top:12px}`, "",{"version":3,"sources":["webpack://./src/modals/WorkspaceCreate/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,qBAAA,CAEA,0BACE,eAAA","sourcesContent":[".wrap {\n  width: 300px;\n  padding: 16px;\n  border-radius: 16px;\n  background-color: white;\n\n  & > * + * {\n    margin-top: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `b0BkLJX9BqcYuJ1yDF4j`
};
export default ___CSS_LOADER_EXPORT___;
