import { Button, Input, message, Space } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import saveicon from '../../../assets/save.svg';
import { ProductParams } from '../../../DataSource/Stores/Admin/ProductParams';
import styles from '../Admin.module.scss';

interface Props {
  name?: any;
  param_type_id: string;
}

export interface RenameParamTypeName {
  param_name: any;
  param_type_id: string;
}

const InputTab: React.FC<Props> = ({ name, param_type_id }) => {
  const [visibInput, setVisibInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(name || 'New Tabs');
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setVisibInput(ProductParams.visibInput);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setVisibInput(false);
        handleSubmit();
      }
    };

    if (visibInput) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visibInput, inputValue]);

  const handleSubmit = async () => {
    console.log('---- ww|handleSubmit|=', inputValue);
    setLoading(true);

    message.loading('Loading');
    try {
      await ProductParams.renameParamTypeName({
        param_name: inputValue,
        param_type_id,
      });
    } catch (error) {
      console.error('---- handleSubmit|error=', error);
      message.destroy();
      message.error('Error');
      return;
    } finally {
      setVisibInput(false);
      setLoading(false);
    }

    message.destroy();
    message.info('Success');
  };

  return (
    <div className={visibInput ? 'no_close' : ''} ref={inputRef}>
      {visibInput ? (
        <Space className={styles.but_tab} direction="vertical">
          <Space.Compact>
            <Input
              value={inputValue}
              disabled={loading}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <Button className={styles.icon_ok} onClick={handleSubmit} loading={loading}>
              <img src={saveicon} />
            </Button>
          </Space.Compact>
        </Space>
      ) : (
        <span
          onDoubleClick={() => {
            setVisibInput(true);
          }}
        >
          {inputValue}
        </span>
      )}
    </div>
  );
};

export default observer(InputTab);
