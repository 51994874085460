import { Table, TableProps } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import usePageTitle from '../../DataSource/Hooks/usePageTitle';
import { LeaderboardStore } from '../../DataSource/Stores/Site/LeaderboardStore';
import styles from '../Admin/Admin.module.scss';
import Lang from '../Lang/index';

export interface DataType {
  key: string;
  createdAt: string;
  email: string;
}

interface Pagination {
  total: number;
  next: number | null;
  prev: number | null;
}

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Key',
    dataIndex: 'number',
    key: 'key',
  },

  {
    title: 'Registration date',
    dataIndex: 'dformat',
    key: 'createdAt',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },

  {
    title: 'Days in system',
    dataIndex: 'date',
    key: 'dformat',
  },
];

const Leaderboard: React.FC = () => {
  usePageTitle(Lang({ str: 'leaderboard', section: 'default' }));
  const [loading, setLoading] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    LeaderboardStore.getLeaderBoard()
      .catch(() => setLoadError(true))
      .finally(() => setLoading(false));
  }, []);

  if (loadError) {
    return (
      <div className="p-20 pt-80 w-100">
        <h1>Leaderboard</h1>
        <div className={styles.table + ' mt-32'}>Error...</div>
      </div>
    );
  }

  return (
    <div className="p-20 pt-80 w-100">
      <h1>{Lang({ str: 'leaderboard', section: 'default' })}</h1>
      <div className={styles.table + ' mt-32'}>
        <Table
          scroll={{ x: 1000 }}
          columns={columns}
          dataSource={LeaderboardStore.dataList}
          pagination={{
            pageSize: 10, // Размер страницы
            total: LeaderboardStore.dataList.length, // Общее количество элементов (если известно заранее)
            // Можно также добавить другие настройки пагинации, например, current, pageSizeOptions и т.д.
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default observer(Leaderboard);
