// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aUL09tKRS3hC66idEcXF{display:flex;flex-direction:column;gap:32px;padding:0}.Qd2NCzcE0r9wp63LIScN{display:flex;align-items:center;gap:48px}.Qd2NCzcE0r9wp63LIScN .tzbYHLxSNLr0SdbSkfht{font-size:32px;font-weight:600;color:#000;font-family:"Mulish",sans-serif}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,SAAA,CACA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,4CACE,cAAA,CACA,eAAA,CACA,UAAA,CACA,+BAAA","sourcesContent":[".distributionCenterPage {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  padding: 0;\n  &Top {\n    display: flex;\n    align-items: center;\n    gap: 48px;\n    .title {\n      font-size: 32px;\n      font-weight: 600;\n      color: #000;\n      font-family: 'Mulish', sans-serif;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"distributionCenterPage": `aUL09tKRS3hC66idEcXF`,
	"distributionCenterPageTop": `Qd2NCzcE0r9wp63LIScN`,
	"title": `tzbYHLxSNLr0SdbSkfht`
};
export default ___CSS_LOADER_EXPORT___;
