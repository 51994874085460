// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EP4oE_zzVVyv0r8eGyEh{height:100svh;width:100%;display:flex;flex-direction:column;gap:32px;padding:40px 24px;padding-top:24px;overflow-y:auto}.EP4oE_zzVVyv0r8eGyEh::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./src/layouts/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,iBAAA,CACA,gBAAA,CACA,eAAA,CACA,yCACE,YAAA","sourcesContent":[".cabinetLayout {\n  height: 100svh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n\n  padding: 40px 24px;\n  padding-top: 24px;\n  overflow-y: auto;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cabinetLayout": `EP4oE_zzVVyv0r8eGyEh`
};
export default ___CSS_LOADER_EXPORT___;
