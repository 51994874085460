// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XBBTKGP47CcUcNf0o8k2{margin-bottom:24px;font-size:20px;display:flex;align-items:center;justify-content:space-between}.XBBTKGP47CcUcNf0o8k2 span{display:block}.H0tSTMlrimkjtZobu3cQ{width:100%}.P6trNAfFQxnkU2o9TR4t button{font-size:14px;margin-right:6px}`, "",{"version":3,"sources":["webpack://./src/components/ProductAccounts/ProductAccounts.module.scss"],"names":[],"mappings":"AAIA,sBACE,kBAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,2BACE,aAAA,CAGJ,sBACE,UAAA,CAGA,6BACE,cAAA,CACA,gBAAA","sourcesContent":["@import '../../scss/variables';\n\n@import '../../scss/mixins';\n\n.title {\n  margin-bottom: 24px;\n  font-size: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  span {\n    display: block;\n  }\n}\n.button {\n  width: 100%;\n}\n.but_group {\n  button {\n    font-size: 14px;\n    margin-right: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `XBBTKGP47CcUcNf0o8k2`,
	"button": `H0tSTMlrimkjtZobu3cQ`,
	"but_group": `P6trNAfFQxnkU2o9TR4t`
};
export default ___CSS_LOADER_EXPORT___;
