// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._ifKr3EpvkhxoOsEn3Nj{display:flex;flex-direction:column;align-items:center;justify-content:center}.PQjrM7auf3_v944P3Cmj{position:relative;align-items:center;display:flex}.PQjrM7auf3_v944P3Cmj p{font-weight:400;font-size:15px;margin:0 0 0 8px;color:#000}.nBsqFlVbhccFpOPwQwFS{position:absolute;top:0;left:0;width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.nBsqFlVbhccFpOPwQwFS img{width:36px;height:36px;border-radius:50%}.ztPHhNYO3_lRorK004GY img{width:26px !important;height:26px !important}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Kanban/CourierItemProgressDrag/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,wBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CAIJ,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,0BACE,UAAA,CACA,WAAA,CACA,iBAAA,CAIF,0BACE,qBAAA,CACA,sBAAA","sourcesContent":[".bl_progress_course {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.progress_container {\n  position: relative;\n  align-items: center;\n  display: flex;\n  p {\n    font-weight: 400;\n    font-size: 15px;\n    margin: 0 0 0 8px;\n    color: #000;\n  }\n}\n\n.progress_content {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  img {\n    width: 36px;\n    height: 36px;\n    border-radius: 50%;\n  }\n}\n.size_32 {\n  img {\n    width: 26px !important;\n    height: 26px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bl_progress_course": `_ifKr3EpvkhxoOsEn3Nj`,
	"progress_container": `PQjrM7auf3_v944P3Cmj`,
	"progress_content": `nBsqFlVbhccFpOPwQwFS`,
	"size_32": `ztPHhNYO3_lRorK004GY`
};
export default ___CSS_LOADER_EXPORT___;
