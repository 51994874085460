import { Alert, Button, Form, Input } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ProductListStore } from '../../../../DataSource/Stores/Admin/ProductListStore';
import { ProductParamsClass } from '../../../../DataSource/Stores/Admin/ProductParamsClass';

type FieldType = {
  product_name: string;
  status_param_id: string;
  type_param_id: string;
  price_param_id: string;
};

const Forms: React.FC = () => {
  const [productParamsClasses, setProductParamsClasses] = useState<ProductParamsClass[]>([]);
  const [ProductParamsStatus] = useState<ProductParamsClass>(new ProductParamsClass('status'));
  const [ProductParamsPrice] = useState<ProductParamsClass>(new ProductParamsClass('price'));
  const [ProductParamsType] = useState<ProductParamsClass>(new ProductParamsClass('type'));
  const [loadErrorStatus, setLoadErrorStatus] = useState<boolean>(false);
  const [loadErrorType, setLoadErrorType] = useState<boolean>(false);
  const [loadErrorPrice, setLoadErrorPrice] = useState<boolean>(false);

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const getList = () => {
    setLoadErrorStatus(false);
    setLoadErrorType(false);
    setLoadErrorPrice(false);

    // for (const paramType of ProductParams.paramTypesList || []) {
    //   productParamsClasses.push(new ProductParamsClass(paramType.key));
    //   setProductParamsClasses(productParamsClasses);
    // }
    //
    // console.log('---- ww|productParamsClasses.length=', productParamsClasses.length);

    // ProductParamsStatus.getParametersList().catch(() => setLoadErrorStatus(true));
    // ProductParamsType.getParametersList().catch(() => setLoadErrorType(true));
    // ProductParamsPrice.getParametersList().catch(() => setLoadErrorPrice(true));
  };

  useEffect(() => {
    getList();
  }, []);

  if (loadErrorStatus || loadErrorType || loadErrorPrice) {
    return (
      <Alert
        message="Error loading data from the server. Please try again later."
        type="error"
        showIcon
        action={
          <Button size="small" onClick={getList}>
            Try Again
          </Button>
        }
      />
    );
  }

  return (
    <>
      <Form.Item<FieldType>
        label="Product name"
        name="product_name"
        rules={[{ required: true, message: 'Please input your product_name!' }]}
      >
        <Input disabled={ProductListStore.globalLoading} />
      </Form.Item>
      {/*<Form.Item<FieldType>*/}
      {/*  label="Status"*/}
      {/*  name="status_param_id"*/}
      {/*  rules={[{ required: true, message: 'Please input your Status!' }]}*/}
      {/*>*/}
      {/*  <Select*/}
      {/*    // defaultValue="text"*/}
      {/*    onChange={handleChange}*/}
      {/*    options={*/}
      {/*      ProductParamsStatus.dataList.map((i: DataType) => ({*/}
      {/*        value: i._id,*/}
      {/*        label: i.field_name,*/}
      {/*      })) as []*/}
      {/*    }*/}
      {/*    loading={ProductParamsStatus.loadingList}*/}
      {/*    disabled={ProductListStore.globalLoading}*/}
      {/*  />*/}
      {/*</Form.Item>*/}
      {/*<Form.Item<FieldType>*/}
      {/*  label="Product Type"*/}
      {/*  name="type_param_id"*/}
      {/*  rules={[{ required: true, message: 'Please input your challenge type!' }]}*/}
      {/*>*/}
      {/*  <Select*/}
      {/*    // defaultValue="text"*/}
      {/*    onChange={handleChange}*/}
      {/*    options={*/}
      {/*      ProductParamsType.dataList.map((i: DataType) => ({*/}
      {/*        value: i._id,*/}
      {/*        label: i.field_name,*/}
      {/*      })) as []*/}
      {/*    }*/}
      {/*    loading={ProductParamsType.loadingList}*/}
      {/*    disabled={ProductListStore.globalLoading}*/}
      {/*  />*/}
      {/*</Form.Item>*/}
      {/*<Form.Item<FieldType>*/}
      {/*  label="Price"*/}
      {/*  name="price_param_id"*/}
      {/*  rules={[{ required: true, message: 'Please input your price!' }]}*/}
      {/*>*/}
      {/*  <Select*/}
      {/*    // defaultValue="text"*/}
      {/*    onChange={handleChange}*/}
      {/*    options={*/}
      {/*      ProductParamsPrice.dataList.map((i: DataType) => ({*/}
      {/*        value: i._id,*/}
      {/*        label: i.field_name,*/}
      {/*      })) as []*/}
      {/*    }*/}
      {/*    loading={ProductParamsType.loadingList}*/}
      {/*    disabled={ProductListStore.globalLoading}*/}
      {/*  />*/}
      {/*</Form.Item>*/}
    </>
  );
};

export default observer(Forms);
