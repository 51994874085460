import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, Typography, message, type FormProps } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthStore } from '../../DataSource/Stores/AuthStore';
import Lang from '../Lang';
import styles from './Auth.module.scss';

type FieldType = {
  email?: string;
};

function Create() {
  const [active, setActive] = useState<boolean>(false);
  const [signUpProcessing, setSignUpProcessing] = useState<boolean>(false);
  const [signUpSuccess, setSignUpSuccess] = useState<boolean>(false);
  const [signUpError, setSignUpError] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({});

  const [form] = Form.useForm();
  const location = useLocation();
  const { Title, Text } = Typography;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params: QueryParams = {};
    for (const [key, value] of searchParams.entries()) {
      // @ts-ignore
      params[key] = value;
    }
    // setQueryParams(params);
    console.log('---- ww|params=', params);
    const { email, token } = params;
    setQueryParams(params);
    setActive(!!(email && token));
  }, [location.search]);

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    console.log('Success:', values);

    if (values.email) {
      try {
        setSignUpProcessing(true);
        await AuthStore.sendRecoveryLink(values.email);
        setSignUpSuccess(true);
      } catch (e) {
        setSignUpError(true);
        return console.warn('signUp exception:', e);
      } finally {
        setSignUpProcessing(false);
      }
    }
  };

  const onFinishCreate: FormProps<FieldTypeForgot>['onFinish'] = async (values) => {
    console.log('onFinishCreate Success:', values);

    if (values.password !== values.confirmpassword) {
      message.error('Passwords do not match');
      console.error('Passwords do not match');
      return; // Прерываем отправку формы
    }

    values.email = queryParams.email;
    values.token = queryParams.token;
    try {
      setSignUpProcessing(true);
      await AuthStore.recoveryPass(values);
      setSignUpSuccess(true);
    } catch (e) {
      setSignUpError(true);
      return console.warn('signUp exception:', e);
    } finally {
      setSignUpProcessing(false);
    }
  };

  const confirmPasswordValidator = (_rule: any, value: any, callback: any) => {
    const passwordFieldValue = form.getFieldValue('password');
    if (value && passwordFieldValue && value !== passwordFieldValue) {
      callback('Passwords do not match');
    } else {
      callback();
    }
  };

  const onFinishCreateFailed: FormProps<FieldTypeForgot>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
    // setActive(true);
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
    // setActive(true);
  };

  const setupProfile = () => {
    return (
      <div className={styles.fon_wrp}>
        <div>
          <h6 className={styles.title_lit}>{queryParams.email}</h6>
          <p className={styles.text_min + ' ' + styles.text_center + ' mb-24'}>
            Enter your details to recovery password
          </p>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinishCreate}
            onFinishFailed={onFinishCreateFailed}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item<FieldTypeForgot>
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item<FieldTypeForgot>
              name="confirmpassword"
              rules={[
                { required: true, message: 'Please confirm password!' },
                { validator: confirmPasswordValidator },
              ]}
            >
              <Input.Password placeholder="Confirm password" />
            </Form.Item>

            <Form.Item>
              <Button className={styles.button_prime} type="primary" htmlType="submit">
                Продтвердить
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const signUp = () => {
    return (
      <div className={styles.fon_wrp}>
        <div>
          <Title level={2}>{Lang({ str: 'forgotpas', section: 'default' })}</Title>
          <p className={styles.text_add}>Введите почту с которой был создан аккаунт</p>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item<FieldType>
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input prefix={<MailOutlined />} placeholder="Email" />
            </Form.Item>

            <Form.Item>
              <Button className={styles.button_prime} type="primary" htmlType="submit">
                Restore password
              </Button>
            </Form.Item>
            <p className={styles.link}>
              Уже есть аккаунт?<Link to="/login">Войти</Link>
            </p>
          </Form>
        </div>
      </div>
    );
  };

  if (signUpProcessing) {
    return (
      <div className={styles.fon_wrp}>
        <div>
          <Spin />
        </div>
      </div>
    );
  }

  return (
    <>
      {!signUpSuccess && !signUpError && active && setupProfile()}
      {!signUpSuccess && !signUpError && !active && signUp()}
      {signUpSuccess && (
        <div className={styles.fon_wrp}>
          <div>
            <Title level={2}>Проверьте почту</Title>
            Электронное письмо для обновления пароля было отправлено на ваш зарегистрированный адрес
            электронной почты. Пожалуйста, проверьте свою электронную почту, включая папку «Спам»,
            чтобы убедиться, что вы его получили.
          </div>
        </div>
      )}
      {/*signUpSuccess && <>Success</>*/}
      {signUpError && <>Error</>}
    </>
  );
}

export default Create;
