import type { GetProps } from 'antd';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ManagerStore } from '../../../../DataSource/Stores/ManagerStore';
import Button from '../../../../components/ui/Button';
import Icon from '../../../../components/ui/Icon';
import HeaderMessage from '../HeaderMessage';
import styles from './index.module.scss';

interface Props {
  title: string;
}

type SearchProps = GetProps<typeof Input.Search>;
const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);
const HeaderTop = ({ title }: Props) => {
  const toggleMessageOpen = () => {
    ManagerStore.messageOpenBl = !ManagerStore.messageOpenBl;
  };
  const { Search } = Input;
  const navigate = useNavigate();

  return (
    <div className={styles.header_top}>
      <div className={styles.one_bl}>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          variant="delivery_outline"
        >
          <Icon slug={'fi-rr-arrow-left'} />
        </Button>
        <p className={styles.title}>{title}</p>
      </div>
      <div className={styles.one_bl}>
        <div className={styles.message_but}>
          <Button onClick={toggleMessageOpen} variant="delivery_outline">
            <Icon slug={'fi-rr-messages'} />
          </Button>
          <span>5</span>
        </div>

        {ManagerStore.messageOpenBl ? (
          <div className={styles.message_bl}>
            <HeaderMessage />
          </div>
        ) : (
          ''
        )}

        <Search
          placeholder="Найдите заказ по id, адресу или курьеру"
          onSearch={onSearch}
          style={{ width: 350 }}
        />
      </div>
    </div>
  );
};
export default observer(HeaderTop);
