import { Modal, Radio, Space } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { useState } from 'react';

import Button from '../../../ui/Button';
import styles from './index.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  items: { value: string; label: string }[];
  onSubmit: (type: string) => void;
}

const FinishJobModal = ({ open, onClose, items, onSubmit }: IProps) => {
  const [finishType, setFinishType] = useState('');

  const onChange = (e: RadioChangeEvent) => {
    setFinishType(e.target.value);
  };

  return (
    <Modal
      centered
      footer={
        <Button onClick={() => onSubmit(finishType)} className={styles.blueBtn}>
          Отправить
        </Button>
      }
      className={styles.typeJobModal}
      title="Выберите тип"
      open={open}
      onCancel={onClose}
      cancelText="No"
    >
      <Radio.Group size="large" onChange={onChange} value={finishType}>
        <Space direction="vertical">
          {items.map((item) => (
            <Radio value={item.value} key={item.value}>
              {item.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default FinishJobModal;
