// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gIcIsFceGuXU3LDN9sDN{margin:16px 0;font-weight:600;display:flex}.gIcIsFceGuXU3LDN9sDN:before,.gIcIsFceGuXU3LDN9sDN:after{color:#e5e5e5;content:"";flex:1;border-bottom:solid 1px #e5e5e5;margin:13px auto}.gIcIsFceGuXU3LDN9sDN p{padding:4px 10px;border-radius:20px;margin:0 16px;font-size:15px}.gIcIsFceGuXU3LDN9sDN._Fmn2vwPbOBxb6l4_f12 p{color:#ff9914;background:rgba(255,153,20,.1)}.gIcIsFceGuXU3LDN9sDN.xVPVlUu_1joJtEsWiXNq p{color:#e9d309;background:rgba(255,231,20,.1)}.gIcIsFceGuXU3LDN9sDN.FjbdCdgZu_AZ5Za4BXqb p{color:#74c978;background:rgba(115,201,119,.1)}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/StatusTitle/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,eAAA,CAEA,YAAA,CAEA,yDAEE,aAAA,CACA,UAAA,CACA,MAAA,CACA,+BAAA,CACA,gBAAA,CAEF,wBACE,gBAAA,CACA,kBAAA,CACA,aAAA,CACA,cAAA,CAGA,6CACE,aAAA,CAEA,8BAAA,CAIF,6CACE,aAAA,CAEA,8BAAA,CAIF,6CACE,aAAA,CAEA,+BAAA","sourcesContent":[".text_block {\n  margin: 16px 0;\n  font-weight: 600;\n\n  display: flex;\n\n  &:before,\n  &:after {\n    color: #e5e5e5;\n    content: '';\n    flex: 1;\n    border-bottom: solid 1px #e5e5e5;\n    margin: 13px auto;\n  }\n  p {\n    padding: 4px 10px;\n    border-radius: 20px;\n    margin: 0 16px;\n    font-size: 15px;\n  }\n  &.work {\n    p {\n      color: #ff9914;\n\n      background: rgba(255, 153, 20, 0.1);\n    }\n  }\n  &.create {\n    p {\n      color: #e9d309;\n\n      background: rgba(255, 231, 20, 0.1);\n    }\n  }\n  &.active {\n    p {\n      color: #74C978;\n\n      background: rgba(115, 201, 119, 0.1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text_block": `gIcIsFceGuXU3LDN9sDN`,
	"work": `_Fmn2vwPbOBxb6l4_f12`,
	"create": `xVPVlUu_1joJtEsWiXNq`,
	"active": `FjbdCdgZu_AZ5Za4BXqb`
};
export default ___CSS_LOADER_EXPORT___;
