import { FC } from 'react';
import { $class } from '../../../utils';
import Clickable from '../Clickable';
import Icon from '../Icon';
import './index.scss';

type IconButtonVariant = 'primary' | 'outlined' | 'clear' | 'secondary';
type IconButtonSize = 'sm' | 'md' | 'lg';

interface IProps {
  className?: string;
  variant?: IconButtonVariant;
  size?: IconButtonSize;
  icon?: string;
  onClick?: () => void;
}

const IconButton: FC<IProps> = ({ className, variant = 'primary', size = 'md', icon, onClick }) => {
  return (
    <Clickable
      className={$class(
        'ui-icon-button',
        `ui-icon-button--size_${size}`,
        ['ui-icon-button--primary', variant === 'primary'],
        ['ui-icon-button--secondary', variant === 'secondary'],
        ['ui-icon-button--clear', variant === 'clear'],
        ['ui-icon-button--outlined', variant === 'outlined'],
        className
      )}
      onClick={onClick}
    >
      <Icon slug={icon} className="ui-icon-button-icon" />
    </Clickable>
  );
};

export default IconButton;
