import { Typography } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Auth.module.scss';

type FieldType = {
  email?: string;
};

function Create() {
  const [active, setActive] = useState<boolean>(false);
  const [signUpProcessing, setSignUpProcessing] = useState<boolean>(false);
  const [signUpSuccess, setSignUpSuccess] = useState<boolean>(false);
  const [signUpError, setSignUpError] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({});

  const location = useLocation();
  const { Title, Text } = Typography;

  return (
    <div className={styles.fon_wrp}>
      <div>
        <Title level={2}>Проверьте почту</Title>
        <p className={styles.text_add}>
          На вашу почту отправлена ссылка для входа в личный кабинет.
        </p>
      </div>
    </div>
  );
}

export default Create;
