// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RL3BeXWkSQWVLAaWfrdC{position:relative;display:flex;justify-content:space-between;margin-bottom:24px}.RL3BeXWkSQWVLAaWfrdC .FJ84Z_xM0FbnUbTYBRDs{background:rgba(241,183,41,.08);border:.5px solid #f1b729;border-radius:8px;padding:4px 16px;display:flex;align-items:center;font-weight:500;width:fit-content;font-size:16px;color:#f1b729;cursor:pointer;text-align:center}.RL3BeXWkSQWVLAaWfrdC .FJ84Z_xM0FbnUbTYBRDs svg{margin-right:8px}.tiq01DMsAJEZ0WOv9CgQ{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/NewJobsBlock/%20SelectedTask/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,4CAEE,+BAAA,CACA,yBAAA,CACA,iBAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA,CACA,cAAA,CACA,aAAA,CACA,cAAA,CACA,iBAAA,CACA,gDACE,gBAAA,CAIN,sBACE,UAAA","sourcesContent":[".flex_block {\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 24px;\n  .back {\n    // transform: translateY(-100%);\n    background: rgba(241, 183, 41, 0.08);\n    border: 0.5px solid #f1b729;\n    border-radius: 8px;\n    padding: 4px 16px;\n    display: flex;\n    align-items: center;\n    font-weight: 500;\n    width: fit-content;\n    font-size: 16px;\n    color: #f1b729;\n    cursor: pointer;\n    text-align: center;\n    svg {\n      margin-right: 8px;\n    }\n  }\n}\n.prompts_create {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex_block": `RL3BeXWkSQWVLAaWfrdC`,
	"back": `FJ84Z_xM0FbnUbTYBRDs`,
	"prompts_create": `tiq01DMsAJEZ0WOv9CgQ`
};
export default ___CSS_LOADER_EXPORT___;
