// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uuck354Nn59pftn0OWbI{background:none;display:flex;align-items:center;border:0}.uuck354Nn59pftn0OWbI p{margin-right:8px;font-size:15px}.uuck354Nn59pftn0OWbI p span{margin-right:8px}.uuck354Nn59pftn0OWbI i{color:#878787;font-size:13px}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/CourierItem/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CACA,wBACE,gBAAA,CACA,cAAA,CACA,6BACE,gBAAA,CAGJ,wBACE,aAAA,CACA,cAAA","sourcesContent":[".courier_item {\n  background: none;\n  display: flex;\n  align-items: center;\n  border: 0;\n  p {\n    margin-right: 8px;\n    font-size: 15px;\n    span {\n      margin-right: 8px;\n    }\n  }\n  i {\n    color: #878787;\n    font-size: 13px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courier_item": `uuck354Nn59pftn0OWbI`
};
export default ___CSS_LOADER_EXPORT___;
