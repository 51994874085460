import { Collapse, CollapseProps } from 'antd';
import dayjs from 'dayjs';
import { FC, ReactNode } from 'react';
import { StagesStore } from '../../../../../../DataSource/Stores/Cabinet/StagesStore';
import styles from './index.module.scss';

interface IProps {
  expandIcon: (panelProps: any) => ReactNode;
}

const SystemTasksBlock: FC<IProps> = ({ expandIcon }) => {
  const systemTasksCollapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'СИСТЕМНЫЕ ЗАДАЧИ',
      children: StagesStore.dataSystemTask.map((task) => (
        <div key={task._id} className={styles.fieldsWrap}>
          <div className={styles.field}>
            <p className={styles.name}>
              #{task._id.slice(-4)}: {task.status}
            </p>
            <div className={styles.copyBlock}>
              {dayjs(task.updatedAt).format('DD / MM / YYYY, HH:mm:ss')}
            </div>
          </div>
          {/*<div className={styles.field}>*/}
          {/*  <p className={styles.name}>UpdatedAt</p>*/}
          {/*  <DatePicker*/}
          {/*    showTime*/}
          {/*    allowClear={false}*/}
          {/*    suffixIcon={null}*/}
          {/*    variant="filled"*/}
          {/*    format="DD / MM / YYYY, HH:mm"*/}
          {/*    style={{ backgroundColor: '#F3F5F6E2', borderRadius: '8px', padding: '4px 8px' }}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      )),
    },
  ];

  return (
    <Collapse
      items={systemTasksCollapseItems}
      expandIcon={expandIcon}
      expandIconPosition="right"
      bordered={false}
      ghost
    />
  );
};

export default SystemTasksBlock;
