// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OtQMAvO2VV0cI1mPQmW5{display:flex;flex-direction:row;column-gap:16px}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/Main/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".flex_worksp {\n  display: flex;\n  flex-direction: row;\n  column-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex_worksp": `OtQMAvO2VV0cI1mPQmW5`
};
export default ___CSS_LOADER_EXPORT___;
