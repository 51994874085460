// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A4uoIgaQEsDfYoHsGvQW{justify-content:space-between;display:flex;padding:0 16px;align-items:center}.A4uoIgaQEsDfYoHsGvQW .eS1jIpteofdfVcUoqS6n span{font-weight:300;font-size:14px;text-transform:uppercase;color:#878787}.A4uoIgaQEsDfYoHsGvQW .eS1jIpteofdfVcUoqS6n p{font-weight:600;font-size:20px;color:#000}.cuJ0_FFEgLKmImvnlkYf{justify-content:space-between;display:flex;align-items:center;column-gap:8px}.mdfyeAB0dNIX7dsKZBAG{font-weight:400;margin-bottom:16px;font-size:15px;color:#000;margin-top:16px;padding-left:16px}.qLdfnJQiuBo_LFzJa_vm{padding:0 8px}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Kanban/NewOrders/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,6BAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CAEE,iDACE,eAAA,CACA,cAAA,CACA,wBAAA,CACA,aAAA,CAEF,8CACE,eAAA,CACA,cAAA,CACA,UAAA,CAIN,sBACE,6BAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CAGF,sBACE,eAAA,CACA,kBAAA,CACA,cAAA,CACA,UAAA,CACA,eAAA,CACA,iBAAA,CAEF,sBACE,aAAA","sourcesContent":[".flex_bl {\n  justify-content: space-between;\n  display: flex;\n  padding: 0 16px;\n  align-items: center;\n  .text {\n    span {\n      font-weight: 300;\n      font-size: 14px;\n      text-transform: uppercase;\n      color: #878787;\n    }\n    p {\n      font-weight: 600;\n      font-size: 20px;\n      color: #000;\n    }\n  }\n}\n.d_flex {\n  justify-content: space-between;\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n}\n\n.order_text {\n  font-weight: 400;\n  margin-bottom: 16px;\n  font-size: 15px;\n  color: #000;\n  margin-top: 16px;\n  padding-left: 16px;\n}\n.order_list {\n  padding: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex_bl": `A4uoIgaQEsDfYoHsGvQW`,
	"text": `eS1jIpteofdfVcUoqS6n`,
	"d_flex": `cuJ0_FFEgLKmImvnlkYf`,
	"order_text": `mdfyeAB0dNIX7dsKZBAG`,
	"order_list": `qLdfnJQiuBo_LFzJa_vm`
};
export default ___CSS_LOADER_EXPORT___;
