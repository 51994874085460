import { ModalProps } from '../../layout/Modals';
import styles from './index.module.scss';
import { useState } from 'react';
import { WorkspacesStore } from '../../DataSource/Stores/Cabinet/WorkspacesStore';
import { observer } from 'mobx-react-lite';
import Input from '../../components/ui/Input';
import Button from '../../components/ui/Button';
import toast from 'react-hot-toast';

interface IProps {
  workspaceId: string;
}

const WorkspaceAddMember = ({ data, closeModal }: ModalProps<IProps>) => {
  const { workspaceId } = data;
  const [email, setEmail] = useState('');

  const add = async () => {
    if (email === '') {
      return;
    }

    try {
      await WorkspacesStore.inviteAdd.call(workspaceId, email);
      await WorkspacesStore.getList();
      closeModal();
      toast.success('Приглашение отправлено');
    } catch (e: any) {
      console.error(e);
      toast.error(e.message.message);
    }
  };

  return (
    <div className={styles.addMemberModal}>
      <p className={styles.title}>Добавить пользователя</p>
      <Input
        name={'email'}
        placeholder="Введите E-mail"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button variant="secondary" loading={WorkspacesStore.inviteAdd.loading} onClick={add}>
        Пригласить
      </Button>
    </div>
  );
};

export default observer(WorkspaceAddMember);
