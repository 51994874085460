import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import Icon from '../../../ui/Icon';
import CourierItem from '../CourierItem';
import styles from './index.module.scss';

const HistoryOrderItem: React.FC = () => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" href="/profile">
          Редактировать
        </a>
      ),
    },
    {
      key: '2',
      label: <a>Удалить</a>,
    },
  ];
  return (
    <div className={styles.order_item}>
      <div className={styles.fl_block + ' ' + styles.fl_beetw}>
        <div className={styles.fl_block}>
          <div>
            <span>#09.08.24 12:00</span>
          </div>
          <CourierItem title={'Andrey'} icon={false} />
          <div className={styles.text_data}>
            <p>Изменение статуса</p>
          </div>
        </div>
        <div className={styles.but_bl}>
          <Dropdown className={styles.menuprogile} trigger={['click']} menu={{ items }}>
            <a className={styles.but_button} onClick={(e) => e.preventDefault()}>
              <Icon slug={'fi-rr-menu-dots-vertical'} />
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default observer(HistoryOrderItem);
