import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import usePageTitle from '../../../DataSource/Hooks/usePageTitle';
import { AuthStore } from '../../../DataSource/Stores/AuthStore';
import styles from '../Auth.module.scss';
import { observer } from 'mobx-react';
import SignUp from './SignUp';
import SetupProfile from './SetupProfile';

export type FieldTypetwo = {
  firstname: string;
  secondname: string;
  password: string;
  confirmpassword: string;
  email?: string;
  token?: string;
};

interface QueryParams {
  email?: string;
  token?: string;
  [key: string]: string | undefined;
}

function Index() {
  const [active, setActive] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({});
  const location = useLocation();
  usePageTitle('Sign up');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params: QueryParams = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    console.log('---- ww|params=', params);
    const { email, token } = params;
    setQueryParams(params);
    setActive(!!(email && token));
  }, [location.search]);

  if (AuthStore.loggedTesting || AuthStore.sending) {
    return (
      <div className={styles.form_wrp + ' center'}>
        <Spin />
      </div>
    );
  }

  if (AuthStore.signUpError) {
    return <>Error</>;
  }

  return (
    <div className={styles.form_wrp}>
      {!AuthStore.signUpSuccess && !AuthStore.signUpError && active && (
        <SetupProfile queryParams={queryParams} />
      )}
      {!AuthStore.signUpSuccess && !AuthStore.signUpError && !active && <SignUp />}
      {AuthStore.signUpSuccess && (
        <>
          The email requesting confirmation of your email address has been dispatched to your
          registered email address. Kindly review your inbox, including the «Spam» folder, to ensure
          receipt.
        </>
      )}
    </div>
  );
}

export default observer(Index);
