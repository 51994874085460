import { ConfigProvider, Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import svgimg from '../assets/bg.svg';
import styles from '../components/Auth/Auth.module.scss';

function AuthLayout() {
  const location = useLocation();
  if (location.pathname === '/zakazbuketov') {
    return (
      <ConfigProvider>
        <Outlet />
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider>
      <Layout>
        <div className="main_block">
          <div className={styles.row_bl}>
            <Outlet />
            <div className={styles.image_bll}>
              <img src={svgimg} alt="" />
            </div>
          </div>
        </div>
      </Layout>
    </ConfigProvider>
  );
}

export default AuthLayout;
