import { makeAutoObservable, observable, runInAction } from 'mobx';
import { CpFieldType, DataTypeMap, LoadingMap } from '../../../components/Admin/OrdersParameters';
import { basicRequestDelete } from '../../Requests/basicRequestDelete';
import { basicRequestPost } from '../../Requests/basicRequestPost';
import { basicRequestGet } from '../../Requests/basicRequestGet';
import { TabsProps } from 'antd';
import { basicRequestPatch } from '../../Requests/basicRequestPatch';
import { RenameOrderTypeName } from '../../../components/Admin/OrdersParameters/InputTab';
import { UniqueIdentifier } from '@dnd-kit/core';
import { DataType } from '../../../components/Admin/ProductParameters';

export class OrderParamsClass {
  @observable paramTypeId: OrdersStatus = 'first';
  @observable _loadingList: LoadingMap = {};
  @observable loadingError: boolean = false;
  @observable loadingParamError: LoadingMap = {};
  @observable loading: boolean = false;
  @observable loadingParamTypesList = false;
  @observable paramTypesList: TabsProps['items'] = [];
  @observable visibInput: boolean = false;
  @observable addingTab: boolean = false;
  @observable renameTab: boolean = false;
  @observable removeTab: boolean = false;

  @observable _dataList: DataTypeMap = { type: [] as ChParamDataType[] };
  @observable paramData: ChParamDataType | null = null;

  constructor(paramTypeId?: OrdersStatus) {
    if (paramTypeId) this.paramTypeId = paramTypeId;
    // makeObservable(this);
    makeAutoObservable(this);
  }

  /**
   *
   */
  async addType(data: CpFieldType) {
    data.param_type_id = this.paramTypeId;
    await basicRequestPost('/admin/orders_list/add_type', data);
  }

  /**
   *
   */
  async editType(data: CpFieldType) {
    data.param_type_id = this.paramTypeId;
    await basicRequestPost('/admin/orders_list/edit_type', data);
  }

  /**
   *
   */
  async getParametersList(param_type_id?: string) {
    // console.log('---- ww|getParametersList|param_type_id=', param_type_id);
    if (param_type_id) {
      this.paramTypeId = param_type_id;
    } else {
      param_type_id = this.paramTypeId;
    }
    if (this._loadingList[param_type_id]) {
      return;
    }
    runInAction(() => {
      this._loadingList[param_type_id] = true;
    });

    try {
      const res = await basicRequestGet(`/admin/orders_list/list/${param_type_id}`);
      // return {
      //   data: res.data.data.data.map((i: any) => {
      //     i.key = i._id;
      //     i.tags = [];
      //     return i;
      //   }) as DataType[],
      //   pagination: res.data.data.pagination,
      // };
      // console.log('---- ww|getParametersList|basicRequestPost|param_type_id=', param_type_id);
      runInAction(() => {
        delete this.loadingParamError[param_type_id];
        this._dataList[param_type_id] = res.data.data.docs.map((i: any) => {
          i.key = i._id;
          i.tags = [];
          return i;
        }) as DataType[];
        // console.log(
        //   '---- ww|getParametersList|basicRequestPost|param_type_id=',
        //   param_type_id,
        //   this._dataList[param_type_id]
        // );
      });
    } catch (error) {
      runInAction(() => {
        this.loadingParamError[param_type_id] = true;
      });
      throw error;
    } finally {
      runInAction(() => {
        this._loadingList[param_type_id] = false;
      });
    }
  }

  get loadingList() {
    return this._loadingList[this.paramTypeId];
  }

  set loadingList(val) {
    console.log('---- ww|loadingList|val=', val, this.paramTypeId);
    runInAction(() => {
      this._loadingList[this.paramTypeId] = val;
    });
  }

  get dataList(): ChParamDataType[] {
    // console.log(
    //   '---- ww|loadingList|get|this.paramType=',
    //   this.paramType,
    //   this._dataList[this.paramType]
    // );
    return this._dataList[this.paramTypeId] || [];
  }

  set dataList(val) {
    this._dataList[this.paramTypeId] = val;
  }

  /**
   *
   */
  async changeSort(
    active_id: UniqueIdentifier,
    over_id: UniqueIdentifier | null,
    param_type_id?: string
  ) {
    console.log('---- ww|changeSort');
    if (param_type_id) {
      this.paramTypeId = param_type_id;
    } else {
      param_type_id = this.paramTypeId;
    }
    if (this._loadingList[param_type_id]) {
      return;
    }
    runInAction(() => {
      this._loadingList[param_type_id] = true;
    });

    // this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPost('/admin/orders_list/change_order_params_sort', {
        active_id,
        over_id,
      });
    } catch (error) {
      console.error('changeSort|error=', error);
      throw error;
    } finally {
      runInAction(() => {
        this._loadingList[param_type_id] = false;
      });
    }
  }

  /**
   *
   */
  async deleteParam(_id: string) {
    const index = this.dataList.findIndex((obj) => obj._id === _id);
    console.log('deleteParam|index=', index);
    if (index === -1) {
      console.error('deleteParam|index !== -1');
      throw 'Remove error';
    }
    await basicRequestDelete('/admin/orders_list/delete_param/' + _id);
    this.dataList = this.dataList.filter((obj) => obj._id !== _id);
  }

  /**
   *
   */
  async getOrderTypesList() {
    // console.log('---- ww|getOrderTypesList|param_type_id=', param_type_id);

    if (this.loadingParamTypesList) {
      return;
    }
    runInAction(() => {
      this.loadingParamTypesList = true;
    });

    try {
      const res = await basicRequestGet('/admin/orders_list/order_param_types');
      // return {
      //   data: res.data.data.data.map((i: any) => {
      //     i.key = i._id;
      //     i.tags = [];
      //     return i;
      //   }) as DataType[],
      //   pagination: res.data.data.pagination,
      // };
      // console.log('---- ww|getOrderTypesList|basicRequestGet|res.data=', res.data);
      runInAction(() => {
        this.paramTypesList = res.data.data.docs.map((i: any) => {
          i.key = i._id;
          i.label = i.param_name;
          return i;
        }) as TabsProps['items'];
        console.log(
          '---- ww|getOrderTypesList|basicRequestGet|this.paramTypesList=',
          this.paramTypesList
        );
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingParamTypesList = false;
      });
    }
  }

  /**
   *
   */
  async renameOrderTypeName({ param_type_id, param_name }: RenameOrderTypeName): Promise<string> {
    console.log('---- ww|renameOrderTypeName');

    runInAction(() => {
      this.renameTab = true;
    });

    // this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPatch(`/admin/orders_list/rename_parameter_type_name`, {
        param_type_id,
        param_name,
      });
      console.log('---- ww|renameOrderTypeName|res=', res.data.data);
      return res.data.data as string;
    } catch (error) {
      console.error('renameOrderTypeName|error=', error);
      throw error;
    } finally {
      runInAction(() => {
        this.renameTab = false;
      });
    }
  }

  /**
   *
   */
  async addOrderParam(): Promise<string> {
    console.log('---- ww|addOrderParam');

    runInAction(() => {
      this.addingTab = true;
    });

    // this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPost('/admin/orders_list/add_order_param_type', {});
      console.log('---- ww|addOrderParam|res=', res.data.data);
      return res.data.data as string;
    } catch (error) {
      console.error('addOrderParam|error=', error);
      throw error;
    } finally {
      runInAction(() => {
        this.addingTab = false;
      });
    }
  }

  /**
   *
   */
  async deleteOrderParam(orderId: string) {
    console.log('---- ww|deleteOrderParam|orderId=', orderId);

    runInAction(() => {
      this.removeTab = true;
    });

    // this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestDelete(`/admin/orders_list/delete_order_param_type/${orderId}`);
    } catch (error) {
      console.error('deleteOrderParam|error=', error);
      throw error;
    } finally {
      runInAction(() => {
        this.removeTab = false;
      });
    }
  }
}
