import styles from './index.module.scss';
import { Collapse, Dropdown, Flex, MenuProps, Select, Table } from 'antd';
import IconButton from '../../../../ui/IconButton';
import Button from '../../../../ui/Button';
import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Condition, Condition2, StageParam } from '../../../../ types';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Clickable from '../../../../ui/Clickable';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';

type CustomRowProps = {
  children: ReactNode;
  [key: string]: any;
};

interface IProps extends Condition {
  index: number;
  stageParams: StageParam[];
  removeRule: (ruleIndex: number) => void;
  addParam: (ruleIndex: number) => void;
  removeParam: (ruleIndex: number, paramIndex: number) => void;
  setParam: (ruleIndex: number, paramIndex: number, param: Condition2) => void;
}

const RuleBlock: FC<IProps> = ({
  index,
  stageParams,
  removeRule,
  addParam,
  removeParam,
  setParam,
  toStageId,
  conditions,
}) => {
  const customExpandIcon = ({ isActive }: any) => (
    <UpOutlined style={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)' }} />
  );

  const toStageIdDropdownMenu: MenuProps['items'] = StagesStore.dataList.map((stage) => ({
    key: stage._id,
    label: stage._id,
  }));

  const columns = [
    {
      title: 'paramId',
      dataIndex: 'paramId',
      width: '33.3%',
      render: (text: string, record: Condition2, i: number) => (
        <Select
          className={styles.select}
          placeholder="Select"
          defaultValue={text || null}
          options={stageParams.map((item) => ({
            value: item._id,
            label: item._id,
          }))}
          style={{ width: '100%' }}
          onChange={(value) => setParam(index, i, { ...record, paramId: value })}
        />
      ),
    },
    {
      title: 'sign',
      dataIndex: 'sign',
      width: '33.3%',
      render: (text: string, record: Condition2, i: number) => (
        <Select
          className={styles.select}
          placeholder="Select"
          defaultValue={text || null}
          options={['=', '!=', '>', '>=', '<', '<='].map((item) => ({
            value: item,
            label: item,
          }))}
          style={{ width: '100%' }}
          onChange={(value) => setParam(index, i, { ...record, sign: value })}
        />
      ),
    },
    {
      title: 'paramValue',
      dataIndex: 'paramValue',
      width: '33.3%',
      render: (text: string, record: Condition2, i: number) => (
        <Select
          className={styles.select}
          placeholder="Select"
          defaultValue={text || null}
          options={['true', 'false'].map((item) => ({
            value: item,
            label: item,
          }))}
          style={{ width: '100%' }}
          onChange={(value) => setParam(index, i, { ...record, paramValue: value })}
        />
      ),
    },
  ];

  return (
    <Collapse
      items={[
        {
          key: '1',
          label: (
            <Flex align="center" gap={16}>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: '0',
                      label: 'Создать блок',
                    },
                    {
                      key: '1',
                      danger: true,
                      label: 'Удалить правило',
                      onClick: () => removeRule(index),
                    },
                  ],
                }}
                trigger={['click']}
              >
                <IconButton icon="three_dots" variant="secondary" size="sm" />
              </Dropdown>
              НАИМЕНОВАНИЕ ПРАВИЛА
            </Flex>
          ),
          children: (
            <div className={styles.fieldsWrap}>
              <div className={styles.field}>
                <p className={styles.name}>toStageID</p>
                {toStageId}
                {/*<Dropdown menu={{ items: toStageIdDropdownMenu }} trigger={['click']}>*/}
                {/*  <Clickable className={styles.dropdownBtn}>*/}
                {/*    {toStageId}*/}
                {/*    <DownOutlined color="#2A3038" />*/}
                {/*  </Clickable>*/}
                {/*</Dropdown>*/}
              </div>

              {conditions.map((item, i) => (
                <div key={i} className={styles.tableWrap}>
                  <Table
                    className={styles.table}
                    bordered
                    pagination={false}
                    dataSource={item}
                    columns={columns}
                    rowKey="key"
                    onRow={(_, index) => {
                      return {
                        onClick: () => {},
                        'data-row-index': index,
                      };
                    }}
                    components={{
                      header: {
                        row: ({ children, ...props }: CustomRowProps) => (
                          <tr {...props} style={{ position: 'relative' }}>
                            {children}
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: '1',
                                    label: 'Удалить блок',
                                    danger: true,
                                  },
                                ],
                              }}
                              trigger={['click']}
                            >
                              <IconButton
                                className={styles.actionBtn}
                                icon="three_dots_vertical"
                                variant="clear"
                                size="sm"
                                onClick={() => console.log('Header Action')}
                              />
                            </Dropdown>
                          </tr>
                        ),
                      },
                      body: {
                        row: ({ children, ...props }: CustomRowProps) => {
                          const rowIndex = item.findIndex((_, i) => i === props['data-row-index']);

                          return (
                            <tr {...props} style={{ position: 'relative' }}>
                              {children}
                              <Dropdown
                                menu={{
                                  items: [
                                    {
                                      key: '1',
                                      label: 'Удалить строку',
                                      danger: true,
                                      onClick: () => removeParam(index, rowIndex),
                                    },
                                  ],
                                }}
                                trigger={['click']}
                              >
                                <IconButton
                                  className={styles.actionBtn}
                                  icon="three_dots_vertical"
                                  variant="clear"
                                  size="sm"
                                />
                              </Dropdown>
                            </tr>
                          );
                        },
                      },
                    }}
                  />
                  <Button
                    className={styles.addParamBtn}
                    variant="secondary"
                    onClick={() => addParam(index)}
                  >
                    <b>+</b> Добавить параметр
                  </Button>
                </div>
              ))}
            </div>
          ),
        },
      ]}
      expandIcon={customExpandIcon}
      expandIconPosition="end"
      bordered={false}
      ghost
    />
  );
};

export default observer(RuleBlock);
