import { ConfigProvider, Drawer, Layout } from 'antd';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';
import { HeaderStore } from '../DataSource/Stores/HeaderStore';
import MenuAdmin from '../components/Admin/MenuAdmin';
import Header from '../components/Header/Header';
import styles from '../components/NewProduct/NewProduct.module.scss';

function AdminLayout() {
  const onClose = () => {
    HeaderStore.setOpenRight(false);
  };
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' });
  return (
    <ConfigProvider>
      <Layout>
        <div className="cabinet_block">
          <Header />
          <div className={styles.flex_row}>
            {isTabletOrMobile ? (
              <Drawer
                className={styles.drawer}
                onClose={onClose}
                placement={'left'}
                open={HeaderStore.setopendrawer}
              >
                <MenuAdmin />
              </Drawer>
            ) : (
              <div className={styles.first_column}>
                <MenuAdmin />
              </div>
            )}

            <div className={styles.second_column}>
              <Outlet />
            </div>
          </div>
        </div>
      </Layout>
    </ConfigProvider>
  );
}

export default observer(AdminLayout);
