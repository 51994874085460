// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CfWmC62QLq8PfkpoIN7c{margin:0;font-weight:600;display:flex}.CfWmC62QLq8PfkpoIN7c p{padding:4px 10px;font-weight:400;border-radius:20px;margin:0;font-size:15px}.CfWmC62QLq8PfkpoIN7c.oTFC1mj5LPf6b5tvyt_w p{color:#ff9914;background:rgba(255,153,20,.1)}.CfWmC62QLq8PfkpoIN7c.WTqAzyOFesswupNIt9zI p{color:#74c978;background:rgba(116,201,120,.1)}.CfWmC62QLq8PfkpoIN7c.Pz47vAD1v464_krCZnEg p{color:#878787;background:rgba(75,77,76,.1)}.CfWmC62QLq8PfkpoIN7c.oocAJBmdltfzA2MZNJ5U p{color:#e9d309;background:rgba(232,210,8,.1)}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/StatusTag/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CACA,eAAA,CACA,YAAA,CAEA,wBACE,gBAAA,CACA,eAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA,CAGA,6CACE,aAAA,CAEA,8BAAA,CAIF,6CACE,aAAA,CAEA,+BAAA,CAIF,6CACE,aAAA,CAEA,4BAAA,CAIF,6CACE,aAAA,CAEA,6BAAA","sourcesContent":[".text_block {\n  margin: 0;\n  font-weight: 600;\n  display: flex;\n\n  p {\n    padding: 4px 10px;\n    font-weight: 400;\n    border-radius: 20px;\n    margin: 0;\n    font-size: 15px;\n  }\n  &.work {\n    p {\n      color: #ff9914;\n\n      background: rgba(255, 153, 20, 0.1);\n    }\n  }\n  &.create {\n    p {\n      color: #74c978;\n\n      background: rgba(116, 201, 120, 0.1);\n    }\n  }\n  &.unactive {\n    p {\n      color: #878787;\n\n      background: rgba(75, 77, 76, 0.1);\n    }\n  }\n  &.indelivery {\n    p {\n      color: #E9D309;\n\n      background: rgba(232, 210, 8, 0.1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text_block": `CfWmC62QLq8PfkpoIN7c`,
	"work": `oTFC1mj5LPf6b5tvyt_w`,
	"create": `WTqAzyOFesswupNIt9zI`,
	"unactive": `Pz47vAD1v464_krCZnEg`,
	"indelivery": `oocAJBmdltfzA2MZNJ5U`
};
export default ___CSS_LOADER_EXPORT___;
