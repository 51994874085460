import { FC, useEffect, useState } from 'react';
import { $class } from '../../../utils';
import Timer from '../../Cabinet/Timer';
import './index.scss';
import dayjs from 'dayjs';

interface IProps {
  startDate?: string;
  expectedDate?: string;
}

const ProgressBar: FC<IProps> = ({ startDate, expectedDate }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const start = dayjs(startDate);
    const end = dayjs(expectedDate);
    const startMs = start.valueOf();
    const endMs = end.valueOf();
    const totalTime = endMs - startMs;

    if (totalTime <= 0) {
      setProgress(100);
    } else {
      const currentTime = dayjs().valueOf();
      if (currentTime >= endMs) {
        setProgress(100);
      } else {
        const progress = ((currentTime - startMs) / totalTime) * 100;
        setProgress(progress);
      }
    }
  }, [startDate, expectedDate]);

  return (
    <div className="progress-bar-wrap">
      <Timer targetDateTimer={dayjs(expectedDate)} />

      <div
        className={$class(
          'progress-bar',
          ['in-progress', progress !== 100],
          ['completed', progress === 100]
        )}
      >
        <div style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
