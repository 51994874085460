import { makeAutoObservable, observable } from 'mobx';
import { basicRequestGet } from '../../Requests/basicRequestGet';
import { basicRequestDelete } from '../../Requests/basicRequestDelete';
import { ProductDataType } from '../../../components/ProductAccounts/ProductAccounts';

export class OrdersStoreActionsClass {
  @observable loadingError: boolean = false;

  @observable loading: boolean = false;

  @observable loadingDelete: boolean = false;

  @observable loadingPaymentUrl: boolean = false;

  @observable dataList: ProductDataType[] = [];

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  /**
   *
   */
  async getList() {
    console.log('---- ww|getList');
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.loadingError = false;
    this.dataList = [];

    try {
      const res = await basicRequestGet('/new_product/orders_list');
      console.log('---- ww|getList|res.data=', res.data);
      this.dataList = res.data.data.docs.map((i: any, k: number) => {
        i.key = k + 1;
        // i.tags = [];
        return i;
      }) as ProductDataType[];
    } catch (error) {
      // throw error;
      console.error('getList|error=', error);
      this.loadingError = true;
    } finally {
      this.loading = false;
    }
  }

  /**
   *
   */
  async deleteProduct(id: string) {
    const index = this.dataList.findIndex((obj) => obj._id === id);
    console.log('deleteProduct|index=', index);
    if (index === -1) {
      console.error('deleteProduct|index !== -1');
      throw 'Remove error';
    }
    this.loadingDelete = true;
    try {
      await basicRequestDelete('/new_product/order/delete/' + id);
      this.dataList = this.dataList.filter((obj) => obj._id !== id);
    } catch (error) {
      console.error('cancelProduct|error=', error);
      throw error;
    } finally {
      this.loadingDelete = false;
    }
  }

  /**
   *
   */
  async cancelProduct(id: string) {
    this.loadingDelete = true;
    try {
      await basicRequestDelete('/new_product/order/cancel/' + id);
      this.dataList = this.dataList.map((e) => {
        if (id == e._id) e.payment_status = 'CANCELED';
        return e;
      });
    } catch (error) {
      console.error('cancelProduct|error=', error);
      throw error;
    } finally {
      this.loadingDelete = false;
    }
  }

  /**
   *
   */
  async getPaymentUrl(order_id: string, product_id: string, product_param_id: string) {
    this.loadingPaymentUrl = true;
    try {
      const res = await basicRequestGet(
        `/new_product/order/payment_url_fb/${order_id}/${product_id}/${product_param_id}`
      );
      console.log('---- ww|getPaymentUrl|res.data=', res.data);
      return res.data as PaymentOrderResponse;
    } catch (error) {
      console.error('getPaymentUrl|error=', error);
      throw error;
    } finally {
      this.loadingPaymentUrl = false;
    }
  }
}
