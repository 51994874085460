import { Modal, Spin, Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { UserParams } from '../../../DataSource/Stores/Admin/UserParams';
import styles from '../Admin.module.scss';

interface TabProps {
  items: TabsProps['items'];
  onEditTab: (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove'
  ) => void;
  onChangeParamType?: (key: string) => void;
  openModalDel: boolean;
  deleteTab: () => void;
  closeModalDel: () => void;
}

const Tab: React.FC<TabProps> = ({
  items,
  onEditTab,
  onChangeParamType,
  openModalDel,
  deleteTab,
  closeModalDel,
}) => {
  return (
    <div className={styles.table + ' mt-32'}>
      {UserParams.loadingParamTypesList ? (
        <div className={styles.form_wrp + ' center'}>
          <Spin />
        </div>
      ) : (
        <Tabs
          type="editable-card"
          defaultActiveKey={UserParams.paramTypeId}
          items={items}
          onEdit={onEditTab}
          onChange={onChangeParamType}
        />
      )}
      <Modal
        title="Modal"
        open={openModalDel}
        onOk={deleteTab}
        onCancel={closeModalDel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure to delete this tab?</p>
      </Modal>
    </div>
  );
};

export default observer(Tab);
