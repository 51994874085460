// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IXu9U_YrsEDQg6OSaXIK{display:flex;flex-direction:column;gap:32px;padding:0}.NPYCbWBAhpnriC16GYTJ{display:flex;align-items:center;gap:48px}.NPYCbWBAhpnriC16GYTJ .ROUgqkVHUVvlbcxJe1Wd{color:#1e1e1e;font-size:44px;font-weight:700;padding:16px 0}.IXu9U_YrsEDQg6OSaXIK td{font-size:18px}.thYM0QSB5EmoWvXSeJpT{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/Search/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,SAAA,CAEA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,4CACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,cAAA,CAIJ,yBACE,cAAA,CAIJ,sBACE,cAAA","sourcesContent":[".searchPage {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  padding: 0;\n\n  &Top {\n    display: flex;\n    align-items: center;\n    gap: 48px;\n\n    .title {\n      color: #1e1e1e;\n      font-size: 44px;\n      font-weight: 700;\n      padding: 16px 0;\n    }\n  }\n\n  td {\n    font-size: 18px;\n  }\n}\n\n.copyBtn {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchPage": `IXu9U_YrsEDQg6OSaXIK`,
	"searchPageTop": `NPYCbWBAhpnriC16GYTJ`,
	"title": `ROUgqkVHUVvlbcxJe1Wd`,
	"copyBtn": `thYM0QSB5EmoWvXSeJpT`
};
export default ___CSS_LOADER_EXPORT___;
