// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o_kVXIwfhcPc4CsMeOuT{width:100%;cursor:pointer;border:0;margin:14px 0;background:rgba(0,0,0,0)}.o_kVXIwfhcPc4CsMeOuT p{font-weight:400;font-size:15px;color:#000}.o_kVXIwfhcPc4CsMeOuT span{font-weight:400;font-size:14px;color:#878787}.o_kVXIwfhcPc4CsMeOuT .yPV3748zzsSibaedGnZA{display:flex;column-gap:16px;align-items:center}.o_kVXIwfhcPc4CsMeOuT .DcWszfExjdwXrqrEiUrj{justify-content:space-between}.X1nDGJiYcGTKBMwuGJfQ i{color:#000;font-size:20px}.sKc5R43AwoxXalpExNy8 p{text-align:left}.sKc5R43AwoxXalpExNy8 p i{margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/HistoryOrderItem/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,cAAA,CACA,QAAA,CACA,aAAA,CACA,wBAAA,CACA,wBACE,eAAA,CACA,cAAA,CACA,UAAA,CAEF,2BACE,eAAA,CACA,cAAA,CACA,aAAA,CAEF,4CACE,YAAA,CACA,eAAA,CACA,kBAAA,CAEF,4CACE,6BAAA,CAKF,wBACE,UAAA,CACA,cAAA,CAIF,wBACE,eAAA,CAEA,0BACE,gBAAA","sourcesContent":[".order_item {\n  width: 100%;\n  cursor: pointer;\n  border: 0;\n  margin: 14px 0;\n  background: transparent;\n  p {\n    font-weight: 400;\n    font-size: 15px;\n    color: #000;\n  }\n  span {\n    font-weight: 400;\n    font-size: 14px;\n    color: #878787;\n  }\n  .fl_block {\n    display: flex;\n    column-gap: 16px;\n    align-items: center;\n  }\n  .fl_beetw {\n    justify-content: space-between;\n  }\n}\n\n.but_bl {\n  i {\n    color: #000;\n    font-size: 20px;\n  }\n}\n.text_data {\n  p {\n    text-align: left;\n\n    i {\n      margin-right: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"order_item": `o_kVXIwfhcPc4CsMeOuT`,
	"fl_block": `yPV3748zzsSibaedGnZA`,
	"fl_beetw": `DcWszfExjdwXrqrEiUrj`,
	"but_bl": `X1nDGJiYcGTKBMwuGJfQ`,
	"text_data": `sKc5R43AwoxXalpExNy8`
};
export default ___CSS_LOADER_EXPORT___;
