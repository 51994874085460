import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Alert,
  Button,
  Form,
  type FormProps,
  Input,
  message,
  Modal,
  Select,
  TabsProps,
} from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import usePageTitle from '../../../DataSource/Hooks/usePageTitle';
import Lang from '../../Lang';
import styles from '../Admin.module.scss';
import InputTab from './InputTab';
import BaseParametersItem from './Item';
import Tab from './Tab';
import { BaseParamsClass } from '../../../DataSource/Stores/Admin/BaseParamsClass';
// import { Params } from '../../../DataSource/Stores/Admin/CategoriesParams';

interface Props {
  Params: BaseParamsClass;

  title: string;
}

export type CpFieldType = {
  field_name: string;
  field_type: string;
  param_type?: string;
  _id?: string;
};

export interface DataType {
  _id?: string;
  key: string;
  field_name: string;
  field_type: string;
  createdAt: string;
  // status: string;
}
export interface DataTypeMap {
  [key: string]: DataType[];
}
export interface LoadingMap {
  [key: string]: boolean;
}
/////testcomit222

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const BaseParameters: React.FC<Props> = ({ Params, title }) => {
  usePageTitle(title);
  const [dataSource, setDataSource] = useState(Params.dataList);

  useEffect(() => {
    setLoadError(false);
    getParameterTypesList();
  }, []);

  const getParametersList = () => {
    Params.getParametersList(Params.paramTypeId)
      .then(() => setDataSource(Params.dataList))
      .catch(() => setLoadError(true));
  };

  const getParameterTypesList = () => {
    Params.getParameterTypesList()
      .then(() => {
        setItems(
          // @ts-ignore
          Params.paramTypesList.map((i) => {
            // console.log('---- ww|paramTypesList|i=', i);
            return {
              key: i.key,
              label: <InputTab name={i.label} param_type_id={i.key} Params={Params} />,
              children: (
                <BaseParametersItem
                  showModal={showModal}
                  onDragEnd={onDragEnd}
                  Row={Row}
                  setIsModalOpen={setIsModalOpen}
                  Params={Params}
                />
              ),
            };
          })
        );
      })
      .then(() => {
        if (Params.paramTypesList?.length) {
          Params.paramTypeId = Params.paramTypesList[0].key;
        }
        return getParametersList();
      })
      .catch(() => setLoadError(true));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (over?.id && active.id !== over.id) {
      setDataSource((previous) => {
        // console.log('---- ww|onDragEnd|previous=', previous);
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        // console.log('---- ww|onDragEnd|activeIndex=', activeIndex);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        // console.log('---- ww|onDragEnd|overIndex=', overIndex);
        const dataList = arrayMove(previous, activeIndex, overIndex);
        // console.log('---- ww|onDragEnd|dataList=', dataList);
        const _tmpDataList = Params.dataList;
        Params.dataList = dataList;

        Params.changeSort(active.id, over?.id || null).catch(() => {
          Params.dataList = _tmpDataList;
          message.error('Error while changing sort item');
        });

        return dataList;
      });
    }
  };

  const [loadError, setLoadError] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef<any>(null);

  const initialItems: TabsProps['items'] = [];

  const handleCancel = () => {
    console.log('---- ww|handleCancel|');
    Params.paramData = null;
    formRef.current?.resetFields();
    setIsModalOpen(false);
  };

  const onChangeType = (value: string) => {
    console.log(`selected ${value}`);
    if (Params.paramData) {
      Params.paramData.field_type = value;
    }
  };

  const onFinish: FormProps<CpFieldType>['onFinish'] = async (values) => {
    // console.log('Success:', values);
    // console.log('Success|Params.paramData=', Params.paramData);
    try {
      Params.loadingList = true;
      message.loading('Loading');

      if (Params.paramData?._id) {
        values._id = Params.paramData?._id;
        Params.paramData = values as DataType;
        await Params.editType(values);
      } else {
        await Params.addType(values);
      }
      message.destroy();
    } catch (error: any) {
      console.error('signUp exception:error?.status=', error?.message);
      message.destroy();
      message.error('Error add parameter');
      return;
    } finally {
      Params.loadingList = false;
    }

    setLoadError(false);
    getParametersList();

    message.info('Success');
    setIsModalOpen(false);
    Params.paramData = null;
    formRef.current?.resetFields();
  };

  const onFinishFailed: FormProps<CpFieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChangeParamType = (key: string) => {
    console.log('onChangeProductParamType|key=', key);
    Params.paramTypeId = key;
    setLoadError(false);
    getParametersList();
  };

  const handleSubmit = () => {
    console.log('handleSubmit|');
    formRef.current?.submit();
  };

  const [items, setItems] = useState(initialItems);
  // const newTabIndex = useRef(0);

  const addTab = async () => {
    if (Params.addingTab) {
      message.destroy();
      message.info('Создания нового параметра еще в работе');
      return;
    }
    message.loading('Загрузка');
    //
    let newActiveKey;
    try {
      newActiveKey = await Params.addProductParam();
    } catch (error) {
      console.error('addTab|error=', error);
      message.destroy();
      message.error('Add new tab error');
      return;
    }
    message.destroy();

    const newPanes = [...items];
    newPanes.push({
      key: newActiveKey,
      label: <InputTab param_type_id={newActiveKey} Params={Params} />,
      children: (
        <BaseParametersItem
          showModal={showModal}
          onDragEnd={onDragEnd}
          Row={Row}
          setIsModalOpen={setIsModalOpen}
          Params={Params}
        />
      ),
    });
    setItems(newPanes);
    Params.paramTypeId = newActiveKey;
  };

  const [openModalDel, setOpenModalDel] = useState(false);
  const [deleteNewTab, setDeleteTab] = useState<false | TargetKey>(false);

  const showPopconfirm = () => {
    setOpenModalDel(true);
  };

  const closeModalDel = () => {
    setOpenModalDel(false);
    setDeleteTab(false);
  };

  const removeTab = async (productId: string) => {
    setOpenModalDel(false);

    message.loading('Loading');
    try {
      await Params.deleteProductParam(productId);
    } catch (error: any) {
      if (error?.message == 'Request failed with status code 409') {
        return message.error('Не возможно удалить. Тип содержит параметры ');
      }
      console.error('removeTab|error=', error);
      message.destroy();
      message.error('Ошибка при удалении типа параметров');
      return;
    }
    message.destroy();

    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === productId) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== productId);
    console.log(
      '---- ww|1|newActiveKey=',
      newPanes.length,
      {
        productId,
      },
      '--------',
      newPanes.length
    );
    if (newPanes.length && Params.paramTypeId === productId) {
      if (lastIndex >= 0) {
        Params.paramTypeId = newPanes[lastIndex].key;
        console.log('---- ww|1|lastIndex >= 0|newActiveKey=', Params.paramTypeId);
      } else {
        Params.paramTypeId = newPanes[0].key;
      }
    }

    setItems(newPanes);

    onChangeParamType(Params.paramTypeId);
  };

  const onEditTab = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove'
  ) => {
    if (action === 'add') {
      Params.visibInput = true;
      addTab();
    } else {
      setDeleteTab(targetKey);
      showPopconfirm();
    }
  };
  const deleteTab = () => {
    if (deleteNewTab) removeTab(deleteNewTab as string);
    setDeleteTab(false);
  };

  return (
    <div className="pt-80">
      <h1>{title}</h1>
      {loadError ? (
        <Alert
          message="Error loading data from the server. Please try again later."
          type="error"
          showIcon
          action={
            <Button
              size="small"
              onClick={() => {
                setLoadError(false);
                getParametersList();
              }}
            >
              Try Again
            </Button>
          }
        />
      ) : (
        <Tab
          items={items}
          onEditTab={onEditTab}
          onChangeParamType={onChangeParamType}
          openModalDel={openModalDel}
          deleteTab={deleteTab}
          closeModalDel={closeModalDel}
          Params={Params}
        />
      )}

      <Modal
        title={Lang({ str: 'challengeparameters', section: 'default' })}
        open={isModalOpen}
        className={styles.modalform}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" loading={Params.loadingList} onClick={handleSubmit}>
            Save
          </Button>,
          <Button key="link" type="primary" loading={Params.loadingList} onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <Form
          key={Date.now()} // Установите key с уникальным значением
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          initialValues={Params.paramData || {}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          ref={formRef} // Привязываем ссылку на форму
        >
          <Form.Item<CpFieldType>
            label="Field name"
            name="field_name"
            rules={[{ required: true, message: 'Please input Field name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<CpFieldType>
            label="Field type"
            name="field_type"
            rules={[{ required: true, message: 'Please input Field type!' }]}
          >
            <Select
              onChange={onChangeType}
              options={[
                { value: 'text', label: 'text' },
                { value: 'number', label: 'number' },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default observer(BaseParameters);
