import { DeleteOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { message, Popconfirm, Table, TableProps } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { UserParams } from '../../../DataSource/Stores/Admin/UserParams';
import Lang from '../../Lang';
import { DataType } from './index';

interface UserParametersItemProps {
  Row: any;
  onDragEnd: ({ active, over }: DragEndEvent) => void;
  showModal: () => void;
  x?: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Item: React.FC<UserParametersItemProps> = ({
  showModal,
  onDragEnd,
  Row,
  x,
  setIsModalOpen,
}) => {
  // Функция для обработки клика на поле field_name
  const handleFieldNameClick = (record: DataType, column: DataType) => {
    // console.log('Clicked on column.key:', column);
    // console.log('Clicked on record:', record as DataType);
    if (column.key == 'field_name') {
      UserParams.paramData = record;
      setIsModalOpen(true);
    }
  };

  const handleDelete = (key: React.Key) => {
    console.log('---- handleDelete|key=', key);
    message.loading('Loading');
    UserParams.deleteParam(key as string)
      .then(() => {
        message.destroy();
        message.info('Success');
      })
      .catch((error) => {
        message.destroy();
        if (error?.message == 'Request failed with status code 409') {
          return message.error('Parameter in use');
        }
        message.error('Error');
      });
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      key: 'sort',
    },
    {
      title: 'Field name',
      dataIndex: 'field_name',
      key: 'field_name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Field type',
      dataIndex: 'field_type',
      key: 'field_type',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Delete',
      dataIndex: 'operation',
      render: (_, record) => (
        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
          <DeleteOutlined className="icon_bl" />
        </Popconfirm>
      ),
    },

    // {
    //   title: 'Status',
    //   key: 'status',
    //   dataIndex: 'status',
    //   render: (_, { status }) => {
    //     const color: StatusColorMap = {
    //       verified: 'green',
    //       block: 'volcano',
    //     };
    //     return <Tag color={color[status]}>{status.toUpperCase()}</Tag>;
    //   },
    // },
  ];

  return (
    <>
      <div className="d_flex_but">
        <button onClick={showModal}>
          <svg
            width="14"
            height="13"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.3334 7H9.33337V1C9.33337 0.447719 8.88566 0 8.33337 0C7.78109 0 7.33337 0.447719 7.33337 1V7H1.33337C0.781093 7 0.333374 7.44772 0.333374 8C0.333374 8.55228 0.781093 9 1.33337 9H7.33337V15C7.33337 15.5523 7.78109 16 8.33337 16C8.88566 16 9.33337 15.5523 9.33337 15V9H15.3334C15.8857 9 16.3334 8.55228 16.3334 8C16.3334 7.44772 15.8857 7 15.3334 7Z"
              fill="#000000"
            />
          </svg>
          {Lang({ str: 'add', section: 'default' })}
        </button>
      </div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={UserParams.dataList.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            scroll={x ? { x } : undefined}
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={columns.map((column) => ({
              ...column,
              onCell: (record: DataType) => ({
                onClick: () => handleFieldNameClick(record, column as DataType),
              }),
            }))}
            dataSource={UserParams.dataList}
            loading={UserParams.loadingList}
          />
        </SortableContext>
      </DndContext>
    </>
  );
};

export default observer(Item);
