import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Alert, Button, Dropdown, MenuProps, message, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import usePageTitle from '../../../DataSource/Hooks/usePageTitle';
// import { ProductListStore } from '../../../DataSource/Stores/Admin/ProductListStore';
// import { ProductParams } from '../../../DataSource/Stores/Admin/ProductParams';
import Lang from '../../Lang';
import ModalForm, { CpLsListFieldType } from './Modal';
import { BaseEntityStoreActionsClass } from '../../../DataSource/Stores/Admin/BaseEntityStoreActionsClass';
import { BaseParamsClass } from '../../../DataSource/Stores/Admin/BaseParamsClass';
import { EventEmitter } from 'events';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface Props {
  EntityStore: BaseEntityStoreActionsClass;
  EntityParams: BaseParamsClass;

  columns: ColumnsType<CpLsListFieldType>;

  title: string;

  eventEmitter: EventEmitter;
  actions: MenuProps;
  manualAddition: boolean;
  needSourceId: boolean;
  needCities: boolean;
}

const ProductList: React.FC<Props> = ({
  title,
  EntityStore,
  EntityParams,
  columns,
  eventEmitter,
  actions,
  manualAddition,
  needSourceId,
  needCities,
}) => {
  usePageTitle(title);

  const [init, setInit] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState(EntityStore.dataList);
  useEffect(() => {
    if (init) return;
    setInit(true);
    refreshList();
  }, []);

  const refreshList = () => {
    EntityStore.getList().then(() => setDataSource(EntityStore.dataList));
    EntityParams.getParameterTypesList(); //.then(() => setDataSource(EntityStore.dataList));
  };

  useEffect(() => {
    const onDelete = (key: React.Key) => {
      handleDelete(key);
    };

    eventEmitter.on('deleteEntity', onDelete);

    // Чистим подписку при размонтировании компонента
    return () => {
      eventEmitter.off('deleteEntity', onDelete);
    };
  }, []);
  useEffect(() => {
    const onImport = () => {
      handleImport();
    };

    eventEmitter.on('importAction', onImport);

    // Чистим подписку при размонтировании компонента
    return () => {
      eventEmitter.off('importAction', onImport);
    };
  }, []);
  useEffect(() => {
    const onSync = () => {
      handleSync();
    };

    eventEmitter.on('sync', onSync);

    // Чистим подписку при размонтировании компонента
    return () => {
      eventEmitter.off('sync', onSync);
    };
  }, []);
  useEffect(() => {
    const onSyncStopLists = () => {
      handleSyncStopLists();
    };

    eventEmitter.on('syncStopLists', onSyncStopLists);

    // Чистим подписку при размонтировании компонента
    return () => {
      eventEmitter.off('syncStopLists', onSyncStopLists);
    };
  }, []);
  useEffect(() => {
    const onChangeActive = (key: React.Key, active: boolean) => {
      handleChangeActive(key, active);
    };

    eventEmitter.on('changeActiveEntity', onChangeActive);

    // Чистим подписку при размонтировании компонента
    return () => {
      eventEmitter.off('changeActiveEntity', onChangeActive);
    };
  }, []);

  /**
   * Функция для обработки клика на поле field_name
   * @param record
   * @param column
   */
  const handleFieldNameClick = async (record: CpLsListFieldType, column: CpLsListFieldType) => {
    console.log('Clicked on column.key:', column);
    console.log('Clicked on record:', record as CpLsListFieldType);
    if (column.key == 'name') {
      message.loading('Loading');
      try {
        await EntityStore.get(record);
      } catch (error) {
        console.error('Clicked on column.key|error=', error);
        message.destroy();
        message.error('Error get challenge data');
        return;
      }
      EntityStore.isModalOpen = true;
      message.destroy();
    }
  };

  const handleChangeActive = (key: React.Key, active: boolean) => {
    console.log('---- handleChangeActive|key=', key);
    console.log('---- handleChangeActive|active=', active);

    // return;
    message.loading('Loading');
    EntityStore.changeActive(key as string, active)
      .then(() => {
        message.destroy();
        message.info('Success');
      })
      .catch((error) => {
        message.destroy();
        if (error?.message == 'Request failed with status code 409') {
          return message.error('Product in use');
        }
        message.error('Error');
      });
  };

  const handleDelete = (key: React.Key) => {
    console.log('---- handleDelete|key=', key);
    message.loading('Loading');
    EntityStore.delete(key as string)
      .then(() => {
        message.destroy();
        message.info('Success');
      })
      .catch((error) => {
        message.destroy();
        if (error?.message == 'Request failed with status code 409') {
          return message.error('Product in use');
        }
        message.error('Error');
      });
  };
  const handleImport = () => {
    message.loading('Loading');
    EntityStore.import()
      .then(() => {
        message.destroy();
        message.info('Success');
        refreshList();
      })
      .catch((error) => {
        message.destroy();
        if (error?.message == 'Request failed with status code 409') {
          return message.error('Product in use');
        }
        message.error('Error');
        refreshList();
      });
  };

  const handleSync = () => {
    message.loading('Loading');
    EntityStore.sync()
      .then(() => {
        message.destroy();
        message.info('Success');
        refreshList();
      })
      .catch((error) => {
        message.destroy();
        if (error?.message == 'Request failed with status code 409') {
          return message.error('Product in use');
        }
        message.error('Error');
        refreshList();
      });
  };
  const handleSyncStopLists = () => {
    message.loading('Loading');
    EntityStore.handleSyncStopLists()
      .then(() => {
        message.destroy();
        message.info('Success');
        refreshList();
      })
      .catch((error) => {
        message.destroy();
        if (error?.message == 'Request failed with status code 409') {
          return message.error('Product in use');
        }
        message.error('Error');
        refreshList();
      });
  };
  // const handleSyncHard = () => {
  //   message.loading('Loading');
  //   EntityStore.syncHard()
  //     .then(() => {
  //       message.destroy();
  //       message.info('Success');
  //       refreshList();
  //     })
  //     .catch((error) => {
  //       message.destroy();
  //       if (error?.message == 'Request failed with status code 409') {
  //         return message.error('Product in use');
  //       }
  //       message.error('Error');
  //       refreshList();
  //     });
  // };

  const handleRetry = () => {
    EntityStore.getList(); // Повторная попытка загрузки данных
  };

  const showModal = () => {
    EntityStore.isModalOpen = true;
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (over?.id && active.id !== over.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        const dataList = arrayMove(previous, activeIndex, overIndex);
        const _tmpDataList = EntityStore.dataList;
        EntityStore.dataList = dataList;

        EntityStore.changeSort(active.id, over?.id || null).catch(() => {
          EntityStore.dataList = _tmpDataList;
          message.error('Error while changing sort item');
        });
        return dataList;
      });
    }
  };

  return (
    <div className="pt-80">
      <h1>{title}</h1>
      <div className="d_flex_but">
        <Dropdown menu={actions}>
          <Button>
            <Space>
              {Lang({ str: 'runAction', section: 'default' })}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        {manualAddition ? (
          <button onClick={showModal}>
            <svg
              width="14"
              height="13"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3334 7H9.33337V1C9.33337 0.447719 8.88566 0 8.33337 0C7.78109 0 7.33337 0.447719 7.33337 1V7H1.33337C0.781093 7 0.333374 7.44772 0.333374 8C0.333374 8.55228 0.781093 9 1.33337 9H7.33337V15C7.33337 15.5523 7.78109 16 8.33337 16C8.88566 16 9.33337 15.5523 9.33337 15V9H15.3334C15.8857 9 16.3334 8.55228 16.3334 8C16.3334 7.44772 15.8857 7 15.3334 7Z"
                fill="#000000"
              />
            </svg>
            {Lang({ str: 'add', section: 'default' })}
          </button>
        ) : (
          <></>
        )}
      </div>
      {EntityStore.loadingError ? (
        <Alert
          message="Error loading data from the server. Please try again later."
          type="error"
          showIcon
          action={
            <Button size="small" onClick={handleRetry}>
              Try Again
            </Button>
          }
        />
      ) : (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={EntityStore.dataList.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              scroll={{ x: 1100 }}
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="key"
              columns={columns.map((column) => ({
                ...column,
                onCell: (record: CpLsListFieldType) => ({
                  onClick: () => handleFieldNameClick(record, column as CpLsListFieldType),
                }),
              }))}
              dataSource={EntityStore.dataList}
              loading={EntityStore.loading}
            />
          </SortableContext>
        </DndContext>
      )}

      <ModalForm
        EntityStore={EntityStore}
        EntityParams={EntityParams}
        needSourceId={needSourceId}
        needCities={needCities}
      />
    </div>
  );
};

export default observer(ProductList);
