import React, { useEffect, useState } from 'react';
import usePageTitle from '../../../DataSource/Hooks/usePageTitle';
import Lang from '../../Lang';
import { Alert, Badge, Button, Input, message, Popover, Space } from 'antd';

import { observer } from 'mobx-react';
import { PresetStatusColorType } from 'antd/es/_util/colors';
import { basicRequestGet } from '../../../DataSource/Requests/basicRequestGet';
import { basicRequestPost } from '../../../DataSource/Requests/basicRequestPost';

const IikoIntegration: React.FC = () => {
  usePageTitle(Lang({ str: 'iikoIntegration', section: 'default' }));
  const [loadError, setLoadError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>('');
  const [popoevrContent, setPopoevrContent] = useState<string>('');
  const [apiKeyStatus, setApiKeyStatus] = useState<PresetStatusColorType>('default'); //success, error, default, processing
  const [messageApi, contextHolder] = message.useMessage();
  const statuses: { [key: string]: string } = {
    wait: 'default',
    active: 'success',
    error: 'error',
  };
  useEffect(() => {
    setLoadError(false);
    getIikoIntegrationData();
  }, []);

  const getIikoIntegrationData = async () => {
    try {
      const res = await basicRequestGet('/admin/iiko');

      console.log(res);

      if (res?.data?.data?.apiKey) {
        const { apiKey, errorMessage, status } = res.data.data;

        // const status = 'error';

        setApiKey(apiKey);
        setApiKeyStatus(statuses[status] as PresetStatusColorType);
        if (status === 'error') {
          console.log(errorMessage);
          setPopoevrContent(errorMessage);
        }
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const error = (msg: string) => {
    setLoading(false);
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };
  const onChangeApiKey = (e: any) => {
    // console.log(e);
    setApiKey(e.target.value);
  };
  const saveApiKey = async () => {
    setLoading(true);
    setPopoevrContent('');
    if (apiKey.length === 0) {
      return error('Api key is empty!');
    }
    setApiKeyStatus('processing');
    try {
      const res = await basicRequestPost('/admin/iiko', { apiKey });
      console.log('---- ww|saveApiKey|res=', res.data.data);
      // return res.data.data as string;

      await getIikoIntegrationData();
    } catch (error) {
      console.error('saveApiKey|error=', error);
      await getIikoIntegrationData();
      throw error;
    }
  };

  return (
    <div className="pt-80">
      <h1>{Lang({ str: 'iikoIntegration', section: 'default' })}</h1>
      {contextHolder}
      {loadError ? (
        <Alert
          message="Error loading data from the server. Please try again later."
          type="error"
          showIcon
          action={
            <Button
              size="small"
              onClick={() => {
                setLoadError(false);
              }}
            >
              Try Again
            </Button>
          }
        />
      ) : (
        <div>
          <div>
            <label htmlFor={'apiKey'}>Api Key</label>
          </div>
          <Space.Compact>
            <Input
              id={'apiKey'}
              addonBefore={
                <Popover content={popoevrContent}>
                  <Badge status={apiKeyStatus} />
                </Popover>
              }
              addonAfter={
                <Button onClick={saveApiKey} loading={loading} type={'text'}>
                  Ok
                </Button>
              }
              value={apiKey}
              onChange={onChangeApiKey}
            />
          </Space.Compact>
        </div>
      )}
    </div>
  );
};

export default observer(IikoIntegration);
