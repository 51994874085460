import type { GetProp, MenuProps } from 'antd';
import { Menu } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Lang from '../Lang/index';

type MenuTheme = GetProp<MenuProps, 'theme'>;
type MenuItem = GetProp<MenuProps, 'items'>[number];

const UsersIcon = () => (
  <svg
    id="Layer_1"
    height="18"
    viewBox="0 0 24 24"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
  >
    <path d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 17v-.5a7.5 7.5 0 0 0 -15 0v.5a1 1 0 0 0 2 0v-.5a5.5 5.5 0 0 1 11 0v.5a1 1 0 0 0 2 0zm9-5a7 7 0 0 0 -11.667-5.217 1 1 0 1 0 1.334 1.49 5 5 0 0 1 8.333 3.727 1 1 0 0 0 2 0zm-6.5-9a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5z" />
  </svg>
);

const UsersPar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M9,12c3.309,0,6-2.691,6-6S12.309,0,9,0,3,2.691,3,6s2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm1.75,14.22c-.568-.146-1.157-.22-1.75-.22-3.86,0-7,3.14-7,7,0,.552-.448,1-1,1s-1-.448-1-1c0-4.962,4.038-9,9-9,.762,0,1.519,.095,2.25,.284,.535,.138,.856,.683,.719,1.218-.137,.535-.68,.856-1.218,.719Zm12.371-4.341c-1.134-1.134-3.11-1.134-4.243,0l-6.707,6.707c-.755,.755-1.172,1.76-1.172,2.829v1.586c0,.552,.448,1,1,1h1.586c1.069,0,2.073-.417,2.828-1.172l6.707-6.707c.567-.567,.879-1.32,.879-2.122s-.312-1.555-.878-2.121Zm-1.415,2.828l-6.708,6.707c-.377,.378-.879,.586-1.414,.586h-.586v-.586c0-.534,.208-1.036,.586-1.414l6.708-6.707c.377-.378,1.036-.378,1.414,0,.189,.188,.293,.439,.293,.707s-.104,.518-.293,.707Z" />
  </svg>
);

const OrderList = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z" />
    <path d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" />
    <path d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" />
    <circle cx="2" cy="5" r="2" />
    <circle cx="2" cy="12" r="2" />
    <circle cx="2" cy="19" r="2" />
  </svg>
);

const ProductList = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m4 6a2.982 2.982 0 0 1 -2.122-.879l-1.544-1.374a1 1 0 0 1 1.332-1.494l1.585 1.414a1 1 0 0 0 1.456.04l3.604-3.431a1 1 0 0 1 1.378 1.448l-3.589 3.414a2.964 2.964 0 0 1 -2.1.862zm20-2a1 1 0 0 0 -1-1h-10a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1zm-17.9 9.138 3.589-3.414a1 1 0 1 0 -1.378-1.448l-3.6 3.431a1.023 1.023 0 0 1 -1.414 0l-1.59-1.585a1 1 0 0 0 -1.414 1.414l1.585 1.585a3 3 0 0 0 4.226.017zm17.9-1.138a1 1 0 0 0 -1-1h-10a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1zm-17.9 9.138 3.585-3.414a1 1 0 1 0 -1.378-1.448l-3.6 3.431a1 1 0 0 1 -1.456-.04l-1.585-1.414a1 1 0 0 0 -1.332 1.494l1.544 1.374a3 3 0 0 0 4.226.017zm17.9-1.138a1 1 0 0 0 -1-1h-10a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1z" />
  </svg>
);
const CalendarOutlined = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M1,4.75H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2ZM7.333,2a1.75,1.75,0,1,1-1.75,1.75A1.752,1.752,0,0,1,7.333,2Z" />
    <path d="M23,11H20.264a3.727,3.727,0,0,0-7.194,0H1a1,1,0,0,0,0,2H13.07a3.727,3.727,0,0,0,7.194,0H23a1,1,0,0,0,0-2Zm-6.333,2.75A1.75,1.75,0,1,1,18.417,12,1.752,1.752,0,0,1,16.667,13.75Z" />
    <path d="M23,19.25H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2ZM7.333,22a1.75,1.75,0,1,1,1.75-1.75A1.753,1.753,0,0,1,7.333,22Z" />
  </svg>
);

const MenuAdmin: React.FC = () => {
  const menuItemsData: { label: string; path: string; icon?: React.ReactNode; type?: 'divider' }[] =
    [
      {
        label: Lang({ str: 'users', section: 'default' }),
        path: '/admin/users',
        icon: <UsersIcon />,
      },
      {
        label: Lang({ str: 'usersparameters', section: 'default' }),
        path: '/admin/users_parameters',
        icon: <UsersPar />,
      },
      {
        label: Lang({ str: 'challengelist', section: 'default' }),
        path: '/admin/product_list',
        icon: <ProductList />,
      },
      {
        label: Lang({ str: 'challengeparameters', section: 'default' }),
        path: '/admin/product_parameters',
        icon: <CalendarOutlined />,
      },

      {
        label: Lang({ str: 'orderslist', section: 'default' }),
        path: '/admin/orders_list',
        icon: <OrderList />,
      },
      {
        label: Lang({ str: 'ordersparameters', section: 'default' }),
        path: '/admin/orders_parameters',
        icon: <CalendarOutlined />,
      },
      {
        label: '', // Это чисто чтоб визуально разделить
        path: '/admin/',
        type: 'divider',
      },
      {
        label: Lang({ str: 'iikoIntegration', section: 'default' }),
        path: '/admin/iikoIntegration',
        icon: <></>,
      },
      {
        label: Lang({ str: 'cities', section: 'default' }),
        path: '/admin/cities',
        icon: <ProductList />,
      },
      {
        label: Lang({ str: 'citiesParameters', section: 'default' }),
        path: '/admin/cities_parameters',
        icon: <CalendarOutlined />,
      },
      {
        label: Lang({ str: 'organizations', section: 'default' }),
        path: '/admin/organizations',
        icon: <ProductList />,
      },
      {
        label: Lang({ str: 'organizationsParameters', section: 'default' }),
        path: '/admin/organizations_parameters',
        icon: <CalendarOutlined />,
      },
      {
        label: Lang({ str: 'groups', section: 'default' }),
        path: '/admin/groups',
        icon: <ProductList />,
      },
      {
        label: Lang({ str: 'groupsParameters', section: 'default' }),
        path: '/admin/groups_parameters',
        icon: <CalendarOutlined />,
      },
      {
        label: Lang({ str: 'categories', section: 'default' }),
        path: '/admin/categories',
        icon: <ProductList />,
      },
      {
        label: Lang({ str: 'categoriesParameters', section: 'default' }),
        path: '/admin/categories_parameters',
        icon: <CalendarOutlined />,
      },
      {
        label: Lang({ str: 'products', section: 'default' }),
        path: '/admin/products',
        icon: <ProductList />,
      },
      {
        label: Lang({ str: 'productsParameters', section: 'default' }),
        path: '/admin/products_parameters',
        icon: <CalendarOutlined />,
      },
      {
        label: Lang({ str: 'modifiers', section: 'default' }),
        path: '/admin/modifiers',
        icon: <ProductList />,
      },
      {
        label: Lang({ str: 'modifiersParameters', section: 'default' }),
        path: '/admin/modifiers_parameters',
        icon: <CalendarOutlined />,
      },
      {
        label: Lang({ str: 'orders', section: 'default' }),
        path: '/admin/orders',
        icon: <ProductList />,
      },
      {
        label: Lang({ str: 'ordersParameters', section: 'default' }),
        path: '/admin/orders_parametersV2',
        icon: <CalendarOutlined />,
      },
    ];

  const [mode, setMode] = useState<'vertical' | 'inline'>('inline');
  const [theme, setTheme] = useState<MenuTheme>('light');
  const location = useLocation();

  const defaultSelectedKey =
    menuItemsData.find((item) => location.pathname.includes(item.path))?.path ||
    menuItemsData[0].path;

  const menuItems: MenuItem[] = menuItemsData.map(({ label, path: key, icon }) => ({
    key,
    icon,
    label: <Link to={key}>{label}</Link>,
  }));

  return (
    <Menu defaultSelectedKeys={[defaultSelectedKey]} mode={mode} theme={theme} items={menuItems} />
  );
};

export default observer(MenuAdmin);
