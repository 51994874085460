import { animated, useSpring } from '@react-spring/web';
import type { FormProps } from 'antd';
import { Button, Form, Input, Modal, Select } from 'antd';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { ManagerStore } from '../../../../../../DataSource/Stores/ManagerStore';
import ButtonGray from '../../../../../ui/Button';
import Icon from '../../../../../ui/Icon';
import HistoryOrderItem from '../../../HistoryOrderItem';
import StatusTitle from '../../../StatusTitle';
import styles from './index.module.scss';

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
  monday?: string;
};
const { TextArea } = Input;

const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
  console.log('Success:', values);
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

const ModalOrder: React.FC = () => {
  const viewJurnal = () => {
    ManagerStore.setLayoutPage('jurnal');
  };

  const comeBack = () => {
    ManagerStore.setLayoutPage('library');
  };

  const wrapRef = useRef<HTMLDivElement>(null);
  const widthnew: any = wrapRef?.current?.clientWidth;
  const { x } = useSpring({
    x: ManagerStore.layoutPage === 'library' ? 0 : -widthnew,
  });

  const handleOk = () => {
    ManagerStore.modalOpenOrderItem = false;
    ManagerStore.setLayoutPage('library');
  };
  const handleCancel = () => {
    ManagerStore.modalOpenOrderItem = false;
    ManagerStore.setLayoutPage('library');
  };

  return (
    <Modal
      title={'заказ'}
      closeIcon={<Icon slug="fi-rr-cross-small" />}
      className={styles.modal}
      open={ManagerStore.modalOpenOrderItem}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Button onClick={viewJurnal} className={styles.but_history}>
        <Icon slug={'fi-rr-completed'} />
      </Button>
      <div className={styles.moda_cont}>
        <p className={styles.moda_cont__title}>
          <b>#00238049</b>
        </p>

        <div className={styles.couriers_wrap} ref={wrapRef}>
          <animated.div style={{ x }} className="plib-myprompts-viewlist">
            <div className={styles.couriers_flex}>
              <div className={styles.child_flex}>
                <StatusTitle title="в доставке" theme="work" />
                <Form
                  name="basic"
                  layout="vertical"
                  onFinish={onFinish}
                  requiredMark={false}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <div className={styles.one_block}>
                    <p className={styles.one_tit}>Маршрут</p>
                    <div className={styles.map_blocl}>тут карта</div>

                    <div className={styles.info_order}>
                      <div className="d_flex">
                        <div className={styles.info_order__item}>
                          <p>Время доставки</p>
                          <span>~14:05</span>
                        </div>
                        <div className={styles.info_order__item}>
                          <p>Дальность</p>
                          <span>12км</span>
                        </div>
                      </div>
                      <div className="d_flex">
                        <div className={styles.info_order__item}>
                          <p>Курьер</p>
                          <span>~14:05</span>
                        </div>
                        <div className={styles.info_order__item}>
                          <p>Маршрут</p>
                          <b>
                            <Icon slug={'fi fi-rr-sort-alt'} />
                            Склад 1 ул. Майлина
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.one_block}>
                    <p className={styles.one_tit}>Информация о заказе</p>
                    <div className={styles.flex_input}>
                      <Form.Item<FieldType>
                        label="Способ оплаты"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                      >
                        <Select
                          defaultValue="Активен"
                          onChange={handleChange}
                          options={[
                            { value: 'Активен', label: 'Активен' },
                            { value: 'Не активен', label: 'Не активен' },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item<FieldType>
                        label="Сумма"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                      >
                        <Input placeholder="8200" />
                      </Form.Item>
                    </div>

                    <Form.Item<FieldType>
                      label="комментарий"
                      name="username"
                      rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                      <TextArea rows={2} placeholder="Заполните" />
                    </Form.Item>
                    <Form.Item<FieldType>
                      label="Содержание"
                      name="username"
                      rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                      <TextArea rows={2} placeholder="Заполните" />
                    </Form.Item>
                  </div>

                  <div className="d_flex grp_8">
                    <ButtonGray
                      variant={'text'}
                      //style={{ width: '50%' }}
                    >
                      Изменить статус
                    </ButtonGray>
                    <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                      Сохранить
                    </Button>
                  </div>
                </Form>
              </div>

              <div className={styles.child_flex}>
                <div className={styles.prompts_create}>
                  <div className={styles.flex_just}>
                    <div className={styles.flex_bb + ' mt-16'}>
                      <ButtonGray variant="outlined" onClick={comeBack}>
                        <Icon slug={'fi-rr-arrow-left'} />
                      </ButtonGray>
                      <p>История изменений</p>
                    </div>
                    <Select
                      defaultValue="Статус"
                      onChange={handleChange}
                      options={[
                        { value: 'Активен', label: 'Активен' },
                        { value: 'Не активен', label: 'Не активен' },
                      ]}
                    />
                  </div>
                  <HistoryOrderItem />
                  <HistoryOrderItem />
                </div>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
    </Modal>
  );
};
export default observer(ModalOrder);
