// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._B_fp7CfbsIkPvi0Aq6n{width:fit-content;background-color:#e2e5e9;border-radius:8px;display:flex;flex-direction:column;gap:12px;padding:12px}._B_fp7CfbsIkPvi0Aq6n .MGX3j6PLRwhCh3NfvUxQ{height:42px;width:42px;background-color:#bfc6cf;border:none;border-radius:8px}._B_fp7CfbsIkPvi0Aq6n .MGX3j6PLRwhCh3NfvUxQ.WqbXWCZTwmfvykFCXbPo{background-color:#f1b729}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/Nav/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,YAAA,CAEA,4CACE,WAAA,CACA,UAAA,CACA,wBAAA,CACA,WAAA,CACA,iBAAA,CAEA,iEACE,wBAAA","sourcesContent":[".nav {\n  width: fit-content;\n  background-color: #e2e5e9;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  padding: 12px;\n\n  .navBtn {\n    height: 42px;\n    width: 42px;\n    background-color: #bfc6cf;\n    border: none;\n    border-radius: 8px;\n\n    &.active {\n      background-color: #f1b729;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `_B_fp7CfbsIkPvi0Aq6n`,
	"navBtn": `MGX3j6PLRwhCh3NfvUxQ`,
	"active": `WqbXWCZTwmfvykFCXbPo`
};
export default ___CSS_LOADER_EXPORT___;
