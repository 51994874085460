import { Dropdown } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { observer } from 'mobx-react';
import Icon from '../../../../ui/Icon';
import DropBox from './DropBox';
import styles from './index.module.scss';

const OrderItem: React.FC = () => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a className={styles.drp_menu}>
          <Icon slug={'fi-rr-screen-share'} /> На доставку
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a className={styles.drp_menu} href="/profile">
          <Icon slug={'fi-rr-pen-field'} />
          Редактировать
        </a>
      ),
    },
  ];

  return (
    <div className={styles.order_item}>
      <div className={styles.flex_just}>
        <div className={styles.order_item__top}>
          <p>10км</p>
          <p>2100₸</p>
          <p>КЛИЕНТ</p>
        </div>
        <Dropdown className={styles.menuprogile} trigger={['click']} menu={{ items }}>
          <a className={styles.but_button} onClick={(e) => e.preventDefault()}>
            <Icon slug={'fi-rr-menu-dots'} />
          </a>
        </Dropdown>
      </div>
      <div className={styles.select_wrp}>
        <p className={styles.text_b}>#00238049</p>
      </div>

      <DropBox />

      <div className={styles.flex_just}>
        <p>
          <Icon className="ui-icon-button-icon" slug={'fi-rr-marker'} /> Склад 1
        </p>
        <p>
          ул. Майлина, 17
          <Icon className="ml-8 ui-icon-button-icon" slug={'fi-rr-location-crosshairs'} />
        </p>
      </div>
      <span className={styles.text_gri}>в ожидании 5 минут</span>
    </div>
  );
};

export default observer(OrderItem);
