// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xKUHBK9CWvdjdcZyBnz2{width:360px;background-color:#fff;border:1px solid #e0e0e0;border-radius:20px;box-shadow:0 2px 4px 0 rgba(0,0,0,.1490196078);display:flex;flex-direction:column;gap:32px;padding:32px}.xKUHBK9CWvdjdcZyBnz2 .HdEiQMVtV2Y1LhQ5suB9{color:#2a3038;font-size:24px;font-weight:500}`, "",{"version":3,"sources":["webpack://./src/modals/WorkspaceAddMember/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,qBAAA,CACA,wBAAA,CACA,kBAAA,CACA,8CAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,YAAA,CAEA,4CACE,aAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".addMemberModal {\n  width: 360px;\n  background-color: white;\n  border: 1px solid #E0E0E0;\n  border-radius: 20px;\n  box-shadow: 0 2px 4px 0 #00000026;\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  padding: 32px;\n\n  .title {\n    color: #2A3038;\n    font-size: 24px;\n    font-weight: 500;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addMemberModal": `xKUHBK9CWvdjdcZyBnz2`,
	"title": `HdEiQMVtV2Y1LhQ5suB9`
};
export default ___CSS_LOADER_EXPORT___;
