import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Alert, Button, message, Popconfirm, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import usePageTitle from '../../../DataSource/Hooks/usePageTitle';
import { ProductListStore } from '../../../DataSource/Stores/Admin/ProductListStore';
import { ProductParams } from '../../../DataSource/Stores/Admin/ProductParams';
import Lang from '../../Lang';
import ModalForm, { CpLsListFieldType } from './Modal';

interface StatusColorMap {
  [key: string]: string;
}

const handleDelete = (key: React.Key) => {
  console.log('---- handleDelete|key=', key);
  message.loading('Loading');
  ProductListStore.deleteProduct(key as string)
    .then(() => {
      message.destroy();
      message.info('Success');
    })
    .catch((error) => {
      message.destroy();
      if (error?.message == 'Request failed with status code 409') {
        return message.error('Product in use');
      }
      message.error('Error');
    });
};

const columns: ColumnsType<CpLsListFieldType> = [
  {
    dataIndex: 'numb',
    key: 'numb',
  },
  {
    key: 'sort',
  },
  {
    title: 'Product Name',
    dataIndex: 'product_name',
    key: 'product_name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Product Type',
    dataIndex: 'type_param_id',
    key: 'type_param_id',
    render: (text) => <a>{text}</a>,
  },

  {
    title: 'Status',
    key: 'status_param_id',
    dataIndex: 'status_param_id',
    render: (_, { status_param_id }) => {
      const color: StatusColorMap = {
        verified: 'green',
        block: 'volcano',
      };
      return <Tag color={color[status_param_id]}>{status_param_id?.toUpperCase()}</Tag>;
    },
  },
  {
    title: 'Delete',
    dataIndex: 'operation',
    render: (_, record) => (
      <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
        <DeleteOutlined className="icon_bl" />
      </Popconfirm>
    ),
  },
];

/**
 * Функция для обработки клика на поле field_name
 * @param record
 * @param column
 */
const handleFieldNameClick = async (record: CpLsListFieldType, column: CpLsListFieldType) => {
  console.log('Clicked on column.key:', column);
  console.log('Clicked on record:', record as CpLsListFieldType);
  if (column.key == 'product_name') {
    message.loading('Loading');
    try {
      await ProductListStore.getProduct(record);
    } catch (error) {
      console.error('Clicked on column.key|error=', error);
      message.destroy();
      message.error('Error get challenge data');
      return;
    }
    ProductListStore.isModalOpen = true;
    message.destroy();
  }
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ProductList: React.FC = () => {
  usePageTitle(Lang({ str: 'challengelist', section: 'default' }));

  const [init, setInit] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState(ProductListStore.dataList);
  useEffect(() => {
    if (init) return;
    setInit(true);

    ProductListStore.getList().then(() => setDataSource(ProductListStore.dataList));
    ProductParams.getParameterTypesList(); //.then(() => setDataSource(ProductListStore.dataList));
  }, []);

  const handleRetry = () => {
    ProductListStore.getList(); // Повторная попытка загрузки данных
  };

  const showModal = () => {
    ProductListStore.isModalOpen = true;
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (over?.id && active.id !== over.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        const dataList = arrayMove(previous, activeIndex, overIndex);
        const _tmpDataList = ProductListStore.dataList;
        ProductListStore.dataList = dataList;

        ProductListStore.changeSort(active.id, over?.id || null).catch(() => {
          ProductListStore.dataList = _tmpDataList;
          message.error('Error while changing sort item');
        });
        return dataList;
      });
    }
  };

  return (
    <div className="pt-80">
      <h1>{Lang({ str: 'challengelist', section: 'default' })}</h1>
      <div className="d_flex_but">
        <button onClick={showModal}>
          <svg
            width="14"
            height="13"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.3334 7H9.33337V1C9.33337 0.447719 8.88566 0 8.33337 0C7.78109 0 7.33337 0.447719 7.33337 1V7H1.33337C0.781093 7 0.333374 7.44772 0.333374 8C0.333374 8.55228 0.781093 9 1.33337 9H7.33337V15C7.33337 15.5523 7.78109 16 8.33337 16C8.88566 16 9.33337 15.5523 9.33337 15V9H15.3334C15.8857 9 16.3334 8.55228 16.3334 8C16.3334 7.44772 15.8857 7 15.3334 7Z"
              fill="#000000"
            />
          </svg>
          {Lang({ str: 'add', section: 'default' })}
        </button>
      </div>
      {ProductListStore.loadingError ? (
        <Alert
          message="Error loading data from the server. Please try again later."
          type="error"
          showIcon
          action={
            <Button size="small" onClick={handleRetry}>
              Try Again
            </Button>
          }
        />
      ) : (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={ProductListStore.dataList.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              scroll={{ x: 1100 }}
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="key"
              columns={columns.map((column) => ({
                ...column,
                onCell: (record: CpLsListFieldType) => ({
                  onClick: () => handleFieldNameClick(record, column as CpLsListFieldType),
                }),
              }))}
              dataSource={ProductListStore.dataList}
              loading={ProductListStore.loading}
            />
          </SortableContext>
        </DndContext>
      )}

      <ModalForm />
    </div>
  );
};

export default observer(ProductList);
