import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import usePageTitle from '../../../DataSource/Hooks/usePageTitle';
import { AuthStore } from '../../../DataSource/Stores/AuthStore';
import styles from '../Auth.module.scss';
import { observer } from 'mobx-react';
import SignUp from './SignUp';
import SetupProfile from './SetupProfile';

export type FieldTypetwo = {
  firstname: string;
  secondname: string;
  // code: string;
  // confirmpassword: string;
  phoneNumber?: string;
  token?: string;
};

interface QueryParams {
  phoneNumber?: string;
  token?: string;
  [key: string]: string | undefined;
}

function Index() {
  const [active, setActive] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({});
  const location = useLocation();
  usePageTitle('Sign up');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params: QueryParams = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    console.log('---- ww|params=', params);
    const { phoneNumber, token } = params;
    setQueryParams(params);
    setActive(!!(phoneNumber && token));
  }, [location.search]);

  if (AuthStore.loggedTesting || AuthStore.sending) {
    return (
      <div className={styles.form_wrp + ' center'}>
        <Spin />
      </div>
    );
  }

  // if (AuthStore.signUpError) {
  //   return <>Error</>;
  // }

  return (
    <div className={styles.form_wrp}>
      {!AuthStore.signUpSuccess && !AuthStore.signUpError && active && (
        <SetupProfile queryParams={queryParams} />
      )}
      {AuthStore.currentUserData.ready &&
        // !AuthStore.signUpSuccess &&
        // !AuthStore.signUpError &&
        !active && <SignUp />}
      {!AuthStore.currentUserData.ready &&
        // !AuthStore.signUpSuccess &&
        // !AuthStore.signUpError &&
        !active && <>Error</>}
      {AuthStore.signUpSuccess && (
        <>The link requesting confirmation of phone number has been dispatched.</>
      )}
    </div>
  );
}

export default observer(Index);
