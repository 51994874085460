import styles from './index.module.scss';
import { Collapse, CollapseProps } from 'antd';
import Button from '../../../../ui/Button';
import React, { FC, Fragment, ReactNode } from 'react';
import { config } from '../../../../../config';

interface IProps {
  expandIcon: (panelProps: any) => ReactNode;
  jobData?: { text: string; photoFiles: string[] }[];
}

const ReferenceBlock: FC<IProps> = ({ expandIcon, jobData }) => {
  const referenceCollapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'РЕФЕРЕНС',
      children: (
        <div className={styles.fieldsWrap}>
          {jobData?.[0]?.photoFiles?.map((url) => (
            <div className={styles.field}>
              <p className={styles.name}>Фото референса</p>
              <Button
                variant="text"
                icon="arrow_vertical"
                autoWidth
                onClick={() => window.open(`${config.EXTERNAL_ADDRESS}/public/${url}`, '_blank')}
              >
                Ссылка
              </Button>
            </div>
          ))}
          {jobData?.[0]?.text && (
            <div className={styles.additionalInfoBlock}>
              {jobData[0].text.split('\\n').map((line, i) => (
                <Fragment key={i}>
                  {line}
                  <br />
                </Fragment>
              ))}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Collapse
      items={referenceCollapseItems}
      expandIcon={expandIcon}
      expandIconPosition="right"
      bordered={false}
      ghost
    />
  );
};

export default ReferenceBlock;
