import type { FormProps } from 'antd';
import { Button, Form, Input, Modal, Select } from 'antd';
import { observer } from 'mobx-react';
import { ManagerStore } from '../../../../../../DataSource/Stores/ManagerStore';
import ButtonGray from '../../../../../ui/Button';
import StatusTitle from '../../../StatusTitle';
import CourierItemProgress from '../../CourierItemProgress';
import styles from './index.module.scss';

type FieldType = {
  username?: string;
  status?: string;
  remember?: string;
  monday?: string;
};

const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
  console.log('Success:', values);
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};
interface ModalProfileProps {
  // forceUpdate: () => void;
}

const Couriers: React.FC<ModalProfileProps> = ({}) => {
  const handleOk = () => {
    ManagerStore.isModalOpenZone = false;
  };
  const handleCancel = () => {
    ManagerStore.isModalOpenZone = false;
  };

  return (
    <Modal
      title={'зона доставки'}
      className={styles.modal}
      open={ManagerStore.isModalOpenZone}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <div className={styles.moda_cont}>
        <p className={styles.moda_cont__title}>
          <b>Алатауский район</b>
        </p>

        <div className={styles.child_flex}>
          <StatusTitle title="активен" theme="active" />
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className={styles.one_block}>
              <p className={styles.one_tit}>Параметры</p>
              <div className={styles.flex_input}>
                <Form.Item<FieldType>
                  label="Название"
                  name="username"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input placeholder="Алатауский район" />
                </Form.Item>
                <Form.Item<FieldType>
                  label="Статус"
                  name="status"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Select
                    defaultValue="Активен"
                    onChange={handleChange}
                    options={[
                      { value: 'Активен', label: 'Активен' },
                      { value: 'Не активен', label: 'Не активен' },
                    ]}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.one_block}>
              <div className={styles.cour_zon}>
                <p>Курьеры</p>
                <div className={styles.flex_bb + ' grp_8'}>
                  <CourierItemProgress title={'Андрей'} percent={10} />
                </div>
              </div>
            </div>

            <div className="d_flex grp_8">
              <ButtonGray
                variant={'text'}
                //style={{ width: '50%' }}
              >
                Изменить статус
              </ButtonGray>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Сохранить
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
export default observer(Couriers);
