// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cP9aAsov4QMWOORkPBMF{width:30%;border:1px solid #e2e2e1;border-radius:20px;padding:24px 0px}._slHq9W9IeS9_Olpaxk_{top:32px !important}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Kanban/ManagingZoning/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,wBAAA,CACA,kBAAA,CACA,gBAAA,CAEF,sBACE,mBAAA","sourcesContent":[".new_zone {\n  width: 30%;\n  border: 1px solid #e2e2e1;\n  border-radius: 20px;\n  padding: 24px 0px;\n}\n.tabl_bl__buttons {\n  top: 32px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new_zone": `cP9aAsov4QMWOORkPBMF`,
	"tabl_bl__buttons": `_slHq9W9IeS9_Olpaxk_`
};
export default ___CSS_LOADER_EXPORT___;
