import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Alert,
  Button,
  Form,
  type FormProps,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  TableProps,
  TabsProps,
} from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import usePageTitle from '../../../DataSource/Hooks/usePageTitle';
import { OrderParams } from '../../../DataSource/Stores/Admin/OrderParams';
import Lang from '../../Lang';
import styles from '../Admin.module.scss';
import Tab from '../OrdersParameters/Tab';
import InputTab from '../OrdersParameters/InputTab';
import OrderParametersItem from '../OrdersParameters/Item';

export type CpFieldType = {
  field_name: string;
  field_type: string;
  param_type_id?: string;
  _id?: string;
};
/*
param_type_id
 */

export interface DataType {
  _id?: string;
  key: string;
  field_name: string;
  field_type: string;
  createdAt: string;
  // status: string;
}
export interface DataTypeMap {
  [key: string]: DataType[];
}
export interface LoadingMap {
  [key: string]: boolean;
}

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const handleDelete = (key: React.Key) => {
  console.log('---- handleDelete|key=', key);
  message.loading('Loading');
  OrderParams.deleteParam(key as string)
    .then(() => {
      message.destroy();
      message.info('Success');
    })
    .catch((error) => {
      message.destroy();
      if (error?.message == 'Request failed with status code 409') {
        return message.error('Parameter in use');
      }
      message.error('Error');
    });
};
const columns: TableProps<ChParamDataType>['columns'] = [
  {
    key: 'sort',
  },
  {
    title: 'Field name',
    dataIndex: 'field_name',
    key: 'field_name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Field type',
    dataIndex: 'field_type',
    key: 'field_type',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Delete',
    dataIndex: 'operation',
    render: (_, record) => (
      <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
        <a>{Lang({ str: 'delete', section: 'default' })}</a>
      </Popconfirm>
    ),
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const initialItems: TabsProps['items'] = [];

const Index: React.FC = () => {
  usePageTitle(Lang({ str: 'ordersparameters', section: 'default' }));
  const [_, setDataSource] = useState(OrderParams.dataList);
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setLoadError(false);
    getOrderTypesList();
  }, []);

  const getOrderList = () => {
    OrderParams.getParametersList(OrderParams.paramTypeId)
      .then(() => setDataSource(OrderParams.dataList))
      .catch(() => setLoadError(true));
  };

  const getOrderTypesList = () => {
    OrderParams.getOrderTypesList()
      .then(() => {
        setItems(
          // @ts-ignore
          OrderParams.paramTypesList.map((i) => {
            console.log('---- ww|paramTypesList|i=', i);
            return {
              key: i.key,
              label: <InputTab name={i.label} param_type_id={i.key} />,
              children: (
                <OrderParametersItem
                  showModal={showModal}
                  onDragEnd={onDragEnd}
                  Row={Row}
                  setIsModalOpen={setIsModalOpen}
                />
              ),
            };
          })
        );
      })
      .then(() => {
        if (OrderParams.paramTypesList?.length) {
          OrderParams.paramTypeId = OrderParams.paramTypesList[0].key;
        }
        return getOrderList();
      })
      .catch(() => setLoadError(true));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (over?.id && active.id !== over.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        const dataList = arrayMove(previous, activeIndex, overIndex);
        const _tmpDataList = OrderParams.dataList;
        OrderParams.dataList = dataList;

        OrderParams.changeSort(active.id, over?.id || null).catch(() => {
          OrderParams.dataList = _tmpDataList;
          message.error('Error while changing sort item');
        });

        return dataList;
      });
    }
  };

  // Функция для обработки клика на поле field_name
  const handleFieldNameClick = (record: ChParamDataType, column: ChParamDataType) => {
    // console.log('Clicked on column.key:', column);
    // console.log('Clicked on record:', record as DataType);
    if (column.key == 'field_name') {
      OrderParams.paramData = record;
      setIsModalOpen(true);
    }
  };

  const [loadError, setLoadError] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef<any>(null);

  const handleCancel = () => {
    console.log('---- ww|handleCancel|');
    OrderParams.paramData = null;
    formRef.current?.resetFields();
    setIsModalOpen(false);
  };

  const onChangeType = (value: string) => {
    console.log(`selected ${value}`);
    if (OrderParams.paramData) {
      OrderParams.paramData.field_type = value;
      getOrderList();
    }
  };

  const onFinish: FormProps<CpFieldType>['onFinish'] = async (values) => {
    try {
      OrderParams.loadingList = true;
      message.loading('Loading');

      if (OrderParams.paramData?._id) {
        values._id = OrderParams.paramData?._id;
        OrderParams.paramData = values as ChParamDataType;
        await OrderParams.editType(values);
      } else {
        await OrderParams.addType(values);
      }
      message.destroy();
    } catch (error: any) {
      console.error('signUp exception:error?.status=', error?.message);
      message.destroy();
      message.error('Error add parameter');
      return;
    } finally {
      OrderParams.loadingList = false;
    }

    setLoadError(false);
    getOrderList();

    message.info('Success');
    setIsModalOpen(false);
    OrderParams.paramData = null;
    formRef.current?.resetFields();
  };

  const onFinishFailed: FormProps<CpFieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChangeParamType = (key: string) => {
    console.log('onChangeOrderParamType|key=', key);
    OrderParams.paramTypeId = key as OrdersStatus;
    setLoadError(false);
    getOrderList();
  };

  const handleSubmit = () => {
    formRef.current?.submit();
  };

  const addTab = async () => {
    if (OrderParams.addingTab) {
      message.destroy();
      message.info('Создания нового параметра еще в работе');
      return;
    }
    message.loading('Загрузка');
    //
    let newActiveKey;
    try {
      newActiveKey = await OrderParams.addOrderParam();
    } catch (error) {
      console.error('addTab|error=', error);
      message.destroy();
      message.error('Add new tab error');
      return;
    }
    message.destroy();

    const newPanes = [...items];
    newPanes.push({
      key: newActiveKey,
      label: <InputTab param_type_id={newActiveKey} />,
      children: (
        <OrderParametersItem
          showModal={showModal}
          onDragEnd={onDragEnd}
          Row={Row}
          setIsModalOpen={setIsModalOpen}
        />
      ),
    });
    setItems(newPanes);
    OrderParams.paramTypeId = newActiveKey;
  };

  const [openModalDel, setOpenModalDel] = useState(false);
  const [deleteNewTab, setDeleteTab] = useState<false | TargetKey>(false);

  const showPopconfirm = () => {
    setOpenModalDel(true);
  };

  const closeModalDel = () => {
    setOpenModalDel(false);
    setDeleteTab(false);
  };

  const removeTab = async (productId: string) => {
    setOpenModalDel(false);

    message.loading('Loading');
    try {
      await OrderParams.deleteOrderParam(productId);
    } catch (error: any) {
      if (error?.message == 'Request failed with status code 409') {
        return message.error('Не возможно удалить. Тип содержит параметры ');
      }
      console.error('removeTab|error=', error);
      message.destroy();
      message.error('Ошибка при удалении типа параметров');
      return;
    }
    message.destroy();

    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === productId) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== productId);
    // console.log(
    //   '---- ww|1|newActiveKey=',
    //   newPanes.length,
    //   {
    //     productId,
    //   },
    //   '--------',
    //   newPanes.length
    // );

    if (newPanes.length && OrderParams.paramTypeId === productId) {
      if (lastIndex >= 0) {
        OrderParams.paramTypeId = newPanes[lastIndex].key;
        console.log('---- ww|1|lastIndex >= 0|newActiveKey=', OrderParams.paramTypeId);
      } else {
        OrderParams.paramTypeId = newPanes[0].key;
      }
    }

    setItems(newPanes);

    onChangeParamType(OrderParams.paramTypeId);
  };

  const onEditTab = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove'
  ) => {
    if (action === 'add') {
      OrderParams.visibInput = true;
      addTab();
    } else {
      setDeleteTab(targetKey);
      showPopconfirm();
    }
  };
  const deleteTab = () => {
    if (deleteNewTab) removeTab(deleteNewTab as string);
    setDeleteTab(false);
  };

  return (
    <div className="pt-80">
      <h1>{Lang({ str: 'ordersparameters', section: 'default' })}</h1>
      {loadError ? (
        <Alert
          message="Error loading data from the server. Please try again later."
          type="error"
          showIcon
          action={
            <Button
              size="small"
              onClick={() => {
                setLoadError(false);
                getOrderList();
              }}
            >
              Try Again
            </Button>
          }
        />
      ) : (
        // <div className={styles.table + ' mt-32'}>
        //   <Tabs
        //     defaultActiveKey={OrdParameters.paramType}
        //     items={items}
        //     onChange={onChangeParamType}
        //   />
        // </div>
        <Tab
          items={items}
          onEditTab={onEditTab}
          onChangeParamType={onChangeParamType}
          openModalDel={openModalDel}
          deleteTab={deleteTab}
          closeModalDel={closeModalDel}
        />
      )}

      <Modal
        title="Order parameters"
        open={isModalOpen}
        className={styles.modalform}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={OrderParams.loadingList}
            onClick={handleSubmit}
          >
            Save
          </Button>,
          <Button
            key="link"
            type="primary"
            loading={OrderParams.loadingList}
            onClick={handleCancel}
          >
            Close
          </Button>,
        ]}
      >
        <Form
          key={Date.now()} // Установите key с уникальным значением
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          initialValues={OrderParams.paramData || {}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          ref={formRef} // Привязываем ссылку на форму
        >
          <Form.Item<CpFieldType>
            label="Field name"
            name="field_name"
            rules={[{ required: true, message: 'Please input Field name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<CpFieldType>
            label="Field type"
            name="field_type"
            rules={[{ required: true, message: 'Please input Field type!' }]}
          >
            <Select
              onChange={onChangeType}
              options={[
                { value: 'text', label: 'text' },
                { value: 'number', label: 'number' },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default observer(Index);
