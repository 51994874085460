import { Drawer, Dropdown, Flex } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { FC, useEffect, useRef, useState } from 'react';
import { Condition2, StageParam, StagesDataType } from '../../../../../ types';
import { StagesStore } from '../../../../../../DataSource/Stores/Cabinet/StagesStore';
import Button from '../../../../../ui/Button';
import IconButton from '../../../../../ui/IconButton';
import Input from '../../../../../ui/Input';
import RuleBlock from './RuleBlock';
import styles from './index.module.scss';

interface IProps extends StagesDataType {
  isOpen: boolean;
  onClose: () => void;
}

const JobDrawer: FC<IProps> = ({
  isOpen,
  onClose,
  _id,
  name,
  description,
  workspaceId,
  status,
  conditions,
  taskTypesAndParams,
  isStarting,
  isFinal,
  createdAt,
  updatedAt,
  __v,
}) => {
  const [stage, setStage] = useState<StagesDataType>({
    _id,
    name,
    description,
    workspaceId,
    status,
    conditions,
    taskTypesAndParams,
    isStarting,
    isFinal,
    createdAt,
    updatedAt,
    __v,
  });
  const [stageParams, setStageParams] = useState<StageParam[]>([]);
  const [changesMade, setChangesMade] = useState(false);
  const initialState = useRef(stage);

  useEffect(() => {
    (async () => {
      const res = await StagesStore.getStageParams(workspaceId, _id);
      if (res) setStageParams(res.data);
    })();
  }, []);

  useEffect(() => {
    setChangesMade(JSON.stringify(initialState.current) !== JSON.stringify(stage));
  }, [stage]);

  const clearChanges = () => {
    setStage(initialState.current);
  };

  const addRule = () => {
    setStage((prev) => ({
      ...prev,
      conditions: [
        ...prev.conditions,
        { toStageId: '', conditions: [[{ paramId: '', sign: '', paramValue: '' }]] },
      ],
    }));
  };

  const removeRule = (ruleIndex: number) => {
    setStage((prev) => ({
      ...prev,
      conditions: [...prev.conditions.filter((_, i) => i !== ruleIndex)],
    }));
  };

  const addParam = (ruleIndex: number) => {
    setStage((prev) => ({
      ...prev,
      conditions: prev.conditions.map((item, i) => {
        if (i === ruleIndex) {
          return {
            ...item,
            conditions: [[...item.conditions[0], { paramId: '', sign: '', paramValue: '' }]],
          };
        }
        return item;
      }),
    }));
  };

  const removeParam = (ruleIndex: number, paramIndex: number) => {
    setStage((prev) => ({
      ...prev,
      conditions: prev.conditions.map((item, i) => {
        if (i === ruleIndex) {
          return {
            ...item,
            conditions: [[...item.conditions[0].filter((_, i) => i !== paramIndex)]],
          };
        }
        return item;
      }),
    }));
  };

  const setParam = (ruleIndex: number, paramIndex: number, param: Condition2) => {
    setStage((prev) => ({
      ...prev,
      conditions: prev.conditions.map((item, i) => {
        if (i === ruleIndex) {
          return {
            ...item,
            conditions: [
              item.conditions[0].map((condition, i) => {
                if (i === paramIndex) {
                  return param;
                }
                return condition;
              }),
            ],
          };
        }
        return item;
      }),
    }));
  };

  const onSave = async () => {
    const res = await StagesStore.updateStageById(workspaceId, _id, stage);
    if (res) setStage(res.data);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} width={540}>
        <div className={styles.stageDrawer}>
          <div className={styles.stageDrawerTop}>
            <p className={styles.title}>{stage.name}</p>
            <Dropdown
              menu={{
                items: [
                  {
                    key: '1',
                    label: 'Создать правило',
                    onClick: addRule,
                  },
                ],
              }}
              trigger={['click']}
            >
              <IconButton icon="three_dots" variant="secondary" />
            </Dropdown>
          </div>

          {changesMade && (
            <Flex align="center" gap={16} style={{ marginBottom: '32px' }}>
              <Button onClick={onSave}>Сохранить</Button>
              <Button className={styles.cancelBtn} onClick={clearChanges}>
                Отменить
              </Button>
            </Flex>
          )}

          <div className={styles.fieldsWrap}>
            <div className={styles.field}>
              <p className={styles.name}>Наименование этапа</p>
              <Input
                style={{ padding: '4px 8px' }}
                value={stage.name}
                onChange={(e) => setStage((prev) => ({ ...prev, name: e.target.value }))}
              />
            </div>
            <div className={styles.field}>
              <p className={styles.name}>Обновлено</p>
              <div className={styles.dateBlock}>
                {dayjs(stage.updatedAt).format('DD / MM / YYYY, HH:mm')}
              </div>
            </div>
          </div>

          {stage.conditions.map((condition, i) => (
            <RuleBlock
              key={i}
              index={i}
              stageParams={stageParams}
              removeRule={removeRule}
              addParam={addParam}
              removeParam={removeParam}
              setParam={setParam}
              {...condition}
            />
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default observer(JobDrawer);
