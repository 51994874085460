// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LfdEkNLMfmkXSsGzpG4g{width:530px;background-color:#fff;border:1px solid #f5f5f5;border-radius:24px;box-shadow:0 2px 4px 0 rgba(0,0,0,.2509803922);display:flex;flex-direction:column;align-items:center;gap:40px;padding:40px 40px 80px}.LfdEkNLMfmkXSsGzpG4g.U_FbKDUV6y1Z_PMlcZmu{width:auto;background:none;border:none;box-shadow:none;padding:24px 0 0 0px}.BFlNRQSdSLexPyamWcEY{width:fit-content;position:relative}.BFlNRQSdSLexPyamWcEY .kWtu7lL6M0ALaFBsNiTw{position:absolute;right:0;bottom:0}.BFlNRQSdSLexPyamWcEY .kWtu7lL6M0ALaFBsNiTw .E3zUMOcb75d29iDSYpMA{background-color:#f1b729;border:2px solid #fff;border-radius:50%}.rE1QGGsqoOLDc0H6dhtk{color:#2a3038;font-size:18px;font-weight:700}`, "",{"version":3,"sources":["webpack://./src/components/Profile/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,qBAAA,CACA,wBAAA,CACA,kBAAA,CACA,8CAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CACA,sBAAA,CAEA,2CACE,UAAA,CACA,eAAA,CACA,WAAA,CACA,eAAA,CACA,oBAAA,CAIJ,sBACE,iBAAA,CACA,iBAAA,CAEA,4CACE,iBAAA,CACA,OAAA,CACA,QAAA,CAEA,kEACE,wBAAA,CACA,qBAAA,CACA,iBAAA,CAKN,sBACE,aAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".profile {\n  width: 530px;\n  background-color: white;\n  border: 1px solid #f5f5f5;\n  border-radius: 24px;\n  box-shadow: 0 2px 4px 0 #00000040;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;\n  padding: 40px 40px 80px;\n\n  &.mobile {\n    width: auto;\n    background: none;\n    border: none;\n    box-shadow: none;\n    padding: 24px 0 0 0px;\n  }\n}\n\n.uploadWrap {\n  width: fit-content;\n  position: relative;\n\n  .upload {\n    position: absolute;\n    right: 0;\n    bottom: 0;\n\n    .editBtn {\n      background-color: #f1b729;\n      border: 2px solid white;\n      border-radius: 50%;\n    }\n  }\n}\n\n.formLabel {\n  color: #2a3038;\n  font-size: 18px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": `LfdEkNLMfmkXSsGzpG4g`,
	"mobile": `U_FbKDUV6y1Z_PMlcZmu`,
	"uploadWrap": `BFlNRQSdSLexPyamWcEY`,
	"upload": `kWtu7lL6M0ALaFBsNiTw`,
	"editBtn": `E3zUMOcb75d29iDSYpMA`,
	"formLabel": `rE1QGGsqoOLDc0H6dhtk`
};
export default ___CSS_LOADER_EXPORT___;
