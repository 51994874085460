import { makeAutoObservable, observable } from 'mobx';
import { basicRequestPost } from '../../Requests/basicRequestPost';
import moment from 'moment/moment';

export class LeaderboardStoreActionsClass {
  @observable
  loadingError: boolean = false;

  @observable
  loading: boolean = false;

  @observable dataList: UserDataType[] = [];

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  /**
   *
   */
  async getLeaderBoard() {
    // console.log('---- ww|getLeaderBoard');
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.loadingError = false;
    this.dataList = [];

    try {
      const res = await basicRequestPost('/user/leaderboard', {});
      // console.log('---- ww|getLeaderBoard|res.data=', res.data);
      const currentDate = moment();
      this.dataList = res.data.docs
        .map((i: any, k: number) => {
          // console.log('---- ww|i.createdAt=', i.createdAt, moment(i.createdAt));
          i.key = i._id;
          i.number = k + 1;
          i.tags = [];
          i.dformat = new Date(i.createdAt).toLocaleString();
          i.date = currentDate.diff(i.createdAt, 'days');
          if (i.status == 'just_created') {
            i.status = 'Not verified';
            i.firstname = '';
            i.secondname = '';
          }
          return i;
        })
        .sort((a: any, b: any) => b.date - a.date) as UserDataType[];
    } catch (error) {
      // throw error;
      console.error('getLeaderBoard|error=', error);
      this.loadingError = true;
    } finally {
      this.loading = false;
    }
  }
}
