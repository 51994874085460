//import styles from './index.module.scss';
import type { MenuProps, TableColumnsType, TableProps } from 'antd';
import { Button, Dropdown, Space, Table } from 'antd';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { ManagerStoreContext } from '../../../../../DataSource/context/title';
import Icon from '../../../../ui/Icon';
import StatusTag from '../../StatusTag';

import { ManagerStore } from '../../../../../DataSource/Stores/ManagerStore';
import Zone from './Modal/Zone';
import styles from './index.module.scss';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;
type Filters = Parameters<OnChange>[1];

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface DataType {
  key: string;
  name: string;
  age: number;
  status: string;
  kolvo: string;
}

const data: DataType[] = [
  {
    key: '1',
    name: 'Алатауский район',
    age: 32,
    kolvo: '103,22 км²',
    status: 'Активна',
  },
  {
    key: '2',
    name: 'Алатауский район',
    age: 42,
    status: 'Активна',
    kolvo: '103,22 км²',
  },
  {
    key: '3',
    name: 'Алатауский район',
    age: 32,
    status: 'Неактивна',
    kolvo: '103,22 км²',
  },
  {
    key: '4',
    name: 'Алатауский район',
    age: 32,
    status: 'Активна',
    kolvo: '103,22 км²',
  },
];

const ManagingZoning = () => {
  const { managersStore } = useContext(ManagerStoreContext)!;
  useEffect(() => {
    managersStore.setTitle('Управление зонированием');
  }, [managersStore]);
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" href="/profile">
          Редактировать
        </a>
      ),
    },
    {
      key: '2',
      label: <a>Удалить</a>,
    },
  ];

  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const showModal = () => {
    ManagerStore.isModalOpenZone = true;
  };
  const handleChange: OnChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as Sorts);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setActiveFilter(null);
  };

  const acFilter = () => {
    setFilteredInfo({
      status: ['Активна'],
    });
    setActiveFilter('activefil');
  };

  const unactiveFilter = () => {
    setFilteredInfo({
      status: ['Неактивна'],
    });
    setActiveFilter('unactivefil');
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <p onClick={showModal}>{text}</p>,
    },
    {
      title: 'Кол-во заказов',
      dataIndex: 'age',
      key: 'age',
      sorter: (a, b) => a.age - b.age,
      sortOrder: sortedInfo.columnKey === 'age' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Курьеры',
      dataIndex: 'age',
      key: 'age',
      sorter: (a, b) => a.age - b.age,
      sortOrder: sortedInfo.columnKey === 'age' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Площадь',
      dataIndex: 'kolvo',
      key: 'kolvo',
      sorter: (a, b) => a.kolvo.length - b.kolvo.length,
      sortOrder: sortedInfo.columnKey === 'kolvo' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Активна', value: 'Активна' },
        { text: 'Неактивна', value: 'Неактивна' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value as string),
      ellipsis: true,
      render: (text, record) => (
        <>
          {record.status === 'Активна' ? (
            <StatusTag theme="create" title={text} />
          ) : (
            <StatusTag theme="unactive" title={text} />
          )}
        </>
      ),
    },
    {
      title: '',
      dataIndex: '',
      width: '5%',
      key: 'x',
      render: () => (
        <Dropdown className="menuprogile" trigger={['click']} menu={{ items }}>
          <a onClick={(e) => e.preventDefault()}>
            <Icon slug={'fi-rr-menu-dots-vertical'} />
          </a>
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <div className="flex_group">
        <div className={styles.new_zone}>{/* <DistrictMap /> */}</div>
        <div className="tabl_bl">
          <Space className={styles.tabl_bl__buttons + ' tabl_bl__buttons'}>
            <p>Зоны доставок</p>
            <Button
              onClick={clearFilters}
              className={`filter_button ${activeFilter === null ? 'active' : ''}`}
            >
              Все
            </Button>
            <Button
              onClick={acFilter}
              className={`filter_button ${activeFilter === 'activefil' ? 'active' : ''}`}
            >
              Активен
            </Button>
            <Button
              onClick={unactiveFilter}
              className={`filter_button ${activeFilter === 'unactivefil' ? 'active' : ''}`}
            >
              Неактивен
            </Button>
          </Space>
          <Table
            columns={columns}
            pagination={{ position: ['topRight'] }}
            dataSource={data}
            onChange={handleChange}
          />
        </div>
      </div>
      <Zone />
    </>
  );
};

export default observer(ManagingZoning);
