// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YYP6NJd32mmBMEGQK3cw{display:flex;flex-direction:column;gap:32px;padding:0}.Az8Htm9yYv_3iLfZ7x1s{display:flex;align-items:center;gap:48px}.Az8Htm9yYv_3iLfZ7x1s .EeCo21JxaxoeuLHbk2Hj{color:#1e1e1e;font-size:44px;font-weight:700;padding:16px 0}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/Workspaces/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,SAAA,CAEA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,4CACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".distributionCenterPage {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  padding: 0;\n\n  &Top {\n    display: flex;\n    align-items: center;\n    gap: 48px;\n\n    .title {\n      color: #1e1e1e;\n      font-size: 44px;\n      font-weight: 700;\n      padding: 16px 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"distributionCenterPage": `YYP6NJd32mmBMEGQK3cw`,
	"distributionCenterPageTop": `Az8Htm9yYv_3iLfZ7x1s`,
	"title": `EeCo21JxaxoeuLHbk2Hj`
};
export default ___CSS_LOADER_EXPORT___;
