import { UniqueIdentifier } from '@dnd-kit/core';
import { makeAutoObservable, observable } from 'mobx';
import {
  CpLsFieldType,
  CpLsListFieldType,
  Product,
} from '../../../components/Admin/ProductList/Modal';
import { basicRequestDelete } from '../../Requests/basicRequestDelete';
import { basicRequestGet } from '../../Requests/basicRequestGet';
import { basicRequestPost } from '../../Requests/basicRequestPost';

export class ProductListStoreActionsClass {
  @observable
  isModalOpen: boolean = false;

  @observable
  loadingError: boolean = false;

  @observable
  loading: boolean = false;

  @observable
  loadingProduct: boolean = false;

  @observable
  addInProgress: boolean = false;

  @observable dataList: CpLsListFieldType[] = [];

  @observable dataProduct: CpLsFieldType | null = null;

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  /**
   *
   */
  async addProduct(data: Product) {
    this.addInProgress = true;
    try {
      await basicRequestPost('/admin/product_list/add_product', data);
    } catch (error) {
      console.error('---- addProduct|error=', error);
      throw error;
    } finally {
      this.addInProgress = false;
    }
  }

  /**
   *
   */
  async editProduct(data: Product) {
    this.addInProgress = true;
    try {
      await basicRequestPost('/admin/product_list/edit_product', data);
    } catch (error) {
      console.error('---- editProduct|error=', error);
      throw error;
    } finally {
      this.addInProgress = false;
    }
  }

  /**
   *
   */
  async getProduct(data: CpLsListFieldType) {
    console.log('---- ww|getProduct|data=', data);
    if (this.loadingProduct) {
      console.info('---- ww|getProduct|loadingProduct==true');
      return;
    }
    this.loadingProduct = true;
    try {
      const res = await basicRequestGet(`/admin/product_list/get_product/${data._id}`);
      console.log('---- ww|getProduct|res.data=', res.data);
      this.dataProduct = res.data as CpLsFieldType;
    } catch (error) {
      throw error;
    } finally {
      this.loadingProduct = false;
    }
  }

  /**
   *
   */
  async getList() {
    console.log('---- ww|getList');
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.loadingError = false;

    try {
      const res = await basicRequestPost('/admin/product_list/list', {
        // filter: { param_type },
      });

      this.dataList = res.data.data.docs.map((i: any, k: number) => {
        i.key = i._id;
        i.numb = k + 1;
        // i.tags = [];
        return i;
      }) as CpLsListFieldType[];
    } catch (error) {
      // throw error;
      console.error('getList|error=', error);
      this.loadingError = true;
    } finally {
      this.loading = false;
    }
  }

  /**
   *
   */
  async changeSort(active_id: UniqueIdentifier, over_id: UniqueIdentifier | null) {
    console.log('---- ww|chandeSort');
    if (this.loading) {
      return;
    }

    this.loading = true;
    // this.loadingError = false;

    try {
      const res = await basicRequestPost('/admin/product_list/change_product_params_sort', {
        active_id,
        over_id,
      });
    } catch (error) {
      // throw error;
      console.error('getList|error=', error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  /**
   *
   */
  async deleteProduct(_id: string) {
    const index = this.dataList.findIndex((obj) => obj._id === _id);
    console.log('deleteProduct|index=', index);
    if (index === -1) {
      console.error('deleteProduct|index !== -1');
      throw 'Remove error';
    }
    await basicRequestDelete('/admin/product_list/delete/' + _id);
    this.dataList = this.dataList.filter((obj) => obj._id !== _id);
  }

  get globalLoading() {
    return this.loading || this.addInProgress;
  }
}
