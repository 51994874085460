import { DeleteOutlined } from '@ant-design/icons';
import { MenuProps, Popconfirm, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EventEmitter } from 'events';
import { observer } from 'mobx-react';
import React from 'react';
import { Params } from '../../../DataSource/Stores/Admin/OrdersParams';
import { OrdersStore } from '../../../DataSource/Stores/Admin/OrdersStoreV2';
import Lang from '../../Lang';
import BaseEntity from '../BaseEntity';
import { CpLsListFieldType } from '../BaseEntity/Modal';

// interface StatusColorMap {
//   [key: string]: string;
// }
const eventEmitter = new EventEmitter();
const columns: ColumnsType<CpLsListFieldType> = [
  {
    dataIndex: 'numb',
    key: 'numb',
  },
  {
    key: 'sort',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a>{text}</a>,
  },
  // {
  //   title: 'Product Type',
  //   dataIndex: 'type_param_id',
  //   key: 'type_param_id',
  //   render: (text) => <a>{text}</a>,
  // },

  // {
  //   title: 'Status',
  //   key: 'status_param_id',
  //   dataIndex: 'status_param_id',
  //   render: (_, { status_param_id }) => {
  //     const color: StatusColorMap = {
  //       verified: 'green',
  //       block: 'volcano',
  //     };
  //     return <Tag color={color[status_param_id]}>{status_param_id?.toUpperCase()}</Tag>;
  //   },
  // },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (_, record) => (
      <Switch
        value={record.isActive}
        onChange={(val) => eventEmitter.emit('changeActiveEntity', record.key, val)}
        loading={OrdersStore.loading}
      />
    ),
  },
  {
    title: 'Delete',
    dataIndex: 'operation',
    render: (_, record) => (
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() => eventEmitter.emit('deleteEntity', record.key)}
      >
        <DeleteOutlined className="icon_bl" />
      </Popconfirm>
    ),
  },
];

const actions: MenuProps = {
  items: [
    {
      key: '1',
      label: (
        <Popconfirm title="Sure to import" onConfirm={() => eventEmitter.emit('importAction', '')}>
          Импорт
        </Popconfirm>
      ),
    },
    // {
    //   key: '2',
    //   label: (
    //     <Popconfirm title="Sure to sync?" onConfirm={() => eventEmitter.emit('syncSoft', '')}>
    //       Синхронизация (мягкая)
    //     </Popconfirm>
    //   ),
    // },
    // {
    //   key: '3',
    //   label: (
    //     <Popconfirm title="Sure to sync?" onConfirm={() => eventEmitter.emit('syncHard', '')}>
    //       Синхронизация (грубая)
    //     </Popconfirm>
    //   ),
    // },
  ],
};

const Orders: React.FC = () => {
  return (
    <BaseEntity
      EntityParams={Params}
      EntityStore={OrdersStore}
      title={Lang({ str: 'orders', section: 'default' })}
      columns={columns}
      eventEmitter={eventEmitter}
      actions={actions}
      manualAddition={false}
      needCities={false}
      needSourceId={true}
    ></BaseEntity>
  );
};

export default observer(Orders);
