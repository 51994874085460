// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LU8PHuEtABnX_AGYctQH{flex-direction:column;display:flex}.TAMKmJteXryAMks9hsfY{width:50px;background:#ebedef;height:50px;margin-bottom:8px;border:1px solid #e5e5e5;border-radius:50px}.TAMKmJteXryAMks9hsfY i{color:#000}.Mpm89cN8K0x2FSKwPlc1{border:1px solid #2b2b2b;background:linear-gradient(206deg, #2b2b2b 0%, #535353 100%)}.Mpm89cN8K0x2FSKwPlc1 i{color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/Menu/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,YAAA,CAEF,sBACE,UAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,wBAAA,CACA,kBAAA,CAEA,wBACE,UAAA,CAEF,sBACE,wBAAA,CACA,4DAAA,CACA,wBACE,UAAA","sourcesContent":[".menu {\n  flex-direction: column;\n  display: flex;\n}\n.nav_item {\n  width: 50px;\n  background: #ebedef;\n  height: 50px;\n  margin-bottom: 8px;\n  border: 1px solid #e5e5e5;\n  border-radius: 50px;\n\n  i {\n    color: #000;\n  }\n  &__active {\n    border: 1px solid #2b2b2b;\n    background: linear-gradient(206deg, #2b2b2b 0%, #535353 100%);\n    i {\n      color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `LU8PHuEtABnX_AGYctQH`,
	"nav_item": `TAMKmJteXryAMks9hsfY`,
	"nav_item__active": `Mpm89cN8K0x2FSKwPlc1`
};
export default ___CSS_LOADER_EXPORT___;
