export const _mock_newTasks = [
  {
    _id: 'job-1',
    name: 'Доставка техники',
    key: 1,
    stageId: '6707a3db598d48bda5860bfa',
    jobId: '001',
    prodImage: 'https://placehold.co/400x400',
    createdAt: '2024-10-01T09:00:00.000Z',
    updatedAt: '2024-10-10T12:00:00.000Z',
    onClick: () => console.log('Доставка техники clicked'),
    workspaceId: 'workspace-001',
    externalId: {
      bitrixId: 'bitrix-101',
      jiraId: 'jira-201',
    },
    jobData: [
      {
        text: 'Доставка бытовой техники на склад.',
        photoFiles: ['https://placehold.co/400x400'],
      },
    ],
    targetDate: '2024-11-01T12:00:00.000Z',
    assignedTo: 'Сергей',
    bitrixId2: 'bitrix-202',
    jiraId2: 'jira-302',
    comments: [
      {
        author: 'Менеджер Алексей',
        createdAt: '2024-10-05T14:00:00.000Z',
        fileUrls: ['https://placehold.co/400x400'],
        text: 'Заказ подтвержден, техника ожидается на складе.',
        updatedAt: '2024-10-05T14:00:00.000Z',
        _id: 'comment-1',
      },
    ],
    isSelectedJob: true,
    expectedDate: '2024-11-05T12:00:00.000Z',
    startDate: '2024-10-02T08:00:00.000Z',
  },
  {
    _id: 'job-2',
    name: 'Доставка мебели',
    key: 2,
    stageId: '6707a3db598d48bda5860bfa',
    jobId: '002',
    prodImage: 'https://placehold.co/400x400',
    createdAt: '2024-09-15T10:00:00.000Z',
    updatedAt: '2024-09-20T12:00:00.000Z',
    onClick: () => console.log('Доставка мебели clicked'),
    workspaceId: 'workspace-002',
    externalId: {
      bitrixId: 'bitrix-102',
      jiraId: 'jira-202',
    },
    jobData: [
      {
        text: 'Перевозка офисной мебели заказчику.',
        photoFiles: ['https://placehold.co/400x400'],
      },
    ],
    targetDate: '2024-12-01T12:00:00.000Z',
    assignedTo: 'Марина',
    bitrixId2: 'bitrix-203',
    jiraId2: 'jira-303',
    comments: [
      {
        author: 'Логист Петр',
        createdAt: '2024-09-17T10:30:00.000Z',
        fileUrls: [],
        text: 'Мебель погружена в транспорт.',
        updatedAt: '2024-09-17T10:30:00.000Z',
        _id: 'comment-2',
      },
    ],
    isSelectedJob: false,
    expectedDate: '2024-12-05T12:00:00.000Z',
    startDate: '2024-09-16T08:00:00.000Z',
  },
  {
    _id: 'job-3',
    name: 'Доставка продуктов питания',
    key: 3,
    stageId: '6707a3db598d48bda5860bfa',
    jobId: '003',
    prodImage: 'https://placehold.co/400x400',
    createdAt: '2024-10-05T11:00:00.000Z',
    updatedAt: '2024-10-06T12:00:00.000Z',
    onClick: () => console.log('Доставка продуктов питания clicked'),
    workspaceId: 'workspace-003',
    externalId: {
      bitrixId: 'bitrix-103',
      jiraId: 'jira-203',
    },
    jobData: [
      {
        text: 'Доставка продуктов на гипермаркет.',
        photoFiles: ['https://placehold.co/400x400'],
      },
    ],
    targetDate: '2024-11-10T12:00:00.000Z',
    assignedTo: 'Олег',
    bitrixId2: 'bitrix-204',
    jiraId2: 'jira-304',
    comments: [
      {
        author: 'Кладовщик Ирина',
        createdAt: '2024-10-06T09:00:00.000Z',
        fileUrls: ['https://placehold.co/400x400'],
        text: 'Продукты выгружены и проверены.',
        updatedAt: '2024-10-06T09:00:00.000Z',
        _id: 'comment-3',
      },
    ],
    isSelectedJob: false,
    expectedDate: '2024-11-12T12:00:00.000Z',
    startDate: '2024-10-07T08:00:00.000Z',
  },
  {
    _id: 'job-4',
    name: 'Экспресс-доставка одежды',
    key: 4,
    stageId: '6707a3db598d48bda5860bfa',
    jobId: '004',
    prodImage: 'https://placehold.co/400x400',
    createdAt: '2024-10-03T08:00:00.000Z',
    updatedAt: '2024-10-07T12:00:00.000Z',
    onClick: () => console.log('Экспресс-доставка одежды clicked'),
    workspaceId: 'workspace-004',
    externalId: {
      bitrixId: 'bitrix-104',
      jiraId: 'jira-204',
    },
    jobData: [
      {
        text: 'Быстрая доставка одежды покупателям.',
        photoFiles: ['https://placehold.co/400x400'],
      },
    ],
    targetDate: '2024-10-20T12:00:00.000Z',
    assignedTo: 'Анна',
    bitrixId2: 'bitrix-205',
    jiraId2: 'jira-305',
    comments: [
      {
        author: 'Курьер Василий',
        createdAt: '2024-10-04T13:30:00.000Z',
        fileUrls: [],
        text: 'Заказ доставлен клиенту.',
        updatedAt: '2024-10-04T13:30:00.000Z',
        _id: 'comment-4',
      },
    ],
    isSelectedJob: false,
    expectedDate: '2024-10-22T12:00:00.000Z',
    startDate: '2024-10-04T08:00:00.000Z',
  },
  {
    _id: 'job-5',
    name: 'Доставка стройматериалов',
    key: 5,
    stageId: '6707a3db598d48bda5860bfa',
    jobId: '005',
    prodImage: 'https://placehold.co/400x400',
    createdAt: '2024-09-25T10:00:00.000Z',
    updatedAt: '2024-09-30T12:00:00.000Z',
    onClick: () => console.log('Доставка стройматериалов clicked'),
    workspaceId: 'workspace-005',
    externalId: {
      bitrixId: 'bitrix-105',
      jiraId: 'jira-205',
    },
    jobData: [
      {
        text: 'Доставка строительных материалов на объект.',
        photoFiles: ['https://placehold.co/400x400'],
      },
    ],
    targetDate: '2024-10-15T12:00:00.000Z',
    assignedTo: 'Иван',
    bitrixId2: 'bitrix-206',
    jiraId2: 'jira-306',
    comments: [
      {
        author: 'Строитель Николай',
        createdAt: '2024-09-28T08:00:00.000Z',
        fileUrls: [],
        text: 'Материалы прибыли на объект.',
        updatedAt: '2024-09-28T08:00:00.000Z',
        _id: 'comment-5',
      },
    ],
    isSelectedJob: false,
    expectedDate: '2024-10-17T12:00:00.000Z',
    startDate: '2024-09-26T08:00:00.000Z',
  },
];
