import { ArrowDownOutlined, ArrowUpOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space } from 'antd';
import { observer } from 'mobx-react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ProductListStore } from '../../../../DataSource/Stores/Admin/ProductListStore';
import { ProductParams } from '../../../../DataSource/Stores/Admin/ProductParams';
import styles from '../../Admin.module.scss';
import { DataType } from '../../ProductParameters';

interface InputFieldsProps {
  kkey: number;
  name: number;
  formsCnt: number;
  restField: { fieldKey?: number | undefined };
  remove: (index: number | number[]) => void;
  move: (from: number, to: number) => void;
  setFormsCnt: Dispatch<SetStateAction<number>>;
  param_type_id: string;
  fieldIndex: number;
  label?: string;
}

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

const InputFields: React.FC<InputFieldsProps> = ({
  kkey,
  name,
  restField,
  remove,
  move,
  setFormsCnt,
  formsCnt,
  param_type_id,
  fieldIndex,
  label,
}) => {
  const [loadError, setLoadError] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    // console.log('---- ww|formsCnt=', formsCnt);
    // console.log('---- ww|name=', label, ProductParams._dataList[param_type_id]?.length);
    if (init) return;
    setInit(true);
    setLoadError(false);
    if (ProductParams._dataList[param_type_id]?.length) return;
    ProductParams.getParametersList(param_type_id).catch(() => setLoadError(true));
  }, []);

  console.log('---- ww|name=', formsCnt, label, ProductParams._dataList[param_type_id]?.length);

  return (
    <Space key={kkey} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
      {loadError ? (
        <div>
          <div className={styles.table + ' mt-32'}>Error...</div>
        </div>
      ) : (
        <>
          <div className={styles.flex_bl}>
            {formsCnt < 2 ? null : (
              <>
                <Button
                  className={styles.but_up}
                  icon={<ArrowUpOutlined />}
                  onClick={() => {
                    move(fieldIndex, fieldIndex - 1);
                  }}
                />
                <Button
                  className={styles.but_down}
                  icon={<ArrowDownOutlined />}
                  onClick={() => {
                    move(fieldIndex, fieldIndex + 1);
                  }}
                />
              </>
            )}
          </div>
          <Form.Item
            {...restField}
            // label="Status"
            name={[name, 'param_id']}
            rules={[{ required: true, message: 'Please input your Status!' }]}
          >
            <Select
              style={{ minWidth: 240, maxWidth: 250 }}
              // defaultValue="text"
              onChange={handleChange}
              options={
                (ProductParams._dataList[param_type_id] || []).map((i: DataType) => ({
                  value: i._id,
                  label: i.field_name,
                })) as []
              }
              loading={ProductParams.loadingList}
              disabled={ProductListStore.globalLoading}
              // showSearch={true}
            />
          </Form.Item>

          <Form.Item
            {...restField}
            name={[name, 'value']}
            rules={[{ required: true, message: 'Value is required' }]}
          >
            <Input placeholder="Value" disabled={ProductListStore.globalLoading} />
          </Form.Item>
        </>
      )}
      <MinusCircleOutlined
        onClick={() => {
          remove(name);
          setFormsCnt(formsCnt - 1);
        }}
      />
    </Space>
  );
};

export default observer(InputFields);
