import { useDroppable } from '@dnd-kit/core';
import { FC, ReactNode, useState } from 'react';
import { StagesDataType } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { $class } from '../../../../utils';
import StageDrawer from './StageDrawer';
import styles from './index.module.scss';
import IconButton from '../../../ui/IconButton';

interface IProps extends StagesDataType {
  children: ReactNode;
}

const JobColumn: FC<IProps> = ({ children, _id, name }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { setNodeRef, isOver, active } = useDroppable({
    id: _id,
  });

  const stage = StagesStore.dataList.find((stage) => stage._id === _id);

  return (
    <>
      <div
        ref={setNodeRef}
        className={$class(
          styles.column,
          active?.data?.current?.stageId !== _id && isOver && styles.columnOver,
          active?.data?.current?.stageId === _id && styles.columnFrom
        )}
      >
        <div className={styles.columnTop}>
          <p>
            {name} <b>({StagesStore.dataJobsList?.filter((job) => job.stageId === _id).length})</b>
          </p>
          <IconButton icon="cog" variant="clear" onClick={() => setDrawerOpen(true)} />
        </div>
        <div className={styles.columnBody}>{children}</div>
      </div>

      {stage && <StageDrawer isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} {...stage} />}
    </>
  );
};

export default JobColumn;
