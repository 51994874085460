import { Form, Input, message, type FormProps } from 'antd';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthStore } from '../../../DataSource/Stores/AuthStore';
import Button from '../../ui/Button';
import styles from './index.module.scss';

type FieldType = {
  email?: string;
  password?: string;
  remember?: string;
};
//const navigate = useNavigate();

const ZakazBuketov = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    const { email, password } = values;
    // console.log('Email:', email);
    // console.log('Password:', password);

    if (email && password) {
      try {
        await AuthStore.login(email, password);
        navigate('/workspaces/66e170b52fc0a03d58789c24/user');
      } catch (error) {
        message.error('Invalid username or password');
      }
    }
  };

  return (
    <div className={styles.fon_wrp}>
      <div className={styles.wrp_form}>
        <p className={styles.title}>Авторизация</p>
        {/* <p className={styles.text_add}>
        Нет аккаунта? <Link to="/register">Создать</Link>
      </p> */}
        <Form
          name="login"
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            className={styles.back_input}
            name="email"
            rules={[{ required: true, message: 'Пожалуйста, введите логин!' }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            className={styles.back_input}
            name="password"
            rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}
            label="Пароль"
          >
            <Input.Password placeholder="Пароль" />
          </Form.Item>

          <Link className={styles.link} to="/forgot">
            Забыли пароль?
          </Link>
          <Button className={styles.button_prime} icon={'bee'} variant="primary">
            Войти
          </Button>
        </Form>
      </div>
    </div>
  );
};
export default observer(ZakazBuketov);
