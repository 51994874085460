import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { WorkspacesStore } from '../../../DataSource/Stores/Cabinet/WorkspacesStore';

import DeliveryCenterMobile from '../DeliveryCenter/Mobile';
import ZakazBuketovMobile from '../User';

const MemberApp = () => {
  const { id } = useParams();
  const workspace = WorkspacesStore.workspaceById.data;
  const loading = WorkspacesStore.workspaceById.loading;

  useEffect(() => {
    if (!id) return;
    WorkspacesStore.workspaceById.call(id);
  }, []);

  if (loading || !workspace)
    return (
      <div className="not_found_wrp">
        <LoaderIcon style={{ width: 32, height: 32 }} />
      </div>
    );

  if (workspace.category === 'order_management') return <ZakazBuketovMobile />;

  if (workspace.category === 'delivery_center') return <DeliveryCenterMobile />;

  return <div className="not_found_wrp">Interface not defined</div>;
};

export default observer(MemberApp);
